@import '../../../styles/variables.scss';

.Radio {
  display: inline-block;
  position: relative;
  backface-visibility: hidden;
  vertical-align: baseline;

  .Input {
    position: absolute;
    display: none;
  }

  &-label {
    cursor: pointer;
    user-select: none;
    position: relative;
    font-size: 1em;
    color: #595959;
    transition: color 0.1s ease;
    padding-left: 1.5rem;
    display: inline-block;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 1.1rem;
      height: 1.1rem;
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &:after {
      display: none;
      background: $CIRRUS;
      border-color: $CIRRUS;
      transform: scale(0.45);
    }
  }
}

.checked > .Radio-label {
  &:before {
    border-color: $CIRRUS;
  }

  &:after {
    display: block;
  }
}
.Radio.disabled {
  opacity: 0.5;
  pointer-events: none;
}