.container{
    background-color: #2d2d2d;
}

.header{
    padding-top: 50px;
}

.notification{
    color: white;
    margin-top: 50px;
    font-size: 20px;
}