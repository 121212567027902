.ant-collapse {
  &-header {
    display: inline-grid;
    grid-template-columns: 30px auto;
    &-content {
      display: inline-grid;
      grid-template-columns: 250px auto;
      grid-gap: 0.2em 0.7em;
      > .status {
        display: inline-flex;
        > div:nth-child(n + 2) {
          padding: 0 0 0 1rem;
        }

        .text,
        .date {
          font-weight: 400;
        }
      }

      .title.required::after {
        display: inline-block;
        margin-left: 4px;
        content: '*';
        font-family: SimSun;
        line-height: 1;
        font-size: 14px;
        color: #f5222d;
      }

      @media (max-width: 1350px) {
        grid-template-columns: auto auto;
      }
    }
  }
}
