@import '../../../styles/variables.scss';

@mixin MenuItem() {
  position: relative;
  display: flex;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: $BORDER;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 14px;
    right: 0;
    border-bottom: 1px dashed $BORDER;
  }
  &.active {
    &:before {
      display: none;
    }
  }
  img {
    // position: absolute;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 1.5rem;
    pointer-events: none;
    align-self: center;
  }
}

a.MenuItem {
  display: block;
  color: inherit !important;
  text-decoration: none !important;
  transition: none !important;
}
.MenuItem {
  margin-bottom: 0.2rem;
  padding: 0.6rem 1rem;
  @include MenuItem();
  &.active {
    color: white !important;
    background-color: $CIRRUS !important;
    font-weight: $BOLD;
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

@mixin MenuItemParent() {
  position: relative;
  display: flex;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: $BORDER;
  }
  // &:before {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: 14px;
  //   right: 0;
  //   border-bottom: 1px dashed $BORDER;
  // }
  &.active {
    &:before {
      display: none;
    }
  }
  img {
    // position: absolute;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 1.5rem;
    pointer-events: none;
    align-self: center;
  }
}

a.MenuItemParent {
  display: block;
  color: inherit !important;
  text-decoration: none !important;
  transition: none !important;
}
.MenuItemParent {
  margin-bottom: 0.2rem;
  padding: 0.6rem 1rem;
  @include MenuItemParent();
  &.active {
    color: white !important;
    background-color: $CIRRUS !important;
    font-weight: $BOLD;
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.MenuGroup {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 0.3rem;
  padding: 0.6rem;
  font-weight: $BOLD;
  @include MenuItemParent();
  .icon.caret {
    font-size: 1.2rem;
    position: relative;
    top: 0.1rem;
    left: 0.5rem;
  }
  &-Children {
    margin-left: 1.7rem;
    // border-left: 1px dashed $CIRRUS;
    padding-left: 1rem;
    &.hidden {
      display: none;
    }
    .MenuGroup {
      margin-top: 0;
    }
  }
}
