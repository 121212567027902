@import './variables.scss';

.ui.button {
  &.button--overridden-style {
    padding-top: 0.9375em;
    padding-bottom: 0.9375em;
  }
}

.input--overridden-style {
  .ui.input,
  &.ui.input {
    & > input {
      height: 2.875em;
      border-color: $INPUT_BORDER;
      color: #7c8aa2 !important;

      &:hover {
        border-color: rgba(34, 36, 38, 0.35) !important;
      }

      &:focus {
        border-color: $CIRRUS !important;
        box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
      }
    }

    &.error > input,
    &.error > input:hover,
    &.error > input:focus {
      border-color: #e0b4b4 !important;
    }
  }

  & > input .ui.label {
    margin-top: 0.3rem !important;
  }
}

.input--overridden-style.disabled {
  & input {
    color: #7c8aa2 !important;
    border: solid 1px rgba(124, 138, 162, 0.3);
    background-color: rgba(124, 138, 162, 0.16);
    background: rgba(0, 0, 0, 0.07);
  }
}

.ui.search {
  &.input--overridden-style {
    min-height: 2.875em;
    border-color: $INPUT_BORDER;

    input,
    .text {
      color: #7c8aa2 !important;
    }

    &:focus,
    &.active {
      border-color: $CIRRUS !important;
      box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
    }

    &.error {
      &:focus,
      &.active,
      & > .menu {
        border-color: #e0b4b4 !important;
      }
    }

    &.disabled {
      border-color: rgba(124, 138, 162, 0.3) !important;
      background-color: rgba(124, 138, 162, 0.16) !important;
      background: rgba(0, 0, 0, 0.07) !important;
    }
  }

  .prompt {
    border-radius: 3px !important;
  }
}

.ui.radio.checkbox,
.ui.radio.checkbox {
  &.radio--overridden-style {
    .box,
    label {
      color: $CIRRUS_TEXT;
      &:before {
        background-color: transparent;
        border-color: $CIRRUS_TEXT;
      }
    }
    input:checked ~ .box,
    input:checked ~ label {
      &:before {
        border-color: $CIRRUS !important;
      }
      &:after {
        background-color: $CIRRUS !important;
      }
    }
  }
}

.ui.ui.ui {
  &.button.blue {
    background-color: $CIRRUS !important;
    &:hover {
      background-color: darken($CIRRUS, 3%) !important;
    }
  }
  &.message.blue {
    background-color: lighten($CIRRUS, 45%) !important;
    color: $CIRRUS !important;
    box-shadow: 0 0 0 1px $CIRRUS inset, 0 0 0 0 transparent;
  }
  &.button.red {
    background-color: $CIRRUS_RED !important;
    &:hover {
      background-color: darken($CIRRUS_RED, 3%) !important;
    }
  }
  &.label.red {
    background-color: $CIRRUS_RED !important;
  }
  &.button.green {
    background-color: $CIRRUS_ACCENT !important;
    &:hover {
      background-color: darken($CIRRUS_ACCENT, 3%) !important;
    }
  }
  &.label.green {
    background-color: $CIRRUS_ACCENT !important;
  }
}

.link {
  color: $CIRRUS !important;
  text-decoration: none !important;
  transition: none !important;
  cursor: pointer;

  &:hover {
    color: $CIRRUS !important;
    text-decoration: underline !important;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.Input-Field > .ui.search.selection.dropdown > i.dropdown.icon.clear:before {
  content: '\2716';
}

form .field .Upload-File p,
.add-file-container .Upload-File p {
  color: $NORMAL_TEXT_COLOR !important;
  opacity: 1 !important;
}

form.ui.form .fields .disabled.field,
form.ui.form .disabled.field > label.Form-Field-label,
form.ui.form .disabled.fields > .field > label.Form-Field-label {
  opacity: 0.75;
  font-weight: $NORMAL;
  color: $NORMAL_TEXT_COLOR !important;
  cursor: not-allowed;
}

form {
  .ui.icon.button.label.clear {
    border-radius: 0;
    background-color: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-left: 0;
  }

  .ui.label > span.clickable {
    cursor: pointer;
  }

  .ui.disabled.checkbox label {
    color: $NORMAL_TEXT_COLOR !important;
    opacity: 1 !important;
  }

  .ui.selection.dropdown {
    .ui.label,
    .default.text {
      color: $NORMAL_TEXT_COLOR;
    }
  }
}

form.ant-form.grid-style {
  .ant-collapse {
    grid-column-start: 1;
    grid-column-end: span 2;
    > .ant-collapse-item > .ant-collapse-header {
      font-size: 1.2rem;
      font-weight: $BOLD;
      padding: 12px 0;
      > i {
        padding-right: 1em;
        align-self: center;
        justify-self: center;
      }

      > .ant-collapse-header-content > .title {
        align-self: center;
      }

      @media (max-width: 1350px) {
        font-size: 1rem;
      }
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 6px;
    }

    .ant-collapse-content-box {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2em), 1fr));
      grid-gap: 0.3em 2em;
    }

    .ant-collapse {
      padding-left: 8px;
    }
  }
}

form.ant-form {
  label.checkbox-session.ant-checkbox-wrapper {
    font-weight: bold;
    background-color: gray;
    padding: 5px;
    width: 350px;
    display: flex;
    align-items: center;
    > span {
      color: #fff !important;
    }
  }

  label.checkbox-session.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    background-color: #2cbf2c;
  }

  .ant-form-item.cancellation-label > .ant-form-item-label > label {
    font-size: 1.2em;
    font-weight: 700;
  }

  .add-file-container {
    min-height: 110px;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-checkbox-disabled .ant-checkbox-inner,
  .ant-radio-disabled .ant-radio-inner {
    background-color: darkgray;
    border-color: darkgreen !important;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner,
  .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: darkgreen;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #fff;
  }

  .ant-radio-disabled.ant-radio-checked .ant-radio-inner:after {
    background-color: #fff;
  }

  .align-start {
    display: flex;
    align-items: flex-start;
  }

  .ant-time-picker-input[disabled] {
    color: $NORMAL_TEXT_COLOR !important;
  }

  .checkbox-field.bold-text > label > span:nth-child(2) {
    font-weight: 700;
    font-size: 1rem;
  }
}

.ant-select,
.ant-select-disabled,
.ant-input,
.ant-input-disabled,
.ant-input-number-disabled,
.ant-radio-disabled + span,
.ant-checkbox-disabled + span,
.ant-checkbox-disabled + span,
.ant-checkbox + span,
.ant-checkbox-wrapper + span,
.ant-radio-wrapper + span,
.ant-select-search__field__placeholder,
.ant-select-selection__placeholder,
.ant-select-selection--multiple .ant-select-selection__choice {
  color: $NORMAL_TEXT_COLOR !important;
}
