@import '../../../styles/variables.scss';

.Session-calendar-container {
  .calendar {
    .popup-specialist-info {
      width: 100%;
      color: black;
      &-title {
        display: block;
        // white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 100%;
        font-size: 12px;
      }
    }
    .fc-col-header-cell-cushion {
      font-size: 16px !important;
      font-weight: bold !important;
    }
    .fc-popover-body {
      height: 500px !important;
      overflow: scroll !important;
      width: 800px !important;
    }
  }

  margin-bottom: 1rem;

  .none-results-session {
    background-color: #1fc0da;
    font-size: 16px;
    font-weight: bold !important;
    color: white !important;
  }
  .session-cell-item {
    background-color: #449d44;
    font-size: 16px;
    font-weight: bold !important;
    color: white !important;
  }

  .appt-cell-item {
    background-color: #c9302c;
    font-size: 16px;
    font-weight: bold !important;
    color: white !important;
  }

  .unconfirmed-session-cell-item {
    background-color: #ffa500;
    font-size: 16px;
    font-weight: bold !important;
    color: white !important;
  }

  .unconfirmed-appt-cell-item {
    background-color: pink;
    font-size: 16px;
    font-weight: bold !important;
    color: white !important;
  }

  .Session-calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }

  .calendar-header-date {
    font-size: 25px;
    font-weight: bold !important;
    color: black;
  }
  .calendar-header-nav {
    margin-left: 1.2rem;
    color: $CIRRUS;

    & > span {
      margin: 0 0.9rem;
    }

    & > span,
    & > .icon {
      cursor: pointer;
    }
  }
  .Toolbar-range-picker,
  .Toolbar-range-picker input {
    width: 25em;
  }
  .ui.buttons .button.active {
    background-color: $CIRRUS !important;
    color: #fff !important;
  }
}

.calendar-header {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  color: #4a4a4a;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 0.95em;
}

.calendar-header-divider {
  width: 100%;
  height: 2px;
  background-color: rgba(124, 138, 162, 0.2);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
