@import '../../../styles/variables.scss';

.TextArea {
  width: 100%;
  padding: 0.78571429em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  color: #7c8aa2 !important;

  &:hover {
    border-color: rgba(34, 36, 38, 0.35) !important;
  }

  &:focus {
    border-color: $CIRRUS !important;
    box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
  }

  &.disabled {
    opacity: 0.45;
    pointer-events: none;
  }

  &.error {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
  }
}
