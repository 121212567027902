@import '../../../styles/variables.scss';

a.Tab {
  color: inherit !important;
  text-decoration: none !important;
  transition: none !important;

  &.animation {
    transition: left 200ms ease-out !important;
  }
}

.Tab {
  display: inline-block;
  position: relative;
  left: 0;
  width: 15rem;
  height: 2.75rem;
  cursor: pointer;
  user-select: none;

  &-Background {
    position: absolute;
    top: 0;
    left: 1rem;
    width: 14rem;
    height: 100%;
    box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    border-bottom: 0;
    background-color: darken(#ffffff, 15%);
    transform: perspective(10.5rem) rotateX(30deg);
    transform-origin: 50% 0;
    pointer-events: none;
  }

  &-Content {
    position: relative;
    height: 100%;
    padding: 0.8rem 1.5rem 0 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      position: absolute;
      top: 0.8rem;
      left: 1.5rem;
      width: 1.3rem;
      height: 1.3rem;
      pointer-events: none;
    }
  }

  &-Close {
    position: absolute;
    top: 0.4rem;
    right: 0.2rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 0.1rem;

    svg {
      width: 100%;
      stroke: lighten(#000000, 30%);
    }

    &:hover {
      background-color: darken(#ff0000, 5%);
      svg {
        stroke: #ffffff;
      }
    }
  }

  &:hover {
    .Tab-Background {
      background-color: darken(#ffffff, 8%);
    }
  }

  &.active {
    // Need to be synced with ./Tabs.scss
    z-index: 100 !important;

    .Tab-Background {
      background-color: #ffffff;
    }

    .Tab-Content {
      font-weight: $BOLD;
    }
  }
}
