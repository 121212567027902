@import '../../../styles/variables.scss';

.SidebarHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: $SIDEBAR_WIDTH;
  height: $HEADER_HEIGHT;
  // border-bottom: 1px dashed $BORDER;
  background-color: #2b2b2b;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    // background-color: $CIRRUS;
    background-color: #2b2b2b;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -0.1rem;
    left: 100%;
    width: 0.1rem;
    background-color: rgba(0, 0, 0, 0.07);
  }
  &.autohide {
    &:after {
      display: none;
    }
  }
}
