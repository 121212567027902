@import '../../../styles/variables.scss';

.DashboardSidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $SIDEBAR_WIDTH;
  z-index: 999;
  &.autohide {
    visibility: hidden;
    left: -$SIDEBAR_WIDTH;
    &.visible {
      visibility: visible;
      left: 0;
      transition: left 300ms ease-out;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    }
  }

  &__site-logo {
    background-color: $SIDEBAR_BACKGROUND-COLOR;
    padding: 0 0.8rem 3.5rem 0.8rem;
    margin-top: $HEADER_HEIGHT;
  }
}

// To show the black backdrop when the sidebar is opening in auto-hide mode
.SidebarAutohideBackdrop {
  display: none;
  position: fixed;
  top: -1rem;
  bottom: -1rem;
  left: -1rem;
  right: -1rem;
  background-color: rgba(0, 0, 0, 0.7);
  &.autohide {
    display: block;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s ease-out;
    &.visible {
      pointer-events: auto;
      opacity: 1;
      transition: none;
    }
  }
}

// To prevent the tooltip show up when animating
.SidebarAnimationBackdrop {
  display: none;
  position: fixed;
  top: -1rem;
  bottom: -1rem;
  left: -1rem;
  right: -1rem;
  &.autohide {
    display: block;
    transition-property: visibility;
    transition-duration: 0s;
    transition-delay: 0.3s;
    visibility: visible;
    pointer-events: none;
    &.visible {
      visibility: hidden;
      pointer-events: auto;
    }
  }
}
