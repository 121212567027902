@import '../../styles/variables.scss';

.FormGroupTitle {
  display: flex;
  padding: 0 0 1rem;
  &-Text {
    font-weight: $BOLD;
  }
  &-Divider {
    flex: 1;
    height: 1px;
    margin: 0.75rem 0 0 0.5rem;
    background-color: $CIRRUS;
  }
}
