.scope-video-container {
  margin: 0 auto;
  text-align: center;
  max-width: 300px;
  margin-top: 2em;
  .scope-logo {
    width: 200px;
  }

  .form-container {
    margin-top: 5em;
  }
}