.ui.dropdown.input--overridden-style {
  .menu::-webkit-scrollbar {
    width: 7px;
  }

  .menu .item {
    letter-spacing: 0.2px;

    .text div:not(:first-child) {
      padding-top: 5px;
    }

    .text .text-bold {
      color: rgba(0, 0, 0, 0.75);
    }

    &.selected {
      font-weight: normal;
    }
  }
}
