$NORMAL: 400;
$BOLD: 700;

$CIRRUS: #1fc0da;
$CIRRUS_SHADE_TINT: #9dd7e5;
// $CIRRUS_RED: #a73067;
$CIRRUS_RED: rgb(201, 48, 44);
$CIRRUS_ACCENT: #038951;
$CIRRUS_DARK_BLUE: #00344b;
$CIRRUS_TEXT: #595959;

$BORDER: $CIRRUS_TEXT;

$INPUT_BORDER: rgba(124, 138, 162, 0.3);
$TEXT_COLOR: rgba(124, 138, 162, 0.5);
$TEXT_COLOR_FOCUS: rgba(124, 138, 162, 1);
$DISABLED_INPUT_BACKGROUND_COLOR: rgba(124, 138, 162, 0.16);

$BODY_MIN_WIDTH: 1150px;
$BODY_MIN_WIDTH_MAX: $BODY_MIN_WIDTH - 1px;
$BODY_MIN_HEIGHT: 550px;
$BODY_MIN_HEIGHT_MAX: $BODY_MIN_HEIGHT - 1px;

$SIDEBAR_WIDTH: 22rem;
$HEADER_HEIGHT: 3.75rem;
$DASHBOARD_SITELOGO_HEIGHT: 3.6rem;

$SIDEBAR_BACKGROUND-COLOR: #2d2d2d;

$NORMAL_TEXT_COLOR: rgba(0, 0, 0, 0.87);
