.Doctor-card-list {
  display: flex;
  justify-content: space-between;

  .Doctor-card-item {
    width: 18%;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    text-align: center;
    padding: 3rem 2rem 2rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transition: box-shadow 0.2s;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    }

    img {
      width: 60%;
      border-radius: 100%;
    }

    .Doctor-info {
      .doctor-name {
        margin-top: 1rem;
        margin-bottom: 10px;
        color: #1fc0da;
      }

      .doctor-specialty {
        width: 90%;
        display: block;
        margin: auto;
        color: #a3a3a3;
        margin-bottom: 2.5rem;
      }
    }

    .Doctor-info-contact-list {
      width: 60%;
      margin-block-start: auto;
      display: flex;
      justify-content: space-between;

      i.icon {
        margin: 0;
        cursor: pointer;
        font-size: 1.2rem;
        color: #1fc0da;

        &.disabled {
          pointer-events: none;
          opacity: 0.2 !important;
        }
      }
    }
  }

  .empty-message {
    width: 100%;
    padding: 2rem 0 !important;
    text-align: center !important;
    color: #ccc;
    font-size: 1.7rem;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }
}
