.fileDrop {
  background: #f5f5f5;
  border: 1px dashed #c2c2c2;
  border-radius: 3px;
  text-align: center;
  padding: 36px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.fileDrop:hover {
  background: rgb(194, 243, 194);
  border: 1px dashed #333;
}

.list-file {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 700;
}

.list-file-ul {
  padding-left: 20px;
  list-style: decimal;
  margin: 10px 0 0;
}
.upload-icon {
  margin-bottom: 20px !important;
  font-size: 2em !important;
  color: #1db4cd;
}

.custom-modal {
  .ant-modal-body {
    max-height: 60em !important;
    overflow: auto;
  }
}
