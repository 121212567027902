.clinic-details-container {
  padding: 1em;
  padding-bottom: 2em;
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.clinic-info {
  display: flex;

  .clinic-avatar {
    width: 160px;
    height: 160px;
    background-size: cover !important;
    margin-right: 30px;
  }

  .clinic-info-details {
    h1 {
      letter-spacing: 1px;
      color: #4a4a4a;
      margin-bottom: 10px;
    }

    .cta-buttons {
      margin-top: 28px;

      .ui.button {
        padding-left: 2.5em;
        padding-right: 2.5em;
        font-size: 1;
        line-height: 1.38;
        margin-right: 7px;
        background-color: #1fc0da !important;
        box-shadow: 0 0 0 1px #1fc0da inset !important;
      }

      .ui.button.basic {
        background-color: transparent !important;
        color: #1fc0da !important;

        &:hover {
          background-color: #1fc0da !important;
          color: #fff !important; 
        }
      }
    }
  }
}

.basic-info {
  margin-top: 28px;

  div {
    margin-bottom: 1em;

    span:first-child {
      display: inline-block;
      width: 120px !important;
      color: #4a4a4a;
      opacity: 0.86;
      font-weight: 600;
    }

    span:last-child {
      color: #7c8aa2;
      letter-spacing: 0.34px;
      font-weight: normal !important;
    }
  }
}

.info-wrapper {
  padding: 1.5em;
  border-radius: 4px;
  background-color: #f7f8fa;
  margin-top: 2em;
  min-height: 8em;
  
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    
    h3 {
      color: #2b2b2b;
      margin-bottom: 0;
      text-transform: uppercase;
      letter-spacing: .34px;
    }
  }
}

.registered-specialists {
  margin-top: 3em;

  span {
    color: #1fc0da; 
    letter-spacing: 0.34px;
  }

  .specialist-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.8em 1em;

    .specialist {
      display: inline-flex;
    }

    .avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-size: cover !important;
    }

    .specialist-info {
      display: block;
      margin-left: 1em;

      h4 {
        color: #1fc0da;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 1.1em;
      }

      span {
        color: #a3a3a3;
        letter-spacing: .34px;
      }
    }
  }
}

.info-description .content {
  color: #a3a3a3;
}

.info-addresses {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;

  .basic-info  {
    div {
      span:first-child {
        width: 100px !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}