@import '../../../styles/variables.scss';

.mail-detail {
  &.infinite-scroll {
    max-height: 730px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 10px;
    width: 100%;

    & .ui.divider {
      width: 100%;
    }
  }

  & .ui.grid {
    width: 100%;
  }

  & .save-button {
    height: 40px;
    align-self: flex-end;
  }
  & .action-note-form {
    width: 100%;
  }
  & .case-no {
    margin-right: 10px;
    font-weight: bold;
    color: #1fc0da !important;
    transition: 0.2s opacity;
  }
}
