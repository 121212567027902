@import '../../../styles/variables.scss';

.PasswordInput {
  position: relative;
  input {
    padding-right: 3.2em !important;
    &[type='password'] {
      letter-spacing: 0.1em;
    }
  }
  //
  .Input-Icon {
    top: 1.6em !important;
    width: 4em;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 0.8rem;
      padding: 0.6rem;
      margin-right: 0.5rem;
      font-weight: $BOLD;
      color: $CIRRUS;
      user-select: none;
      letter-spacing: 0.03em;
    }
  }
}
