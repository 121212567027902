@import '../../../styles/variables.scss';

.ui.form.mail-search {
  width: 100%;
  padding: 0 10px;

  & .ui.button {
    margin-left: 5px;
  }
  & .field.button-container {
    align-self: flex-start;
    margin-top: 25px;
  }
  & .Toolbar-range-picker {
    width: 100%;
  }
  .receiver-emails-filter {
    // max-height: 100px;
  }
}

.ui.items {
  margin: 0 1.5rem 0 0.5rem;

  & .ui.divider {
    margin-left: 20px;
  }
  & .child-list {
    margin-left: 70px;
    margin-right: 0px;
  }
  & .item.selected {
    background-color: rgba(0,0,0,.1);
  }
  .item {
    padding-bottom: 0;
    .header {
      font-size: 1.1em !important;
    }
    .meta {
      line-height: 1.5em;
    }
  }

  & .item .header.negative {
    color: #9f3a38;
  }

  & .item .item-selection {
    align-items: center;
    margin-right: 7px;
    display: flex;

    & i.icon {
      height: 18px;
      cursor: pointer;
      margin-left: 0.25rem;
    }
  }

  & .item .actions {
    vertical-align: middle;
    display: flex;
    & .ui.button.dropdown {
      padding: 5px;
      align-self: center;
      & i.icon {
        margin: 0;
      }
      & .menu .ui.item.dropdown {
        text-align: right;
        width: 120px;
        & .dropdown.icon {
          margin-top: 2px;
        }
      }
    }
  }

  &.child-list {
    .item {
      padding: 10px !important;
      .header-container {
        padding-top: 10px;
      }
    }
    .sub-message-header {
      font-size: 1em !important;
      overflow: hidden;
      max-height: 60px;
      text-overflow: ellipsis;
    }
  }
}

.ui.items>.item>.content {
  cursor: pointer;
  overflow-x: hidden;
}

.ui.items>.item>.image:not(.ui) {
  width: 16px;
  padding-top: 6px;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.ui.items>.item>.image+.content {
  padding-left: 0.5rem;
}

.mail-list.infinite-scroll {
  max-height: 650px;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 300px;
  background-color: white;
}
.action-bar {
  padding: 0 30px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .item-count {
    margin-right: 30px;
  }
  .select-all-emails {
    margin-left: 7px;
    margin-right: 1em;
  }
}

.ui.grid .row.no-padding-top {
  padding-top: 0;
}

.ui.grid .row.no-padding {
  padding: 0;
}
