@import '../../../styles/variables.scss';

.DashboardHeader {
  position: fixed;
  top: 0;
  left: $SIDEBAR_WIDTH;
  right: 0;
  height: $HEADER_HEIGHT;
  min-width: calc(#{$BODY_MIN_WIDTH} - #{$SIDEBAR_WIDTH});
  z-index: 1;
  &.sidebarAutohide {
    left: 0;
    min-width: $BODY_MIN_WIDTH;
  }
}
