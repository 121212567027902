.DashboardHeaderIcon {
  position: absolute;
  top: 0;
  width: 3rem;
  height: 3rem;
  font-size: 1.3rem;
  line-height: 3rem;
  overflow: hidden;
  text-align: center;
  color: darken(#ffffff, 10%);
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &-Tooltip {
    position: fixed !important;
    top: 3.1rem;
    margin-top: 0 !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    pointer-events: none;
    white-space: nowrap;
    &:before {
      top: 1px !important;
      left: auto !important;
      right: 1em !important;
    }
  }
}
