.AdvancedFormContainer {
  width: 100%;
}

.AdvancedForm {
  background-color: #eceff4;
  padding: 1rem 1rem;
  border-radius: 3px;
}

#AdvancedForm {
  .search-toolbar {
    align-items: flex-end;

    .doctor-search-input {
      width: -webkit-fill-available;
      max-width: 48%;
      margin-right: 1rem;
    }
  }
}

.showAdvancedSearch {
  .button {
    visibility: hidden;
  }
}

.twinInput {
  display: flex;

  &:hover .Search .Input:after {
    background: rgba(34, 36, 38, 0.35) !important;
  }

  & .Search,
  & .Input {
    flex: 1;
    margin-bottom: 0 !important;
  }

  & .Search .Input {
    position: relative;

    & input {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: #d7dce3;
    }
  }

  &>.Input input {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
    }
  }
}

.twinInput .Search .Input.active:after {
  background: #1fc0da !important;
}

.Radio-area {
  margin-bottom: 5px;
  display: inherit;

  .Radio {
    margin: 0 0 0 10px;
  }
}

.input-label {
  display: inline-block;
  margin-bottom: 5px;
  color: #4a4a4a;
}

.additional-checkbox .Checkbox .Checkbox-label {
  margin: 0 !important;
}