@import '../../../styles/variables.scss';

.TabMore {
  width: 4.5rem;
  top: 0;

  .Tab-Background {
    width: 3.5rem;
    transform: perspective(3.3rem) rotateX(30deg);
  }

  .Tab-Content {
    padding: 0.8rem 0.3rem 0 0.3rem;
    text-align: center;
    font-weight: $BOLD;
    transform: translateX(5px);
  }

  &-Count {
    background-color: red;
    color: white;
    font-size: 0.8rem;
    padding: 0 3px;
    border-radius: 3px;
  }

  &-Dropdown {
    display: none;
    position: absolute;
    top: -0.5rem;
    right: 0;
    width: 20rem;
    background-color: white;
    box-shadow: 0 0 2px gray;
    border-radius: 3px;
    overflow: hidden;

    &.active {
      display: block;
    }

    .MenuItem {
      padding-left: 2.4rem;
      padding-right: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 0;
      img {
        top: 0.7rem;
        left: 0.5rem;
      }
      &:hover {
        background-color: darken(white, 5%);
      }
    }

    .Tab-Close {
      position: absolute;
      top: 0.8rem;
      right: 0.3rem;
    }
  }
}
