.add-files-urgent-report label span {
  font-size: 14px !important;
}

.dictation-transcription-header {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.dictation-transcription-wrapper {
  margin-bottom: 1rem;
  border: solid 1px lightgray;
  border-radius: 1rem;
  padding: 1rem;
}

.dictation-transcription-item {
  // border: solid 1px lightgray;
  // border-radius: 1rem;
  display: flex;
  gap: 1em;
  align-items: center;
  margin-bottom: 1rem;
  .transcription-content {
    margin-top: 0.5rem;
  }

  .language-selector {
    width: 250px;
  }
}
