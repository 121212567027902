
.iframe-layout {
  .login-wrapper {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 150px;
  }

  .logout-button {
    position: fixed;
    right: 10px;
    top: 0px;
    z-index: 10000;
  }
}


