.btn-primary,
.btn-secondary,
.btn-dark {
//   width: 100px;
  height: 46px;
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  padding: 10px 0 0 0;
  text-align: center;
  color: #ffffff !important;
}

.btn-primary {
  background-color: #1fc0da !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: #25828b !important;
  border: solid 1px rgba(37, 130, 139, 0.5) !important;
}

.btn-dark {
  background-color: #4a4a4a !important;
}
