.progress-statuses-container {
  position: sticky;
  grid-column: 1 / -1;
  top: 0;
  width: 100%;
  background: white;
  z-index: 2;
  padding-top: 10px;
  
  .progress-statuses {
    &__sections {
      display: flex;
      justify-content: space-between;
  
      &.expanding {
        height: auto;
      }
  
      height: 40px;
      overflow: hidden;
    }
  
    .case-info {
      font-size: 1.1em;
      font-weight: 400;
      text-align: center;
    }
    .status-group {
      font-size: 20px;
      color: gray;
      cursor: pointer;
      &.completed {
        color: green;
      }
      &.inprogress {
        color: green;
      }
      .group-icon {
        font-size: 20px;
      }
  
      .group-summary {
        margin-left: 5px;
      }
    }
  
    .expand-section {
      text-align: center;
      margin-top: 10px;
    }
  
    .tasks-section {
      margin-top: 2rem;
      font-size: 16px;
      .title {
        margin-bottom: 1rem;
        font-weight: bold;
      }
  
      .task-status {
        margin-bottom: 1rem;
  
        &__label {
          font-weight: bold;
          font-size: 16px;
          label {
            display: flex;
            align-items: flex-start;
            .ant-checkbox, .ant-radio {
              margin-top: 5px;
            }
          }

          &.not-performed {
            label, input {
              color: orange;
            }
            .ant-radio-inner {
              border-color: orange;
              &:after {
                background: orange;
              }
            }
            .ant-checkbox-inner {
              background: orange;
              border-color: none;
              &:after {
                background: orange;
              }
            }
          }

          &.current {
            label, span {
              color: green !important;
            }
          }
        }
      }
  
      .task-info {
        padding-left: 25px;
        font-size: 14px;
      }
    }
  }
}

