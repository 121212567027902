.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}
.form-add-edit-loading {
  display: flex;
  height: 75vh;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}
.btn-secondary-close {
  background-color: #1fc0da !important;
}
.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
