@charset "UTF-8";
@import url("https://cdnjs.cloudflare.com/ajax/libs/antd/3.10.0/antd.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.3.3/semantic.min.css");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
html {
  font-size: 13px !important;
}
@media (min-width: 1400px) {
  html {
    font-size: 14px !important;
  }
}
@media (min-width: 1500px) {
  html {
    font-size: 15px !important;
  }
}

body {
  font-family: Lato, Roboto, Tahoma, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  overflow: auto;
  min-width: 1150px;
  min-height: 550px;
}

#root {
  min-width: 100%;
  min-height: 100%;
  position: relative;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  opacity: 1;
}

.ui,
.ui input,
.ant-notification {
  font-family: inherit !important;
  font-weight: inherit !important;
}

.ui.header,
.ui.button,
.ant-notification-notice-message {
  font-weight: 700 !important;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

*,
*:before,
*:after {
  outline: none !important;
}

.ui input {
  height: 38rem/14 !important;
}

.ui.input > input {
  line-height: 1.5em;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

.step-container-sticky {
  position: sticky;
  top: 130px;
  z-index: 1;
  min-height: 600px;
  max-height: 700px;
}

::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 0.92 !important;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}

::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}

.row-orange-cls {
  background: rgba(238, 122, 39, 0.3019607843) !important;
  color: #e96619 !important;
}

/* Spacing helper like bootstrap utils */
.pt-0 {
  padding-top: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.pa-0 {
  padding: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.ma-0 {
  margin: 0rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pa-1 {
  padding: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.ma-1 {
  margin: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.pa-2 {
  padding: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.ma-2 {
  margin: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pa-3 {
  padding: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.ma-3 {
  margin: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.pa-4 {
  padding: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.ma-4 {
  margin: 4rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.pr-5 {
  padding-right: 5rem !important;
}

.py-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-5 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.pa-5 {
  padding: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.my-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-5 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.ma-5 {
  margin: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.text-danger {
  color: #ff4d4f;
}

.text-success {
  color: #009b72;
}

.text-secondary {
  color: #595959;
}

.text-bold {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.hidden {
  display: none !important;
}

.visibilityHidden {
  visibility: hidden !important;
}

.formgroup {
  margin-bottom: 1.3em;
}

.nowrap {
  white-space: nowrap;
}

.loading-container {
  max-width: 28rem;
  margin: 0 auto;
}

.relative {
  position: relative !important;
}

.r-0 {
  right: 0 !important;
}

.multi-fields .Input {
  flex: 1;
}
.multi-fields .Input:first-child {
  padding-right: 0.5em;
}
.multi-fields .Input:last-child {
  padding-left: 0.5em;
}

.block-display {
  display: block !important;
}

.block-display-pading {
  display: block !important;
  padding-bottom: 75px;
}

.label-title-from {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.multi-item-left {
  float: left;
  clear: none;
  width: 49%;
}

.multi-item-right {
  float: right;
  clear: none;
  width: 49%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-between {
  justify-content: space-between;
}

.scrollable {
  overflow: auto;
}
.scrollable::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background: transparent !important;
  border-bottom-right-radius: 3px !important;
}
.scrollable::-webkit-scrollbar-button {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
}
.scrollable::-webkit-scrollbar-corner {
  background: transparent !important;
}
.scrollable::-webkit-scrollbar-thumb {
  border: 2px solid transparent !important;
  background-clip: padding-box !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.hoverable {
  cursor: pointer;
}

.ant-notification-notice-icon-error {
  color: #f5222d !important;
}

.loading.full-screen {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.dropdown > .clear.icon:before {
  content: "x";
}

.cell-correspondence-content {
  width: 20em;
}
.cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-correspondence {
  width: 20em;
}

.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading {
  color: white !important;
}
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading:before, .ui.button.icon.labeled.loading.loading.loading.loading.loading.loading:after {
  left: auto !important;
  right: 0.65em;
}
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading .icon {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading .icon:before {
  display: none;
}

form .ui.icon.button.label.clear {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 0;
}

.ui.button.button--overridden-style {
  padding-top: 0.9375em;
  padding-bottom: 0.9375em;
}

.input--overridden-style .ui.input > input, .input--overridden-style.ui.input > input {
  height: 2.875em;
  border-color: rgba(124, 138, 162, 0.3);
  color: #7c8aa2 !important;
}
.input--overridden-style .ui.input > input:hover, .input--overridden-style.ui.input > input:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.input--overridden-style .ui.input > input:focus, .input--overridden-style.ui.input > input:focus {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.input--overridden-style .ui.input.error > input, .input--overridden-style .ui.input.error > input:hover, .input--overridden-style .ui.input.error > input:focus, .input--overridden-style.ui.input.error > input, .input--overridden-style.ui.input.error > input:hover, .input--overridden-style.ui.input.error > input:focus {
  border-color: #e0b4b4 !important;
}
.input--overridden-style > input .ui.label {
  margin-top: 0.3rem !important;
}

.input--overridden-style.disabled input {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3);
  background-color: rgba(124, 138, 162, 0.16);
  background: rgba(0, 0, 0, 0.07);
}

.ui.search.input--overridden-style {
  min-height: 2.875em;
  border-color: rgba(124, 138, 162, 0.3);
}
.ui.search.input--overridden-style input,
.ui.search.input--overridden-style .text {
  color: #7c8aa2 !important;
}
.ui.search.input--overridden-style:focus, .ui.search.input--overridden-style.active {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.ui.search.input--overridden-style.error:focus, .ui.search.input--overridden-style.error.active, .ui.search.input--overridden-style.error > .menu {
  border-color: #e0b4b4 !important;
}
.ui.search.input--overridden-style.disabled {
  border-color: rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
  background: rgba(0, 0, 0, 0.07) !important;
}
.ui.search .prompt {
  border-radius: 3px !important;
}

.ui.radio.checkbox.radio--overridden-style .box,
.ui.radio.checkbox.radio--overridden-style label,
.ui.radio.checkbox.radio--overridden-style .box,
.ui.radio.checkbox.radio--overridden-style label {
  color: #595959;
}
.ui.radio.checkbox.radio--overridden-style .box:before,
.ui.radio.checkbox.radio--overridden-style label:before,
.ui.radio.checkbox.radio--overridden-style .box:before,
.ui.radio.checkbox.radio--overridden-style label:before {
  background-color: transparent;
  border-color: #595959;
}
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:before,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:before,
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:before,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:before {
  border-color: #1fc0da !important;
}
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:after,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:after,
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:after,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:after {
  background-color: #1fc0da !important;
}

.ui.ui.ui.button.blue {
  background-color: #1fc0da !important;
}
.ui.ui.ui.button.blue:hover {
  background-color: #1db4cd !important;
}
.ui.ui.ui.message.blue {
  background-color: #e3f8fb !important;
  color: #1fc0da !important;
  box-shadow: 0 0 0 1px #1fc0da inset, 0 0 0 0 transparent;
}
.ui.ui.ui.button.red {
  background-color: rgb(201, 48, 44) !important;
}
.ui.ui.ui.button.red:hover {
  background-color: #bc2d29 !important;
}
.ui.ui.ui.label.red {
  background-color: rgb(201, 48, 44) !important;
}
.ui.ui.ui.button.green {
  background-color: #038951 !important;
}
.ui.ui.ui.button.green:hover {
  background-color: #037a48 !important;
}
.ui.ui.ui.label.green {
  background-color: #038951 !important;
}

.link {
  color: #1fc0da !important;
  text-decoration: none !important;
  transition: none !important;
  cursor: pointer;
}
.link:hover {
  color: #1fc0da !important;
  text-decoration: underline !important;
}
.link.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.Input-Field > .ui.search.selection.dropdown > i.dropdown.icon.clear:before {
  content: "✖";
}

form .field .Upload-File p,
.add-file-container .Upload-File p {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 1 !important;
}

form.ui.form .fields .disabled.field,
form.ui.form .disabled.field > label.Form-Field-label,
form.ui.form .disabled.fields > .field > label.Form-Field-label {
  opacity: 0.75;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: not-allowed;
}

form .ui.icon.button.label.clear {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 0;
}
form .ui.label > span.clickable {
  cursor: pointer;
}
form .ui.disabled.checkbox label {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 1 !important;
}
form .ui.selection.dropdown .ui.label,
form .ui.selection.dropdown .default.text {
  color: rgba(0, 0, 0, 0.87);
}

form.ant-form.grid-style .ant-collapse {
  grid-column-start: 1;
  grid-column-end: span 2;
}
form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 12px 0;
}
form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header > i {
  padding-right: 1em;
  align-self: center;
  justify-self: center;
}
form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-content > .title {
  align-self: center;
}
@media (max-width: 1350px) {
  form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 1rem;
  }
}
form.ant-form.grid-style .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 6px;
}
form.ant-form.grid-style .ant-collapse .ant-collapse-content-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2em), 1fr));
  grid-gap: 0.3em 2em;
}
form.ant-form.grid-style .ant-collapse .ant-collapse {
  padding-left: 8px;
}

form.ant-form label.checkbox-session.ant-checkbox-wrapper {
  font-weight: bold;
  background-color: gray;
  padding: 5px;
  width: 350px;
  display: flex;
  align-items: center;
}
form.ant-form label.checkbox-session.ant-checkbox-wrapper > span {
  color: #fff !important;
}
form.ant-form label.checkbox-session.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #2cbf2c;
}
form.ant-form .ant-form-item.cancellation-label > .ant-form-item-label > label {
  font-size: 1.2em;
  font-weight: 700;
}
form.ant-form .add-file-container {
  min-height: 110px;
}
form.ant-form .ant-form-item {
  margin-bottom: 15px;
}
form.ant-form .ant-checkbox-disabled .ant-checkbox-inner,
form.ant-form .ant-radio-disabled .ant-radio-inner {
  background-color: darkgray;
  border-color: darkgreen !important;
}
form.ant-form .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner,
form.ant-form .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
  background-color: darkgreen;
}
form.ant-form .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #fff;
}
form.ant-form .ant-radio-disabled.ant-radio-checked .ant-radio-inner:after {
  background-color: #fff;
}
form.ant-form .align-start {
  display: flex;
  align-items: flex-start;
}
form.ant-form .ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.87) !important;
}
form.ant-form .checkbox-field.bold-text > label > span:nth-child(2) {
  font-weight: 700;
  font-size: 1rem;
}

.ant-select,
.ant-select-disabled,
.ant-input,
.ant-input-disabled,
.ant-input-number-disabled,
.ant-radio-disabled + span,
.ant-checkbox-disabled + span,
.ant-checkbox-disabled + span,
.ant-checkbox + span,
.ant-checkbox-wrapper + span,
.ant-radio-wrapper + span,
.ant-select-search__field__placeholder,
.ant-select-selection__placeholder,
.ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.87) !important;
}