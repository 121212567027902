.Checkbox {
  position: relative;
  backface-visibility: hidden;
  font-size: 1rem;
  line-height: 1.2rem;

  .Input {
    position: absolute;
    display: none;
  }

  &-label {
    cursor: pointer;
    user-select: none;
    position: relative;
    font-size: 1em;
    color: #595959;
    transition: color 0.1s ease;
    padding-left: 1.8rem;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.2rem;
      height: 1.2rem;
      background: #fff;
      border-radius: 3px;
      border: 1px solid #d4d4d5;
    }

    &:after {
      content: '\e800';
      font-size: 0.8rem;
      font-family: Checkbox;
      justify-content: center;
      align-items: center;
      display: none;
    }
  }

  &-note {
    margin-top: 8px;
    color: lighten(#000, 60%);
  }
}

.checked > .Checkbox-label:after {
  display: flex;
}

.Checkbox.disabled {
  opacity: 0.5;
  pointer-events: none;
}
