// Some bootstrap-like helpers
@import './globals-spacing.scss';

.clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center;
}

.text-danger {
  color: #ff4d4f;
}

.text-success {
  color: #009b72;
}

.text-secondary {
  color: $CIRRUS_TEXT;
}

.text-bold {
  font-weight: $BOLD;
}
.text-underline {
  text-decoration: underline !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
.hidden {
  display: none !important;
}
.visibilityHidden {
  visibility: hidden !important;
}

// Other globals classes
.formgroup {
  margin-bottom: 1.3em;
}

.nowrap {
  white-space: nowrap;
}

.loading-container {
  max-width: 28rem;
  margin: 0 auto;
}

.relative {
  position: relative !important;
}

.r-0 {
  right: 0 !important;
}

.multi-fields {
  & .Input {
    flex: 1;

    &:first-child {
      padding-right: 0.5em;
    }

    &:last-child {
      padding-left: 0.5em;
    }
  }
}

.block-display {
  display: block !important;
}

.block-display-pading {
  display: block !important;
  padding-bottom: 75px;
}

.label-title-from {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.multi-item-left {
  float: left;
  clear: none;
  width: 49%;
}

.multi-item-right {
  float: right;
  clear: none;
  width: 49%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-between {
  justify-content: space-between;
}

.scrollable {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
    background: transparent !important;
    border-bottom-right-radius: 3px !important;
  }

  &::-webkit-scrollbar-button {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  &::-webkit-scrollbar-corner {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent !important;
    background-clip: padding-box !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}

.hoverable {
  cursor: pointer;
}
.ant-notification-notice-icon-error {
  color: #f5222d !important;
}

.loading.full-screen {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.dropdown > .clear.icon:before {
  content: 'x';
}

.cell-correspondence-content {
  width: 20em;
  &-body {
    display: block;
    white-space: nowrap;
    width: 20em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.cell-correspondence {
  width: 20em;
}
