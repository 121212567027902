.custom-modal {
  .ant-modal-title {
    font-size: 1.4em;
  }

  .modal-confirm-message {
    i {
      margin-right: 10px;
    }

    font-size: 1.2em;
    font-weight: bold;
  }

  .ant-modal-footer button:last-child {
    margin-right: 0;
    margin-left: 7px;
  }

  .ant-modal-body {
    max-height: 46em;
    overflow: auto;
  }

  .ant-modal-addOrEdit {
    min-height: 100%;
    overflow: auto;
  }

  .ant-modal-LoginHistory {
    min-height: 70vh;
    min-width: 90vh;
    overflow: auto;
  }

}

.modal-spin {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-form-disabled {
  opacity: 0.5;
  pointer-events: none;
}