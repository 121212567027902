@import '../../../styles/variables.scss';

.SidebarMenus {
  position: absolute;
  top: $HEADER_HEIGHT + $DASHBOARD_SITELOGO_HEIGHT;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 0.8rem 0.5rem;
  padding-bottom: 100px;
  background-color: $SIDEBAR_BACKGROUND-COLOR;
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  .MenuGroup,
  .MenuItem {
    text-transform: uppercase;
    &:before {
      border-color: #4d4d4d;
    }
    &:hover {
      background-color: #3d3d3d;
      &:before {
        border-color: transparent;
      }
    }
  }
  .MenuGroup-Children {
    .MenuItem {
      text-transform: none;
    }
  }
}

.labelMenu {
  cursor: default !important;
  // padding-left: 35px !important;
}
