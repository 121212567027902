.Input {
  margin-bottom: 1em;
  position: relative;

  &-Label {
    display: inline-block;
    user-select: none;
    margin-bottom: 0.2em;
    color: rgba(0, 0, 0, 0.7);

    &:after {
      content: ':';
    }

    &.required:before {
      content: '*';
      color: red;
      margin-right: 0.1em;
      position: relative;
      top: 0.1em;
    }
  }

  &-Error {
    &Pointing {
      line-height: 1.3em !important;
      &:before {
        top: 0.1em !important;
        left: 1.2em !important;
      }
    }

    .ui.label {
      margin-top: 0.7em !important;
    }
  }

  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:-ms-input-placeholder {
      color: #ccc !important;
    }
  }

  &-Icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.75em;
    height: 2.75em;
    text-align: center;
    vertical-align: middle;
    pointer-events: none;

    svg {
      width: 1.7em;
      height: 1.7em;
      margin-top: 0.5em;
    }
  }

  &.hasLabel {
    .Input-Icon {
      top: 1.35em;
    }
  }
}

button[type='submit'] {
  margin-right: 0 !important;
}
