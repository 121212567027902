@import '../../styles/variables.scss';

.Pagination.ui.pagination.menu {
  box-shadow: none;
  border: none;
  min-height: 2em;

  & > .item {
    background-color: transparent;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 0;
    min-width: 2em;
    color: $CIRRUS_TEXT;
    font-weight: normal;

    &.icon {
      &:first-child {
        margin-right: 2em;
      }
      &:last-child {
        margin-left: 2em;
      }
    }

    &::before {
      width: 0;
    }

    &.active {
      background-color: $CIRRUS;
      color: #fff;
      padding-top: 0;
    }
  }
  .icon {
    color: $CIRRUS !important;
  }
}
