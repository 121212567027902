// Override font family & font size for both semantic ui and antd
html {
  font-size: 13px !important;
  @media (min-width: 1400px) {
    font-size: 14px !important;
  }
  @media (min-width: 1500px) {
    font-size: 15px !important;
  }
}
body {
  font-family: Lato, Roboto, Tahoma, sans-serif !important;
  font-size: 1rem !important;
  font-weight: $NORMAL !important;
  overflow: auto;
  min-width: $BODY_MIN_WIDTH;
  min-height: $BODY_MIN_HEIGHT;
}
#root {
  min-width: 100%;
  min-height: 100%;
  position: relative;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  opacity: 1;
}

.ui,
.ui input,
.ant-notification {
  font-family: inherit !important;
  font-weight: inherit !important;
}
.ui.header,
.ui.button,
.ant-notification-notice-message {
  font-weight: $BOLD !important;
}

// Override inner scroll bar css from semantic ui
body ::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

// Remove outline glow
*,
*:before,
*:after {
  outline: none !important;
}

// Fix safari input line height
.ui input {
  height: 38rem/14 !important;
}

.ui.input>input{
  line-height: 1.5em;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

.step-container-sticky {
  position: sticky;
  top: 130px;
  z-index: 1;
  min-height: 600px;
  max-height: 700px;
}

::placeholder {
  color: rgba(0,0,0,.87) !important;
  opacity: 0.92 !important;
}

:-ms-input-placeholder {
  color: rgba(0,0,0,.87) !important;
}

::-ms-input-placeholder {
  color: rgba(0,0,0,.87) !important;
}

.row-orange-cls{background: #ee7a274d!important;
  color: #e96619!important;}