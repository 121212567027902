.form-progress-cr {
  grid-column: 1 / -1;
  margin-bottom: 3em;
  overflow: hidden;
  position: sticky;
  height: 75px; //fix to auto if it has description
  top: 0;
  background-color: #fff;
  z-index: 1;
  .form-progress-step {
    grid-column: 1 / -1;
    margin-bottom: 3em;
    overflow: hidden;
    position: relative;
    margin: 0 75px 0 75px;
    .ant-steps {
      transition: transform 0.2s;
    }

    .ant-steps-item {
      flex-basis: 300px;
      flex-shrink: 0;
      cursor: pointer;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      white-space: break-spaces;
    }

    .ant-steps-item-title {
      width: 203px !important;
    }

    .ant-steps-item-description {
      max-width: 250px !important;
    }
  }

  button {
    position: absolute;
    z-index: 1;
    transform: translateY(-50%);
  }

  button:first-child {
    left: 0;
    top: 60%; //fix to 50 percent if it has description
  }

  button:last-child {
    right: 0;
    top: 60%; //fix to 50 percent if it has description
  }
}
