.conversation-form {
  .ant-col.ant-form-item-label {
    line-height: 2;
  }

  .ant-row.ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    line-height: 1;
  }
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
  .add-file-container-text {
    text-align: center;
  }
  .add-file-container-btn-area {
    display: flex;
    justify-content: center;
    .button-add-file {
      position: absolute;
      bottom: -15px;
      width: 70%;
    }
  }
  .upload-box {
    height: 7em;
    position: relative;

    .upload-file {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .ant-spin {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3090;
        background: rgba(0, 0, 0, 0.04);
      }

      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }

    .file-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      &-inner {
        display: inline-flex;
        width: 90%;

        i {
          font-size: 3em;
          height: 100%;
        }

        .file-name {
          color: #25828b;
          margin-left: 0.5em;
          display: block;
          white-space: nowrap;
          width: 900px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .file-actions {
      cursor: pointer;
      font-size: 1.3em;

      i.download {
        margin-right: 0.8em;
      }
    }
  }
}
