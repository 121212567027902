@import '../../../styles/variables.scss';
.Radio{
    margin-bottom: 1em;
    margin-left: 1em;
}
.form-action-note-imaging-service {
  padding: 1em;
  border-radius: 3px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .form-group {
    font-size: 1.03em;
    margin-top: 5px;
  }

  .form-group.Checkbox {
    margin-bottom: 1em;
  }

  .datetime-picker {
    display: flex;
    justify-content: space-between;

    > span {
      width: 100% !important;

      input {
        height: 2.875em;
        border-color: rgba(124, 138, 162, 0.3);
        color: #7c8aa2 !important;

        &:hover {
          border-color: rgba(34, 36, 38, 0.35) !important;
        }

        &:focus {
          border-color: $CIRRUS !important;
          box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
        }
      }
    }

    &.date-picker span {
      width: 100% !important;
    }
  }
}
