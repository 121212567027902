.Form-Container-DR {
  width: 100%;
  height: 100vh;
  &-View {
    margin-bottom: 5px;
  }
  &-Profile-img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
  }
  &-Consultants {
    width: 200px;
    height: 20px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #25828b;
  }
  &-Name {
    width: 262px;
    height: 38px;
    font-family: Lato;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 1px;
    color: #4a4a4a;
  }
  &-Checkbox-avaiable {
    width: 262px;
    height: 38px;
    font-family: Lato;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 1px;
    color: #4a4a4a;
  }
  &-Label {
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #4a4a4a !important;
  }
  &-Text {
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2 !important;
    letter-spacing: 0.34px !important;
    color: #7c8aa2 !important;
  }
  &-Label-Preferences {
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
    color: #2b2b2b !important;
  }
}
.Form-Loading-DR {
  display: flex;
  height: 75vh;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.Form-Group-label {
  font-family: Lato;
  font-size: 30px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #4a4a4a !important;
  margin-top: 1rem !important;
}
.Form-Field-label {
  opacity: 0.86;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #4a4a4a !important;
}
.Segment-View {
  margin-top: 10px;
}
.Field-option {
  border-radius: 4px;
  font-size: 14px !important;
  border: solid 1px rgba(37, 130, 139, 0.2);
  background-color: rgba(37, 130, 139, 0.1);
  margin-top: 5px !important;
}

.Label-item {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: black !important;
}
.Form-Field-Link {
  margin-left: 1em;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.34px;
}
.Form-Field-Text {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.34px;
  color: #7c8aa2 !important;
  &-content {
    display: block;
    white-space: nowrap;
    width: 20em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-subject {
    width: 20em;
  }
}
.Form-field-new-ui-checkbox {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  margin-right: 22px !important;
  margin-top: 5px;
}

.Form-field-radio {
  height: 19px;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  margin-right: 2rem !important;
  margin-top: 5px;
}
.Preferences-img {
  width: 50px;
  height: 49.6px;
  border-radius: 50%;
}
.Field-preferences {
  margin-top: 22px;
}
.Field-preferences-label {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #25828b !important;
}
.Field-preferences-text {
  font-family: Lato;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7c8aa2 !important;
}
.Field-preferences-Date {
  font-family: Lato;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(74, 74, 74, 0.6) !important;
}
.Field-Shown-to-CM-and-staf {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(74, 74, 74, 0.6);
}
.Field-Shown-to-CM-and-staf-icon {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #7a7a7a !important;
}

.Field-document-content {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #25828b;
}
.Field-document-content-no-file {
  opacity: 0.3;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #7a7a7a !important;
}

.Field-document-action {
  font-family: Material-Design-Iconic-Font;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.43px;
  color: #4a4a4a !important;
  font-size: 18px !important;
  &-delete {
    color: #c4737d !important;
    font-size: 18px !important;
  }
}

.Medical-centre-card {
  width: 262px !important;
  height: 350px !important;
}
.Medical-centre-Image {
  width: 100%;
  height: 240px;
  background: url('./placeholder.png');
  background-size: auto;
}
.Medical-centre-Name {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #25828b !important;
  margin-top: 20px !important;
}
.Medical-centre-Address {
  font-family: Lato;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a !important;
}
.Medical-centre-Image-icon {
  opacity: 0.5;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  float: right;
  padding-top: 10px;
}
.Medical-centre-box-icon {
  text-align: center;
  margin-top: 10px;
}

.Medical-centre-Icon {
  font-size: 22px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.64 !important;
  letter-spacing: normal !important;
  color: #25828b !important;
  margin: 10px !important;
  cursor: pointer;
  &:nth-child(2) {
    font-size: 24px !important;
  }
  &:nth-child(3) {
    font-size: 24px !important;
  }
  &:nth-child(1) {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }
}
.Medical-centre-Add-New {
  width: 262px !important;
  height: 350px !important;
  border: solid 2px #25828b !important;
  background-color: rgba(37, 130, 139, 0.05) !important;
  border-style: dashed !important;
  text-align: center !important;
  cursor: pointer !important;

  //Disable select text
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Medical-centre-content {
  margin: auto;
}
.Medical-centre-Add-New-icon {
  width: 37px !important;
  height: 50px !important;
  font-size: 30px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.64px !important;
  color: #25828b !important;
  text-decoration: none !important;
}
.Medical-centre-Add-New-text {
  width: 91px !important;
  height: 17px !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  color: #25828b !important;
}

.Form-Field-table {
  margin-top: 2rem;
  overflow-x: auto;
  overflow-y: auto;
}

.btn-text-show {
  float: right !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
  color: rgba(122, 122, 122, 0.51) !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.popup-clinic {
  display: flex;
  &-input {
    width: 360px !important;
    height: 46px !important;
    border-radius: 4px !important;
    border: solid 1px rgba(124, 138, 162, 0.5) !important;
    background-color: #ffffff !important;
    //
    opacity: 0.7 !important;
    font-family: Lato !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.38 !important;
    letter-spacing: normal !important;
    color: #7c8aa2 !important;
  }
  &-dropdown {
    width: 254px !important;
    height: 46px !important;
    border-radius: 4px !important;
    border: solid 1px rgba(124, 138, 162, 0.5) !important;
    background-color: #ffffff !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    //
    font-family: Lato !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.88 !important;
    letter-spacing: normal !important;
    color: #4a4a4a !important;
  }
  &-header {
    margin-bottom: 29px;
    &-text {
      font-family: Lato !important;
      font-size: 20px !important;
      font-weight: bold !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.9 !important;
      letter-spacing: 0.67px !important;
      color: #4a4a4a !important;
    }
    &-icon {
      font-size: 20px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.9 !important;
      letter-spacing: 1px !important;
      color: #4a4a4a !important;
      float: right;
      vertical-align: middle;
      position: relative;
      cursor: pointer;
    }
  }
  margin-bottom: 24px;
}

//Table Clinice
.Modal-Form-Field-table {
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: auto;
}

.Tab-label {
  font-size: 18px;
}

.Form-table-DRDetail-cell-label {
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.88 !important;
  letter-spacing: normal !important;
  color: #4a4a4a !important;
}

.Form-table-DRDetail-cell-img {
  width: 30px !important;
  height: 30px !important;
}
.Form-table-DRDetail-cell-id {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  color: #25828b !important;
  cursor: pointer;
}
.Form-table-DRDetail-cell-name,
.Form-table-DRDetail-cell-address {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  color: #7c8aa2 !important;
}
.Form-table-DRDetail-cell-icon {
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  text-align: right !important;
  color: #c4737d !important;
  cursor: pointer;
}
.Form-table-DRDetail-cell-add {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  text-align: right !important;
  color: #25828b !important;
  cursor: pointer;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DoctorDetail {
  .Avatar {
    width: 180px;
    height: 180px;
    display: inline-flex;
    border: 1px dashed #25828b;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }

    &-Actions {
      margin-top: 10px;
      // display: flex;
    }

    &.Upload {
      cursor: pointer;
      background-color: rgba(37, 130, 139, 0.05);

      div {
        margin: auto;
        text-align: center;
        color: #25828b;
      }
    }
  }

  .Upload-File {
    padding: 0.5em;
    background-color: rgba(122, 122, 122, 0.08);
    border-radius: 0.28571429rem;
    display: flex;
    align-items: center;
    height: 76px;

    .FileName {
      flex-grow: 2;
      max-width: 84%;
    }
    .Actions > i {
      cursor: pointer;
    }

    .Button {
      cursor: pointer;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 46px;
      font-size: 14px;
      line-height: 14px;
      background-color: #4a4a4a;
      color: white;
      border-radius: 0.28571429rem;
      margin-right: 1em;
    }
    p {
      color: #7a7a7a;
      opacity: 0.8;
    }
  }

  .PageFooter {
    position: sticky;
    bottom: 0;
    background-color: white;
    margin-top: 1em;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    padding: 1em;
    display: flex;
    align-items: center;
  }
}

.Modal-Conversation,
.Modal-ChangePassword {
  .ant-col.ant-form-item-label {
    line-height: 2;
  }

  .ant-row.ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-form-item-control {
    line-height: 1;
  }
}

.Modal-ChangePassword {
  .List-password-strength {
    width: 30rem;
    height: 7rem;
    margin-bottom: -40px;
    margin-top: -20px;
  }
  .List-password-strength label {
    position: relative;
    display: block;
    font-size: 1em;
    color: #ff4757;
  }
  .List-password-strength .checked-icon {
    position: absolute;
    top: 5px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid #ff4757;
  }

  .List-password-strength input[type='checkbox'] {
    -webkit-appearance: none;
  }

  .List-password-strength input[type='checkbox']:checked ~ .checked-icon {
    bottom: 10px;
    border-top: none;
    border-right: none;
    height: 8px;
    width: 17px;
    transform: rotate(-45deg);
    border-color: #23ad5c;
  }
  .List-password-strength span {
    position: relative;
    left: 25px;
    transition: 0.5s;
  }
  .List-password-strength span:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: translateY(-50%) scaleX(0);
    transform-origin: right;
    transition: transform 0.5s;
  }
  .List-password-strength input[type='checkbox']:checked ~ span:before {
    transform: translateY(-50%) scaleX(1);
    transform-origin: left;
    transition: transform 0.5s;
  }
  .List-password-strength input[type='checkbox']:checked ~ span {
    color: #23ad5c;
  }
}

.invalid-label-eror-doctor-details {
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
  &-error {
    color: white;
  }
}
.input-doctor-details-edit {
  &-error input {
    border: solid 1px red !important;
  }
}

.Modal-UploadProfilePicture {
  text-align: center;

  .Button {
    cursor: pointer;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    height: 46px;
    font-size: 14px;
    line-height: 14px;
    background-color: #4a4a4a;
    color: white;
    border-radius: 0.28571429rem;
  }

  .cropper-view-box,
  .cropper-crop-box {
    box-shadow: 0 0 0 1px #39f;
    border-radius: 50%;
    outline: 0;
  }

  canvas {
    display: none;
  }
}

///
.Field-Password-Checker {
  width: 30rem;
  height: 2.125em;
}

.Field-text-password {
  &-short {
    color: lightgrey;
  }
  &-weak {
    color: #ff4757;
  }
  &-medium {
    color: orange;
  }
  &-good {
    color: #3399ff;
  }
  &-strong {
    color: #23ad5c;
  }
}

.indicator {
  height: 10px;
  margin: 10px 0;
  width: 100%;
  display: block;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.percent {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: lightgrey;
  box-shadow: inset;
}
.progress {
  position: absolute;
  border-radius: 10px;
  background: #fff;
  box-shadow: inset 0 0 2px #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: animate 1s ease-in-out;
}

@keyframes animate {
  from {
    width: 0;
  }
}
