// Change the loading style and icon position in the Button component
// Only for the standard buttons with dark background and white text
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading {
  color: white !important;
  &:before,
  &:after {
    left: auto !important;
    right: 0.65em;
  }
  .icon {
    background-color: rgba(0, 0, 0, 0.05) !important;
    &:before {
      display: none;
    }
  }
}

form {
  .ui.icon.button.label.clear {
    border-radius: 0;
    background-color: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-left: 0;
  }
}