@import url(https://cdnjs.cloudflare.com/ajax/libs/antd/3.10.0/antd.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.3.3/semantic.min.css);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
@charset "UTF-8";
html {
  font-size: 13px !important;
}
@media (min-width: 1400px) {
  html {
    font-size: 14px !important;
  }
}
@media (min-width: 1500px) {
  html {
    font-size: 15px !important;
  }
}

body {
  font-family: Lato, Roboto, Tahoma, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  overflow: auto;
  min-width: 1150px;
  min-height: 550px;
}

#root {
  min-width: 100%;
  min-height: 100%;
  position: relative;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  opacity: 1;
}

.ui,
.ui input,
.ant-notification {
  font-family: inherit !important;
  font-weight: inherit !important;
}

.ui.header,
.ui.button,
.ant-notification-notice-message {
  font-weight: 700 !important;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

*,
*:before,
*:after {
  outline: none !important;
}

.ui input {
  height: 38rem/14 !important;
}

.ui.input > input {
  line-height: 1.5em;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

.step-container-sticky {
  position: sticky;
  top: 130px;
  z-index: 1;
  min-height: 600px;
  max-height: 700px;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 0.92 !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 0.92 !important;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}

::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}

.row-orange-cls {
  background: rgba(238, 122, 39, 0.3019607843) !important;
  color: #e96619 !important;
}

/* Spacing helper like bootstrap utils */
.pt-0 {
  padding-top: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.pa-0 {
  padding: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.ma-0 {
  margin: 0rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pa-1 {
  padding: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.ma-1 {
  margin: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.pa-2 {
  padding: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.ma-2 {
  margin: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pa-3 {
  padding: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.ma-3 {
  margin: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.pa-4 {
  padding: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.ma-4 {
  margin: 4rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.pr-5 {
  padding-right: 5rem !important;
}

.py-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-5 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.pa-5 {
  padding: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.my-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-5 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.ma-5 {
  margin: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.text-danger {
  color: #ff4d4f;
}

.text-success {
  color: #009b72;
}

.text-secondary {
  color: #595959;
}

.text-bold {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.hidden {
  display: none !important;
}

.visibilityHidden {
  visibility: hidden !important;
}

.formgroup {
  margin-bottom: 1.3em;
}

.nowrap {
  white-space: nowrap;
}

.loading-container {
  max-width: 28rem;
  margin: 0 auto;
}

.relative {
  position: relative !important;
}

.r-0 {
  right: 0 !important;
}

.multi-fields .Input {
  -ms-flex: 1 1;
      flex: 1 1;
}
.multi-fields .Input:first-child {
  padding-right: 0.5em;
}
.multi-fields .Input:last-child {
  padding-left: 0.5em;
}

.block-display {
  display: block !important;
}

.block-display-pading {
  display: block !important;
  padding-bottom: 75px;
}

.label-title-from {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.multi-item-left {
  float: left;
  clear: none;
  width: 49%;
}

.multi-item-right {
  float: right;
  clear: none;
  width: 49%;
}

.d-flex {
  display: -ms-flexbox;
  display: flex;
}

.flex-column {
  -ms-flex-direction: column;
      flex-direction: column;
}

.flex-end {
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.flex-between {
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.scrollable {
  overflow: auto;
}
.scrollable::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background: transparent !important;
  border-bottom-right-radius: 3px !important;
}
.scrollable::-webkit-scrollbar-button {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
}
.scrollable::-webkit-scrollbar-corner {
  background: transparent !important;
}
.scrollable::-webkit-scrollbar-thumb {
  border: 2px solid transparent !important;
  background-clip: padding-box !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.hoverable {
  cursor: pointer;
}

.ant-notification-notice-icon-error {
  color: #f5222d !important;
}

.loading.full-screen {
  width: 100%;
  height: 90vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.Text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.dropdown > .clear.icon:before {
  content: "x";
}

.cell-correspondence-content {
  width: 20em;
}
.cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-correspondence {
  width: 20em;
}

.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading {
  color: white !important;
}
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading:before, .ui.button.icon.labeled.loading.loading.loading.loading.loading.loading:after {
  left: auto !important;
  right: 0.65em;
}
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading .icon {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.ui.button.icon.labeled.loading.loading.loading.loading.loading.loading .icon:before {
  display: none;
}

form .ui.icon.button.label.clear {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 0;
}

.ui.button.button--overridden-style {
  padding-top: 0.9375em;
  padding-bottom: 0.9375em;
}

.input--overridden-style .ui.input > input, .input--overridden-style.ui.input > input {
  height: 2.875em;
  border-color: rgba(124, 138, 162, 0.3);
  color: #7c8aa2 !important;
}
.input--overridden-style .ui.input > input:hover, .input--overridden-style.ui.input > input:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.input--overridden-style .ui.input > input:focus, .input--overridden-style.ui.input > input:focus {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.input--overridden-style .ui.input.error > input, .input--overridden-style .ui.input.error > input:hover, .input--overridden-style .ui.input.error > input:focus, .input--overridden-style.ui.input.error > input, .input--overridden-style.ui.input.error > input:hover, .input--overridden-style.ui.input.error > input:focus {
  border-color: #e0b4b4 !important;
}
.input--overridden-style > input .ui.label {
  margin-top: 0.3rem !important;
}

.input--overridden-style.disabled input {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3);
  background-color: rgba(124, 138, 162, 0.16);
  background: rgba(0, 0, 0, 0.07);
}

.ui.search.input--overridden-style {
  min-height: 2.875em;
  border-color: rgba(124, 138, 162, 0.3);
}
.ui.search.input--overridden-style input,
.ui.search.input--overridden-style .text {
  color: #7c8aa2 !important;
}
.ui.search.input--overridden-style:focus, .ui.search.input--overridden-style.active {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.ui.search.input--overridden-style.error:focus, .ui.search.input--overridden-style.error.active, .ui.search.input--overridden-style.error > .menu {
  border-color: #e0b4b4 !important;
}
.ui.search.input--overridden-style.disabled {
  border-color: rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
  background: rgba(0, 0, 0, 0.07) !important;
}
.ui.search .prompt {
  border-radius: 3px !important;
}

.ui.radio.checkbox.radio--overridden-style .box,
.ui.radio.checkbox.radio--overridden-style label,
.ui.radio.checkbox.radio--overridden-style .box,
.ui.radio.checkbox.radio--overridden-style label {
  color: #595959;
}
.ui.radio.checkbox.radio--overridden-style .box:before,
.ui.radio.checkbox.radio--overridden-style label:before,
.ui.radio.checkbox.radio--overridden-style .box:before,
.ui.radio.checkbox.radio--overridden-style label:before {
  background-color: transparent;
  border-color: #595959;
}
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:before,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:before,
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:before,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:before {
  border-color: #1fc0da !important;
}
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:after,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:after,
.ui.radio.checkbox.radio--overridden-style input:checked ~ .box:after,
.ui.radio.checkbox.radio--overridden-style input:checked ~ label:after {
  background-color: #1fc0da !important;
}

.ui.ui.ui.button.blue {
  background-color: #1fc0da !important;
}
.ui.ui.ui.button.blue:hover {
  background-color: #1db4cd !important;
}
.ui.ui.ui.message.blue {
  background-color: #e3f8fb !important;
  color: #1fc0da !important;
  box-shadow: 0 0 0 1px #1fc0da inset, 0 0 0 0 transparent;
}
.ui.ui.ui.button.red {
  background-color: rgb(201, 48, 44) !important;
}
.ui.ui.ui.button.red:hover {
  background-color: #bc2d29 !important;
}
.ui.ui.ui.label.red {
  background-color: rgb(201, 48, 44) !important;
}
.ui.ui.ui.button.green {
  background-color: #038951 !important;
}
.ui.ui.ui.button.green:hover {
  background-color: #037a48 !important;
}
.ui.ui.ui.label.green {
  background-color: #038951 !important;
}

.link {
  color: #1fc0da !important;
  text-decoration: none !important;
  transition: none !important;
  cursor: pointer;
}
.link:hover {
  color: #1fc0da !important;
  text-decoration: underline !important;
}
.link.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.Input-Field > .ui.search.selection.dropdown > i.dropdown.icon.clear:before {
  content: "✖";
}

form .field .Upload-File p,
.add-file-container .Upload-File p {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 1 !important;
}

form.ui.form .fields .disabled.field,
form.ui.form .disabled.field > label.Form-Field-label,
form.ui.form .disabled.fields > .field > label.Form-Field-label {
  opacity: 0.75;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: not-allowed;
}

form .ui.icon.button.label.clear {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-left: 0;
}
form .ui.label > span.clickable {
  cursor: pointer;
}
form .ui.disabled.checkbox label {
  color: rgba(0, 0, 0, 0.87) !important;
  opacity: 1 !important;
}
form .ui.selection.dropdown .ui.label,
form .ui.selection.dropdown .default.text {
  color: rgba(0, 0, 0, 0.87);
}

form.ant-form.grid-style .ant-collapse {
  grid-column-start: 1;
  grid-column-end: span 2;
}
form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 12px 0;
}
form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header > i {
  padding-right: 1em;
  -ms-flex-item-align: center;
      align-self: center;
  justify-self: center;
}
form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-content > .title {
  -ms-flex-item-align: center;
      align-self: center;
}
@media (max-width: 1350px) {
  form.ant-form.grid-style .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 1rem;
  }
}
form.ant-form.grid-style .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 6px;
}
form.ant-form.grid-style .ant-collapse .ant-collapse-content-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2em), 1fr));
  grid-gap: 0.3em 2em;
}
form.ant-form.grid-style .ant-collapse .ant-collapse {
  padding-left: 8px;
}

form.ant-form label.checkbox-session.ant-checkbox-wrapper {
  font-weight: bold;
  background-color: gray;
  padding: 5px;
  width: 350px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
form.ant-form label.checkbox-session.ant-checkbox-wrapper > span {
  color: #fff !important;
}
form.ant-form label.checkbox-session.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #2cbf2c;
}
form.ant-form .ant-form-item.cancellation-label > .ant-form-item-label > label {
  font-size: 1.2em;
  font-weight: 700;
}
form.ant-form .add-file-container {
  min-height: 110px;
}
form.ant-form .ant-form-item {
  margin-bottom: 15px;
}
form.ant-form .ant-checkbox-disabled .ant-checkbox-inner,
form.ant-form .ant-radio-disabled .ant-radio-inner {
  background-color: darkgray;
  border-color: darkgreen !important;
}
form.ant-form .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner,
form.ant-form .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
  background-color: darkgreen;
}
form.ant-form .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #fff;
}
form.ant-form .ant-radio-disabled.ant-radio-checked .ant-radio-inner:after {
  background-color: #fff;
}
form.ant-form .align-start {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
}
form.ant-form .ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.87) !important;
}
form.ant-form .checkbox-field.bold-text > label > span:nth-child(2) {
  font-weight: 700;
  font-size: 1rem;
}

.ant-select,
.ant-select-disabled,
.ant-input,
.ant-input-disabled,
.ant-input-number-disabled,
.ant-radio-disabled + span,
.ant-checkbox-disabled + span,
.ant-checkbox-disabled + span,
.ant-checkbox + span,
.ant-checkbox-wrapper + span,
.ant-radio-wrapper + span,
.ant-select-search__field__placeholder,
.ant-select-selection__placeholder,
.ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.87) !important;
}
.Checkbox {
  position: relative;
  backface-visibility: hidden;
  font-size: 1rem;
  line-height: 1.2rem;
}
.Checkbox .Input {
  position: absolute;
  display: none;
}
.Checkbox-label {
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
  position: relative;
  font-size: 1em;
  color: #595959;
  transition: color 0.1s ease;
  padding-left: 1.8rem;
}
.Checkbox-label:hover {
  color: rgba(0, 0, 0, 0.8);
}
.Checkbox-label:before, .Checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d4d4d5;
}
.Checkbox-label:after {
  content: "\e800";
  font-size: 0.8rem;
  font-family: Checkbox;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: none;
}
.Checkbox-note {
  margin-top: 8px;
  color: #999999;
}

.checked > .Checkbox-label:after {
  display: -ms-flexbox;
  display: flex;
}

.Checkbox.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.Input {
  margin-bottom: 1em;
  position: relative;
}
.Input-Label {
  display: inline-block;
  -ms-user-select: none;
      user-select: none;
  margin-bottom: 0.2em;
  color: rgba(0, 0, 0, 0.7);
}
.Input-Label:after {
  content: ":";
}
.Input-Label.required:before {
  content: "*";
  color: red;
  margin-right: 0.1em;
  position: relative;
  top: 0.1em;
}
.Input-ErrorPointing {
  line-height: 1.3em !important;
}
.Input-ErrorPointing:before {
  top: 0.1em !important;
  left: 1.2em !important;
}
.Input-Error .ui.label {
  margin-top: 0.7em !important;
}
.Input input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Input input:-ms-input-placeholder {
  color: #ccc !important;
}
.Input-Icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.75em;
  height: 2.75em;
  text-align: center;
  vertical-align: middle;
  pointer-events: none;
}
.Input-Icon svg {
  width: 1.7em;
  height: 1.7em;
  margin-top: 0.5em;
}
.Input.hasLabel .Input-Icon {
  top: 1.35em;
}

button[type=submit] {
  margin-right: 0 !important;
}
.DatePicker {
  position: relative;
}
.DatePicker .Picker {
  position: absolute;
  z-index: 999;
  width: 22rem;
  box-shadow: 0 2px 8px 0 rgba(37, 130, 139, 0.2);
}
.DatePicker .Picker-Arrow:before, .DatePicker .Picker-Arrow:after {
  position: absolute;
  content: "";
  height: 15px;
  background: #f9fafb;
}
.DatePicker .Picker-Arrow:before {
  width: 15px;
  top: -6px;
  left: 13px;
  transform: rotate(45deg);
  border: 1px solid #1fc0da;
  box-shadow: 0 2px 8px 0 rgba(37, 130, 139, 0.2);
}
.DatePicker .Picker-Arrow:after {
  width: 30px;
  top: 1px;
  left: 5px;
}
.DatePicker .Picker table {
  border-color: #1fc0da;
  margin: 0;
}
.PasswordInput {
  position: relative;
}
.PasswordInput input {
  padding-right: 3.2em !important;
}
.PasswordInput input[type=password] {
  letter-spacing: 0.1em;
}
.PasswordInput .Input-Icon {
  top: 1.6em !important;
  width: 4em;
  cursor: pointer;
  pointer-events: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.PasswordInput .Input-Icon span {
  font-size: 0.8rem;
  padding: 0.6rem;
  margin-right: 0.5rem;
  font-weight: 700;
  color: #1fc0da;
  -ms-user-select: none;
      user-select: none;
  letter-spacing: 0.03em;
}
.Radio {
  display: inline-block;
  position: relative;
  backface-visibility: hidden;
  vertical-align: baseline;
}
.Radio .Input {
  position: absolute;
  display: none;
}
.Radio-label {
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
  position: relative;
  font-size: 1em;
  color: #595959;
  transition: color 0.1s ease;
  padding-left: 1.5rem;
  display: inline-block;
}
.Radio-label:hover {
  color: rgba(0, 0, 0, 0.8);
}
.Radio-label:before, .Radio-label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 1.1rem;
  height: 1.1rem;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
.Radio-label:after {
  display: none;
  background: #1fc0da;
  border-color: #1fc0da;
  transform: scale(0.45);
}

.checked > .Radio-label:before {
  border-color: #1fc0da;
}
.checked > .Radio-label:after {
  display: block;
}

.Radio.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.Search {
  position: relative;
}
.Search i {
  opacity: 0.2 !important;
  transition: unset !important;
}
.Search input:focus ~ i {
  opacity: 0.8 !important;
}

.Search-results {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #1fc0da;
  box-shadow: 0 10px 8px 0 rgba(37, 130, 139, 0.2) !important;
  max-height: 18.75em;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.Search-results > .item {
  padding: 0.7em 0.8em 0.7em 1em;
  cursor: pointer;
}
.Search-results > .item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.Search-results > .item:hover {
  background: rgba(0, 0, 0, 0.03);
}
.Search-results > .no-results {
  padding: 0.7em 0.8em 0.7em 1em;
  letter-spacing: 0.025em;
  color: rgba(0, 0, 0, 0.4);
}
.ui.dropdown.input--overridden-style .menu::-webkit-scrollbar {
  width: 7px;
}
.ui.dropdown.input--overridden-style .menu .item {
  letter-spacing: 0.2px;
}
.ui.dropdown.input--overridden-style .menu .item .text div:not(:first-child) {
  padding-top: 5px;
}
.ui.dropdown.input--overridden-style .menu .item .text .text-bold {
  color: rgba(0, 0, 0, 0.75);
}
.ui.dropdown.input--overridden-style .menu .item.selected {
  font-weight: normal;
}
.TextArea {
  width: 100%;
  padding: 0.78571429em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  color: #7c8aa2 !important;
}
.TextArea:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.TextArea:focus {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.TextArea.disabled {
  opacity: 0.45;
  pointer-events: none;
}
.TextArea.error {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}
.Table-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.Table-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}
.Table-container > .ant-spin.ant-spin-lg.ant-spin-spinning {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.Table-container table:not(.no-stick-header) thead {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 6px -6px;
}
.Table-container table:not(.no-stick-header) thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.Table-container table thead th.sorted {
  background-color: #e6e6e6 !important;
}
.Table-container table thead th:hover {
  background-color: #eee !important;
}
.Table-container table.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.Table-container .cursor-default {
  cursor: default !important;
}

.Cell-no-data-msg {
  padding: 4rem !important;
  text-align: center !important;
  color: #ccc;
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}

.Cell-nowrap {
  white-space: nowrap;
}

.Cell-link a {
  font-weight: bold;
  color: #1fc0da !important;
  transition: 0.2s opacity;
}
.Cell-link a:hover {
  opacity: 0.8;
}

.Cell-link-Red a {
  font-weight: bold;
  color: red !important;
  transition: 0.2s opacity;
}
.Cell-link-Red a:hover {
  opacity: 0.8;
}

.Cell-actions {
  width: 3em;
}
.Cell-actions i.ant-dropdown-trigger {
  cursor: pointer;
  margin: 0;
}

.Cell-Action-Required {
  min-width: 12.5em;
}

.Cell-Client-with-mail {
  min-width: 15.625em;
}

.Table-footer-pagination {
  border-color: #1fc0da !important;
  box-shadow: unset !important;
}
.Table-footer-pagination a.icon.item,
.Table-footer-pagination a.active.item {
  background: #1fc0da !important;
  color: #fff !important;
}
.Table-footer-pagination a.item:hover {
  background: rgba(31, 192, 218, 0.8) !important;
  color: #fff !important;
}

.pd-l-10 {
  padding-left: 10px;
}

.Wrap-count-page {
  display: -ms-flexbox;
  display: flex;
}
.Wrap-count-page p {
  padding: 0 13px;
}
.Wrap-count-page p * {
  padding: 0 3px;
}
.Wrap-count-page p:first-child {
  margin-left: -12px;
  border-right: 1.5px solid black;
}
.Toolbar-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.Toolbar-left,
.Toolbar-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 10px;
}

.Toolbar-input,
.Toolbar-range-picker input {
  width: 20rem;
}

.Toolbar-input,
.Toolbar-range-picker {
  margin-right: 5px;
}

.Toolbar-filter {
  height: 2.875em;
  min-width: 14.5em !important;
  margin-right: 5px;
}
.Toolbar-filter.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.Toolbar-filter.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}

.Toolbar-highlight-button {
  margin-right: 0 !important;
  text-transform: capitalize !important;
}

.ui.button.floating.dropdown.icon.Toolbar-dropdown-button {
  padding-top: 0.9375em;
  padding-bottom: 0.9375em;
  min-width: 200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.ui.dropdown .menu > .Toolbar-dropdown-button-item, .ui.dropdown .menu > .Toolbar-dropdown-button-item:hover {
  padding: 1em !important;
  background-color: #fff;
}
.ui.dropdown .menu > .Toolbar-dropdown-button-item:not(:first-child) {
  margin-top: 5px;
}
.ui.floating.dropdown > .menu {
  min-width: 236px;
  background-color: #ececec;
}
.react-tabs__tab-list div {
  overflow: hidden;
}

.react-tabs__tab {
  display: block;
  width: 50%;
  float: left;
  text-align: center;
  text-transform: uppercase;
  height: 50px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
}

.react-tabs__tab--selected {
  border: 0px;
  position: relative;
  color: #1fc0da;
}

.react-tabs__tab--selected::after {
  content: "";
  border-bottom: 2px solid #1fc0da;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.react-tabs__tab--selected:hover {
  color: #1fc0da;
}

.react-tabs__tab-list {
  border-bottom: 0;
}

.Radio-box {
  margin-right: 1.525em;
  margin-bottom: 1.025em;
}

.Container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.FormGroupTitle {
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 1rem;
}
.FormGroupTitle-Text {
  font-weight: 700;
}
.FormGroupTitle-Divider {
  -ms-flex: 1 1;
      flex: 1 1;
  height: 1px;
  margin: 0.75rem 0 0 0.5rem;
  background-color: #1fc0da;
}
.custom-modal .ant-modal-title {
  font-size: 1.4em;
}
.custom-modal .modal-confirm-message {
  font-size: 1.2em;
  font-weight: bold;
}
.custom-modal .modal-confirm-message i {
  margin-right: 10px;
}
.custom-modal .ant-modal-footer button:last-child {
  margin-right: 0;
  margin-left: 7px;
}
.custom-modal .ant-modal-body {
  max-height: 46em;
  overflow: auto;
}
.custom-modal .ant-modal-addOrEdit {
  min-height: 100%;
  overflow: auto;
}
.custom-modal .ant-modal-LoginHistory {
  min-height: 70vh;
  min-width: 90vh;
  overflow: auto;
}

.modal-spin {
  height: 6rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.modal-form-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.fileDrop {
  background: #f5f5f5;
  border: 1px dashed #c2c2c2;
  border-radius: 3px;
  text-align: center;
  padding: 36px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.fileDrop:hover {
  background: rgb(194, 243, 194);
  border: 1px dashed #333;
}

.list-file {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 700;
}

.list-file-ul {
  padding-left: 20px;
  list-style: decimal;
  margin: 10px 0 0;
}

.upload-icon {
  margin-bottom: 20px !important;
  font-size: 2em !important;
  color: #1db4cd;
}

.custom-modal .ant-modal-body {
  max-height: 60em !important;
  overflow: auto;
}
.send-notice {
  -ms-flex-align: unset !important;
      align-items: unset !important;
}
body {
  min-width: auto;
  min-height: auto;
  overflow: hidden;
}
.scope-video-container {
  margin: 0 auto;
  text-align: center;
  max-width: 300px;
  margin-top: 2em;
}
.scope-video-container .scope-logo {
  width: 200px;
}
.scope-video-container .form-container {
  margin-top: 5em;
}
.AddEditMySession .searchbar {
  border-radius: 2px;
}
.AddEditMySession .searchbar .Toolbar-input {
  width: calc(20rem + 26px);
}
.AddEditMySession .searchbar .Toolbar-input .text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AddEditMySession .searchbar .Toolbar-left {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.AddEditMySession .searchbar .Toolbar-range-picker,
.AddEditMySession .searchbar .Toolbar-range-picker input {
  width: 180px;
}
.AddEditMySession .searchbar__group-items {
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.AddEditMySession-form-group {
  width: 25rem;
}
.AddEditMySession-form-group .Input-Label {
  display: block;
}
.AddEditMySession .clinic-option div:last-child {
  font-size: 0.9em;
  margin-top: 3px;
}
.AddEditMySession .clinic-option .address {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.fc-license-message {
  display: none !important;
}

.modal-add-edit-session .ui.menu .item {
  font-weight: bold !important;
}
.modal-add-edit-session .edit-session-tab {
  height: 700px !important;
  overflow: auto !important;
  padding: 16px;
}
.modal-add-edit-session .form-container-add-edit-sessions {
  overflow: auto;
}
.modal-add-edit-session .form-container-add-edit-sessions .Time-Picker input {
  width: 100px;
}
.modal-add-edit-session .form-container-add-edit-sessions .checkbox-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.modal-add-edit-session .form-container-add-edit-sessions .form-title {
  letter-spacing: 0.2px;
  margin-bottom: 1.5em;
  color: #4a4a4a;
}
.modal-add-edit-session .form-container-add-edit-sessions .form-subtitle {
  margin-top: 0;
  margin-bottom: 1em;
  color: #525252;
}
.modal-add-edit-session .form-container-add-edit-sessions .fluid-field {
  grid-column: 1/-1;
}
.modal-add-edit-session .form-container-add-edit-sessions .btn-disbled {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0.9em;
  line-height: 1;
  margin-left: 5px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.modal-add-edit-session .form-container-add-edit-sessions .ant-divider.fluid-field {
  margin: 1em 0 2em 0;
}
.modal-add-edit-session .form-container-add-edit-sessions > form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2em), 1fr));
  grid-gap: 0.3em 2em;
}
.modal-add-edit-session .form-container-add-edit-sessions .ant-col.ant-form-item-label {
  line-height: 1.2;
  margin-bottom: 6px;
}
.modal-add-edit-session .form-container-add-edit-sessions .ant-col.ant-form-item-label label {
  font-size: 1.1em;
}
.modal-add-edit-session .form-container-add-edit-sessions .ant-form-item-control {
  line-height: 1;
}
.Session-affix .ant-affix {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 6px -6px;
}

.Session-note-container {
  background-color: #fff;
  margin-top: 1rem;
}
.Session-note-container .Session-note-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: flex-end;
  margin-bottom: 0.3rem;
}
.Session-note-container .Session-note-group:last-child {
  margin-bottom: 0;
}
.Session-note-container .Session-note-group.note {
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.Session-note-container .Session-note-group.note > span {
  line-height: 1;
}
.Session-note-container .Session-note-group .Session-note-title {
  margin: 0;
  line-height: 1;
  margin-right: 10px;
  font-size: 1.1em;
  letter-spacing: 0.1px;
  color: black;
  min-width: 14rem;
}
.Session-note-container .Session-note-item {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: end;
      align-items: flex-end;
  margin-right: 1.2rem;
}
.Session-note-container .Session-note-item:last-child {
  margin-right: 0;
}
.Session-note-container .Session-note-item .Session-note-label {
  line-height: 1;
  font-size: 1em;
  letter-spacing: 0.2px;
  margin-right: 7px;
  color: #000;
}
.Session-note-container .Session-note-item .Session-note-background {
  width: 1.7rem;
  height: 1.5rem;
  overflow: hidden;
  border-radius: 3px;
  padding: 1px;
}
.Session-note-container .Session-note-item.booked .Session-note-label {
  font-weight: bold;
  color: black;
}
.Session-note-container .Session-note-item.booked .Session-note-background {
  background: #a62639;
}
.Session-note-container .Session-note-item.available.inperson .Session-note-background {
  background: #009b72;
}
.Session-note-container .Session-note-item.available.video .Session-note-background {
  background: #514163;
}
.Session-note-container .Session-note-item.available.both .Session-note-background {
  background: #febf40;
}
.Session-note-container .Session-note-item.available.ndis .Session-note-background {
  background: #006eff;
}
.Session-note-container .Session-note-item.on-demand.inperson .Session-note-background {
  background: #99d7c6;
}
.Session-note-container .Session-note-item.on-demand.video .Session-note-background {
  background: #afa8b8;
}
.Session-note-container .Session-note-item.on-demand.both .Session-note-background {
  background: #fedc96;
}
.Session-note-container .Session-note-item.on-demand.ndis .Session-note-background {
  background: #559eff;
}
.Session-calendar-container {
  margin-bottom: 1rem;
}
.Session-calendar-container .calendar .popup-specialist-info {
  width: 100%;
  color: black;
}
.Session-calendar-container .calendar .popup-specialist-info-title {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  font-size: 12px;
}
.Session-calendar-container .calendar .fc-col-header-cell-cushion {
  font-size: 16px !important;
  font-weight: bold !important;
}
.Session-calendar-container .calendar .fc-popover-body {
  height: 500px !important;
  overflow: scroll !important;
  width: 800px !important;
}
.Session-calendar-container .none-results-session {
  background-color: #1fc0da;
  font-size: 16px;
  font-weight: bold !important;
  color: white !important;
}
.Session-calendar-container .session-cell-item {
  background-color: #449d44;
  font-size: 16px;
  font-weight: bold !important;
  color: white !important;
}
.Session-calendar-container .appt-cell-item {
  background-color: #c9302c;
  font-size: 16px;
  font-weight: bold !important;
  color: white !important;
}
.Session-calendar-container .unconfirmed-session-cell-item {
  background-color: #ffa500;
  font-size: 16px;
  font-weight: bold !important;
  color: white !important;
}
.Session-calendar-container .unconfirmed-appt-cell-item {
  background-color: pink;
  font-size: 16px;
  font-weight: bold !important;
  color: white !important;
}
.Session-calendar-container .Session-calendar-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 2rem;
}
.Session-calendar-container .calendar-header-date {
  font-size: 25px;
  font-weight: bold !important;
  color: black;
}
.Session-calendar-container .calendar-header-nav {
  margin-left: 1.2rem;
  color: #1fc0da;
}
.Session-calendar-container .calendar-header-nav > span {
  margin: 0 0.9rem;
}
.Session-calendar-container .calendar-header-nav > span, .Session-calendar-container .calendar-header-nav > .icon {
  cursor: pointer;
}
.Session-calendar-container .Toolbar-range-picker,
.Session-calendar-container .Toolbar-range-picker input {
  width: 25em;
}
.Session-calendar-container .ui.buttons .button.active {
  background-color: #1fc0da !important;
  color: #fff !important;
}

.calendar-header {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  color: #4a4a4a;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 0.95em;
}

.calendar-header-divider {
  width: 100%;
  height: 2px;
  background-color: rgba(124, 138, 162, 0.2);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.AdvancedFormContainer {
  width: 100%;
}

.AdvancedForm {
  background-color: #eceff4;
  padding: 1rem 1rem;
  border-radius: 3px;
}

#AdvancedForm .search-toolbar {
  -ms-flex-align: end;
      align-items: flex-end;
}
#AdvancedForm .search-toolbar .doctor-search-input {
  width: -webkit-fill-available;
  max-width: 48%;
  margin-right: 1rem;
}

.showAdvancedSearch .button {
  visibility: hidden;
}

.twinInput {
  display: -ms-flexbox;
  display: flex;
}
.twinInput:hover .Search .Input:after {
  background: rgba(34, 36, 38, 0.35) !important;
}
.twinInput .Search, .twinInput .Input {
  -ms-flex: 1 1;
      flex: 1 1;
  margin-bottom: 0 !important;
}
.twinInput .Search .Input {
  position: relative;
}
.twinInput .Search .Input input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.twinInput .Search .Input:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #d7dce3;
}
.twinInput > .Input input {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.twinInput > .Input input:focus {
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}

.twinInput .Search .Input.active:after {
  background: #1fc0da !important;
}

.Radio-area {
  margin-bottom: 5px;
  display: inherit;
}
.Radio-area .Radio {
  margin: 0 0 0 10px;
}

.input-label {
  display: inline-block;
  margin-bottom: 5px;
  color: #4a4a4a;
}

.additional-checkbox .Checkbox .Checkbox-label {
  margin: 0 !important;
}
.Tooltip {
  position: fixed;
  background: #000;
  color: #fff;
  padding: 0.6em 0.9em;
  font-size: 0.9em;
  line-height: 1.2em;
  border-radius: 3px;
  min-width: 10em;
  max-width: 50%;
  z-index: 999;
}
.Tooltip-arrow {
  content: "";
  position: absolute;
  top: -3px;
  left: 0.9em;
  width: 0.8em;
  height: 0.625em;
  background: #000;
  transform: rotate(45deg);
}

.Tooltip.swap-right > .Tooltip-arrow {
  left: auto;
  right: 0.9em !important;
}

.Tooltip.swap-top > .Tooltip-arrow {
  top: auto;
  bottom: -3px;
}
.DoctorTable__monthlySession {
  background-color: #7c8aa2;
  margin: -0.8em -0.85em;
  height: 38px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.DoctorTable__monthlySession > span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  color: #fff;
  line-height: 38px;
}
.DoctorTable__monthlySession .DoctorTable__monthlySession__month {
  min-width: 120px;
}
.DoctorTable__monthlySession .DoctorTable__monthlySession__doctor {
  min-width: 100px;
}
.DoctorTable__monthlySession.available {
  background-color: #0bb66c;
}
.DoctorTable__monthlySession.immediateAvailable {
  background-color: #038951;
}
.DoctorTable__monthlySession.immediateAvailable + i {
  display: none !important;
}

.SessionCount {
  position: relative;
  text-align: left;
  margin: 0.25rem 0;
  white-space: nowrap;
}

.SessionCountDate {
  display: inline-block;
  width: 50px;
  vertical-align: middle;
}

.SessionCountLabel {
  cursor: pointer;
}

.RightMenu {
  right: 0 !important;
  left: auto !important;
}

.columnBtns {
  width: 180px;
  margin: 0 auto;
}

.requestMoreDoctorInfoBtn,
.requestAnotherAppointmentBtn {
  padding: 5px 0 7px 0 !important;
  font-size: 0.9em !important;
  border-radius: 3px !important;
}

.requestAnotherAppointmentBtn {
  margin-top: 3px !important;
}

.mag-popover-container {
  position: fixed;
  background: #fff;
  padding: 1em;
  max-width: 40rem;
  z-index: 2080;
  border: 1px solid #d4d4d5;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  transition: all 0.3s;
}
.mag-popover-container.top {
  margin-bottom: 8px;
}
.mag-popover-container.bottom {
  margin-top: 8px;
}
.mag-popover-container:before {
  content: "";
  position: absolute;
  top: auto;
  right: auto;
  bottom: -0.30714286em;
  left: 1em;
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  margin-left: 0;
  z-index: 2;
  transform: rotate(45deg);
  box-shadow: 1px 1px 0 0 #bababc;
}
.mag-popover-container.top.right:before {
  right: 1em;
  left: auto;
}
.mag-popover-container.bottom.right:before, .mag-popover-container.bottom.left:before {
  right: 1em;
  top: -0.30714286em;
  bottom: auto;
  left: auto;
  transform: rotate(227deg);
}
.mag-popover-container.bottom.left:before {
  left: 1em;
  right: auto;
}
.mag-popover-container .mag-popover-loading {
  width: 25rem;
  height: 15rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.mag-popover-container .mag-popover-title {
  color: #4a4a4a;
  border-bottom: 1px solid #d4d4d5;
  padding-bottom: 7px;
  margin-top: 0;
  margin-bottom: 7px;
}
.mag-popover-container .mag-popover-subtitle {
  margin-top: 0;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 1px;
  letter-spacing: 0.2px;
}
.mag-popover-container .mag-popover-subtitle.red {
  color: #ff4d4f;
}
.mag-popover-container .mag-popover-content {
  color: #7c8aa2;
  margin-bottom: 6px;
}
.mag-popover-container .mag-popover-content.red {
  color: #ff7875;
}
.mag-popover-container .mag-popover-content:last-of-type {
  margin-bottom: 0;
}
.mag-popover-container .mag-popover-message {
  background-color: #e3f8fb !important;
  color: #1fc0da !important;
  padding: 7px 0;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-top: 6px;
}

.profile-picture {
  width: 180px;
}

.profile-picture--small {
  width: 3.5rem;
}

.dr-name-distance div {
  display: inline-block;
}
.dr-name-distance .distance {
  font-size: 0.9rem;
  font-style: italic;
  color: #727272;
}

.label-tag {
  margin: 0 7px 7px 0 !important;
  border-radius: 4px !important;
  border: solid 1px rgba(37, 130, 139, 0.2) !important;
  background-color: rgba(37, 130, 139, 0.1) !important;
}

.table-scroll {
  display: block;
  empty-cells: show;
}
.table-scroll thead {
  position: relative;
  display: block;
  width: 100%;
  overflow-y: scroll;
}
.table-scroll tbody {
  /* Position */
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 220px);
}
.table-scroll tr {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}
.table-scroll td, .table-scroll th {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 2;
      flex-grow: 2;
  display: block;
}
.Table__AccordionTile {
  position: relative;
}
.Table__AccordionTile .Table__AccordionIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
  color: #fff;
  cursor: pointer;
  color: #fff;
}

.td-Sessions {
  padding-top: 3px !important;
  padding-bottom: 15px !important;
}

.Session-container {
  display: -ms-flexbox;
  display: flex;
}
.Session-container:not(:first-child) {
  margin-top: 0.6em;
}
.Session-container .Session-label {
  -ms-flex: 0 1 7em;
      flex: 0 1 7em;
}
.Session-container .Appointments {
  -ms-flex: 1 1;
      flex: 1 1;
  height: 100%;
}
.Session-container .Appointments-detail {
  cursor: pointer;
  width: calc(10% - 0.6em);
  color: #fff;
  border-radius: 3px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.95em;
  height: 100%;
  position: relative;
}
.Session-container .Appointments-detail-content {
  border-radius: 3px;
  font-weight: bold;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  letter-spacing: 0.2px;
}
.Session-container .Appointments-detail-content span:first-child {
  padding-left: 0.4em;
}
.Session-container .Appointments-detail-content .video.icon {
  margin: 0;
  font-size: 0.9em;
  padding-top: 0.03em;
}
.Session-container .Appointments-detail-content span:last-child {
  padding-right: 0.4em;
}
@media (max-width: 1599px) {
  .Session-container .Appointments-detail-content {
    font-size: 0.8em;
  }
  .Session-container .Appointments-detail-content .video.icon {
    font-size: 0.8em;
    padding-top: 0;
  }
}
.Session-container .Appointments-detail:not(:first-child) {
  margin-left: 0.63em;
}
.Session-container .Appointments-detail.new-line {
  margin-top: 0.3em;
}

.Appointments-detail.booked {
  background: #a62639;
}
.Appointments-detail.reserved {
  background: #1FC0DA;
}
.Appointments-detail.available.inperson {
  background: #009b72;
}
.Appointments-detail.available.video {
  background: #514163;
}
.Appointments-detail.available.both {
  background: #febf40;
  color: #000;
}
.Appointments-detail.onDemand.inperson {
  background: #99d7c6;
}
.Appointments-detail.onDemand.video {
  background: #afa8b8;
}
.Appointments-detail.onDemand.both {
  background: #fedc96;
  color: #000;
}
.Pagination.ui.pagination.menu {
  box-shadow: none;
  border: none;
  min-height: 2em;
}
.Pagination.ui.pagination.menu > .item {
  background-color: transparent;
  border-radius: 3px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0;
  padding-top: 0;
  min-width: 2em;
  color: #595959;
  font-weight: normal;
}
.Pagination.ui.pagination.menu > .item.icon:first-child {
  margin-right: 2em;
}
.Pagination.ui.pagination.menu > .item.icon:last-child {
  margin-left: 2em;
}
.Pagination.ui.pagination.menu > .item::before {
  width: 0;
}
.Pagination.ui.pagination.menu > .item.active {
  background-color: #1fc0da;
  color: #fff;
  padding-top: 0;
}
.Pagination.ui.pagination.menu .icon {
  color: #1fc0da !important;
}
.page-container {
  padding: 1em;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 0 4em;
}

.page-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.loading-spin {
  width: 100%;
  height: 70vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.form-container .form-title {
  letter-spacing: 0.2px;
  margin-bottom: 1.5em;
  color: #4a4a4a;
}
.form-container .form-subtitle {
  margin-top: 0;
  margin-bottom: 1em;
  color: #525252;
}
.form-container .fluid-field {
  grid-column: 1/-1;
}
.form-container .btn-disbled {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0.9em;
  line-height: 1;
  margin-left: 5px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.form-container .ant-divider.fluid-field {
  margin: 1em 0 2em 0;
}
.form-container .clinic-type-radio-group .clinic-type-radio {
  width: 255px;
}
.form-container .booking-fee-type-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.form-container .booking-fee-type-group .radio {
  width: 180px;
}
.form-container .dictation-type-radio-group .dictation-type-radio {
  width: 170px;
}
.form-container > form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2em), 1fr));
  grid-gap: 0.3em 2em;
}
.form-container .ant-col.ant-form-item-label {
  line-height: 1.2;
  margin-bottom: 6px;
}
.form-container .ant-col.ant-form-item-label label {
  font-size: 1.1em;
}
.form-container .checkbox-session .ant-checkbox-disabled + span {
  color: white !important;
}
.form-container .ant-form-item-control {
  line-height: 1;
}
.form-container .upload-box {
  height: 7em;
  position: relative;
}
.form-container .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.form-container .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.form-container .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.form-container .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.form-container .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.form-container .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.form-container .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
}
.form-container .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.form-container .upload-box .file-actions i.download {
  margin-right: 0.8em;
}

.form-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding-bottom: 1em;
  grid-column: 1/-1;
}
.form-footer .ant-divider {
  margin: 2.5em 0;
}
.form-footer .ui.primary.button {
  background: #1fc0da;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.form-footer .ui.primary.button:hover {
  background-color: #1db4cd !important;
}

.step-container .step {
  letter-spacing: 0.2px;
  padding: 1.2em 2em;
  text-align: left;
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  line-height: 1.3em;
}
.step-container .step:hover {
  color: #fff !important;
  border: solid 1px #1aaec5;
  background: #1aaec5;
}
.step-container .step:not(:last-child) {
  margin-bottom: 1em;
}
.step-container .active {
  background: #1fc0da !important;
  color: #fff !important;
}

.check-duplicate,
.field-actions {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0.9em;
  line-height: 1;
  color: #1890ff;
  margin-left: 5px;
  cursor: pointer;
  letter-spacing: 0.2px;
}
.check-duplicate:hover,
.field-actions:hover {
  color: #40a9ff;
}
.check-duplicate:disabled,
.field-actions:disabled {
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.clinic-calendar,
.btn-action {
  width: fit-content;
  padding: 0;
  background: transparent;
  border: none;
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}
.clinic-calendar:hover,
.btn-action:hover {
  color: #40a9ff;
}
.clinic-calendar:disabled,
.btn-action:disabled {
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.room-list > p {
  margin-bottom: 7px;
}
.room-list .ant-tag {
  font-size: 1em;
  padding: 0.5em 1em;
  height: fit-content;
}
.room-list .tag-input {
  width: 145px;
  height: 37px !important;
  margin: 5px 10px 5px 0;
}
.room-list .tag-item {
  margin: 5px 10px 5px 0;
}
.room-list .add-new-room {
  width: 145px;
  height: 37px;
  background: #fff;
  margin: 5px 10px 5px 0;
}

.registered-specialists-container.disabled table,
.registered-specialists-container.disabled .registered-specialists-searchbox {
  opacity: 0.6;
  pointer-events: none;
}
.registered-specialists-container .registered-specialists-searchbox {
  display: -ms-flexbox;
  display: flex;
}
.registered-specialists-container .registered-specialists-searchbox .ant-input-search {
  width: 35%;
  margin-right: 0.5em;
}
.registered-specialists-container .registered-specialists-searchbox .ant-select {
  width: 23%;
  margin-right: 0.5em;
}
.registered-specialists-container .registered-specialists-searchbox button.primary {
  background: #1fc0da;
}
.registered-specialists-container .registered-specialists-searchbox button.primary:hover {
  background-color: #1db4cd !important;
}
.registered-specialists-container .registered-specialists-searchbox button.primary:focus {
  background: #1fc0da;
}
.registered-specialists-container .registered-specialists-searchbox .label-tag {
  margin: 0 7px 7px 0 !important;
  border-radius: 4px !important;
  border: solid 1px rgba(37, 130, 139, 0.2) !important;
  background-color: rgba(37, 130, 139, 0.1) !important;
}
.registered-specialists-container table {
  margin-bottom: 0.5em !important;
}
.registered-specialists-container table thead tr th:first-child,
.registered-specialists-container table tbody tr td:first-child {
  width: 4em;
  text-align: center;
}
.registered-specialists-container .doctor-info {
  width: 170px;
}
.registered-specialists-container .doctor-info .avatar {
  width: 50px;
  height: 50px;
  background-size: cover !important;
  margin: auto auto 7px auto;
  border-radius: 4px;
}
.registered-specialists-container .doctor-info .doctor-name {
  text-align: center;
}

.checkbox-field {
  margin-bottom: 1.5em;
}
.checkbox-field .note {
  display: block;
  padding: 0;
  margin-top: 5px;
  color: rgb(145, 145, 145);
  font-size: 1em;
}

.action-group button {
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}

.ant-form-item-no-colon::after,
.ant-form-item-no-colon label::after {
  content: "" !important;
}

.manual-rate .ant-input-number {
  width: 100px !important;
}
.table-case-correspondence {
  overflow: auto;
  width: 60vw;
  height: 35rem;
}
.table-case-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-case-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-case-correspondence .cell-correspondence {
  width: 20em;
}

.drag-drop-file {
  text-align: center;
}
.drag-drop-file .drag-drop-file-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #25828b;
}

.add-file-container {
  min-height: 130px;
  position: relative;
}
.add-file-container .add-file-container-text {
  text-align: center;
}
.add-file-container .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.add-file-container .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.add-file-container .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.add-file-container .Upload-File .Actions > i {
  cursor: pointer;
}
.add-file-container .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.add-file-container .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}

.steps-ime-container {
  grid-column: 1/-1;
  position: relative;
  margin-bottom: 3em;
}
.steps-ime-container .steps-container {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: 0 75px 0 75px;
}
.steps-ime-container .steps-container .step-container {
  position: relative;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-align: left;
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  line-height: 1.3em;
  transition: transform 0.2s;
}
.steps-ime-container .steps-container .step-container:hover {
  color: #fff !important;
  border: solid 1px #1aaec5;
  background: #1aaec5;
}
.steps-ime-container .steps-container .active {
  background: #1fc0da !important;
  color: #fff !important;
}
.steps-ime-container button {
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
}
.steps-ime-container button:first-child {
  left: 0;
  top: 50%;
}
.steps-ime-container button:last-child {
  right: 0;
  top: 50%;
}

.appointment-clinic-type {
  min-width: fit-content;
}
.progress-statuses-container {
  position: sticky;
  grid-column: 1/-1;
  top: 0;
  width: 100%;
  background: white;
  z-index: 2;
  padding-top: 10px;
}
.progress-statuses-container .progress-statuses__sections {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: 40px;
  overflow: hidden;
}
.progress-statuses-container .progress-statuses__sections.expanding {
  height: auto;
}
.progress-statuses-container .progress-statuses .case-info {
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
}
.progress-statuses-container .progress-statuses .status-group {
  font-size: 20px;
  color: gray;
  cursor: pointer;
}
.progress-statuses-container .progress-statuses .status-group.completed {
  color: green;
}
.progress-statuses-container .progress-statuses .status-group.inprogress {
  color: green;
}
.progress-statuses-container .progress-statuses .status-group .group-icon {
  font-size: 20px;
}
.progress-statuses-container .progress-statuses .status-group .group-summary {
  margin-left: 5px;
}
.progress-statuses-container .progress-statuses .expand-section {
  text-align: center;
  margin-top: 10px;
}
.progress-statuses-container .progress-statuses .tasks-section {
  margin-top: 2rem;
  font-size: 16px;
}
.progress-statuses-container .progress-statuses .tasks-section .title {
  margin-bottom: 1rem;
  font-weight: bold;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status {
  margin-bottom: 1rem;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label {
  font-weight: bold;
  font-size: 16px;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label label .ant-checkbox, .progress-statuses-container .progress-statuses .tasks-section .task-status__label label .ant-radio {
  margin-top: 5px;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label.not-performed label, .progress-statuses-container .progress-statuses .tasks-section .task-status__label.not-performed input {
  color: orange;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label.not-performed .ant-radio-inner {
  border-color: orange;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label.not-performed .ant-radio-inner:after {
  background: orange;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label.not-performed .ant-checkbox-inner {
  background: orange;
  border-color: none;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label.not-performed .ant-checkbox-inner:after {
  background: orange;
}
.progress-statuses-container .progress-statuses .tasks-section .task-status__label.current label, .progress-statuses-container .progress-statuses .tasks-section .task-status__label.current span {
  color: green !important;
}
.progress-statuses-container .progress-statuses .tasks-section .task-info {
  padding-left: 25px;
  font-size: 14px;
}
.ant-collapse-header {
  display: inline-grid;
  grid-template-columns: 30px auto;
}
.ant-collapse-header-content {
  display: inline-grid;
  grid-template-columns: 250px auto;
  grid-gap: 0.2em 0.7em;
}
.ant-collapse-header-content > .status {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ant-collapse-header-content > .status > div:nth-child(n+2) {
  padding: 0 0 0 1rem;
}
.ant-collapse-header-content > .status .text,
.ant-collapse-header-content > .status .date {
  font-weight: 400;
}
.ant-collapse-header-content .title.required::after {
  display: inline-block;
  margin-left: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}
@media (max-width: 1350px) {
  .ant-collapse-header-content {
    grid-template-columns: auto auto;
  }
}
.add-files-urgent-report label span {
  font-size: 14px !important;
}

.dictation-transcription-header {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.dictation-transcription-wrapper {
  margin-bottom: 1rem;
  border: solid 1px lightgray;
  border-radius: 1rem;
  padding: 1rem;
}

.dictation-transcription-item {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 1rem;
}
.dictation-transcription-item .transcription-content {
  margin-top: 0.5rem;
}
.dictation-transcription-item .language-selector {
  width: 250px;
}
.conversation-form-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}
.conversation-form-container .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form-container .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form-container .ant-form-item-control {
  line-height: 1;
}
.conversation-form-container .ant-radio-group + input {
  margin-top: 10px;
}
.form-action-note {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  padding: 1em;
  border-radius: 3px;
  position: relative;
}
.form-action-note i.delete {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 1.1em;
  cursor: pointer;
}
.form-action-note i.delete:hover {
  opacity: 0.8;
}
.form-action-note:not(:last-child) {
  margin-bottom: 20px;
}
.form-action-note .form-group {
  font-size: 0.93em;
  margin-top: 5px;
}
.form-action-note .form-group.Checkbox {
  margin-bottom: 0.4em;
}
.form-action-note .datetime-picker {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.form-action-note .datetime-picker > span {
  width: 49% !important;
}
.form-action-note .datetime-picker > span input {
  height: 2.875em;
  border-color: rgba(124, 138, 162, 0.3);
  color: #7c8aa2 !important;
}
.form-action-note .datetime-picker > span input:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.form-action-note .datetime-picker > span input:focus {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.form-action-note .datetime-picker.date-picker span {
  width: 100% !important;
}
.form-action-note button.blue {
  padding-left: 2em;
  padding-right: 2em;
  margin-left: 0;
  margin-top: 0.4em;
}
.form-action-note .action-note-info {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.05em;
  margin-bottom: 5px;
}
.form-progress-action-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}
.form-progress-action-container .fluid-field {
  grid-column: 1/-1;
}
.form-progress-action-container .action-notes-icon {
  margin-left: 5px !important;
}
.form-progress-action-container .action-notes {
  background-color: #d9edf7;
  margin-top: 10px;
  padding: 10px 0 0 0;
}
.form-progress-action-container .action-notes label {
  padding: 0 0 0 1em;
  font-size: 16px;
  font-weight: 500;
}
.form-progress-action-container .action-notes ul {
  list-style: none;
  padding: 0 0 10px 0;
}
.form-progress-action-container .action-notes li {
  padding-left: 1.3em;
}
.form-progress-action-container .action-notes li:before {
  content: "- ";
}
.form-progress-action-container .popup-content {
  width: 20em !important;
  background-color: red;
}
.add-file-container-DisbursementFunding {
  min-height: 130px;
  margin-bottom: 10px;
  position: relative;
}
.add-file-container-DisbursementFunding .add-file-container-text {
  text-align: center;
}
.add-file-container-DisbursementFunding .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-DisbursementFunding .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-DisbursementFunding .upload-box {
  height: 6em;
  position: relative;
}
.add-file-container-DisbursementFunding .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-DisbursementFunding .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-DisbursementFunding .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-DisbursementFunding .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-DisbursementFunding .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-DisbursementFunding .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-DisbursementFunding .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-DisbursementFunding .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-DisbursementFunding .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.Case-progress .status-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Case-progress .status-list .status-item {
  width: 85px;
  text-align: center;
}
.Case-progress .status-list .status-item .status-box {
  width: 100%;
  height: 80px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.Case-progress .status-list .status-item .status-box .status-box-outer,
.Case-progress .status-list .status-item .status-box .status-box-inner {
  position: absolute;
  background-color: #4a4a4a;
  border-radius: 100%;
}
.Case-progress .status-list .status-item .status-box .status-box-outer {
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.Case-progress .status-list .status-item .status-box .status-box-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.04;
}
.Case-progress .status-list .status-item .status-box .status-box-inner i.icon {
  margin-top: 3px;
  margin-right: 0;
  color: #fff;
}
.Case-progress .status-list .status-item .status-box.checked .status-box-outer,
.Case-progress .status-list .status-item .status-box.checked .status-box-inner {
  background-color: rgb(44, 191, 44);
}
.Case-progress .status-list .status-item .status-box.checked .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.checked .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-outer,
.Case-progress .status-list .status-item .status-box.unchecked .status-box-inner {
  background-color: rgb(221, 221, 221);
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-label {
  display: block;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #4a4a4a;
}
.Case-progress .status-details {
  margin-top: 2.2rem;
}
.Case-progress .status-details .person-in-charge-list .ui.divider,
.Case-progress .status-details .status-date-list .ui.divider {
  margin-top: 7px !important;
}
.Case-progress .status-details .status-date-list {
  margin-top: 1.5rem;
}
.Case-progress .status-details .person-in-charge-list-item,
.Case-progress .status-details .status-date-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Case-progress .status-details .person-in-charge-list-item .person-in-charge,
.Case-progress .status-details .person-in-charge-list-item .status-date,
.Case-progress .status-details .status-date-list-item .person-in-charge,
.Case-progress .status-details .status-date-list-item .status-date {
  width: 85px;
  text-align: center;
  color: #7c8aa2;
  letter-spacing: 0.2px;
}
.Case-progress .status-details .person-in-charge-list-item .person-in-charge,
.Case-progress .status-details .person-in-charge-list-item .status-date,
.Case-progress .status-details .status-date-list-item .person-in-charge,
.Case-progress .status-details .status-date-list-item .status-date {
  overflow-wrap: break-word;
}
.Case-progress-status-history {
  margin-top: 1.5em;
}
.Case-progress-status-history > h4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  letter-spacing: 0.2px;
  color: #1fc0da;
  cursor: pointer;
  transition: color 0.2s;
}
.Case-progress-status-history > h4:hover {
  color: rgba(31, 192, 218, 0.8);
}
.Case-progress-status-history > table {
  margin-top: 0 !important;
}
.fileDrop {
  background: #f5f5f5;
  border: 1px dashed #c2c2c2;
  border-radius: 3px;
  text-align: center;
  padding: 36px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.fileDrop:hover {
  background: rgb(194, 243, 194);
  border: 1px dashed #333;
}

.list-file {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 700;
}

.list-file-ul {
  padding-left: 20px;
  list-style: decimal;
  margin: 10px 0 0;
}

.upload-icon {
  margin-bottom: 20px !important;
  font-size: 2em !important;
  color: #1db4cd;
}

.custom-modal .ant-modal-body {
  max-height: 60em !important;
  overflow: auto;
}
.Case-progress .status-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Case-progress .status-list .status-item {
  width: 85px;
  text-align: center;
}
.Case-progress .status-list .status-item .status-box {
  width: 100%;
  height: 80px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.Case-progress .status-list .status-item .status-box .status-box-outer,
.Case-progress .status-list .status-item .status-box .status-box-inner {
  position: absolute;
  background-color: #4a4a4a;
  border-radius: 100%;
}
.Case-progress .status-list .status-item .status-box .status-box-outer {
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.Case-progress .status-list .status-item .status-box .status-box-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.04;
}
.Case-progress .status-list .status-item .status-box .status-box-inner i.icon {
  margin-top: 3px;
  margin-right: 0;
  color: #fff;
}
.Case-progress .status-list .status-item .status-box.checked2 .status-box-outer,
.Case-progress .status-list .status-item .status-box.checked2 .status-box-inner {
  background-color: #dddddd;
}
.Case-progress .status-list .status-item .status-box.checked2 .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.checked2 .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-box.checked .status-box-outer,
.Case-progress .status-list .status-item .status-box.checked .status-box-inner {
  background-color: rgb(44, 191, 44);
}
.Case-progress .status-list .status-item .status-box.checked .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.checked .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-outer,
.Case-progress .status-list .status-item .status-box.unchecked .status-box-inner {
  background-color: rgb(221, 221, 221);
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-label {
  display: block;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #4a4a4a;
}
.Case-progress .status-details {
  margin-top: 2.2rem;
}
.Case-progress .status-details .person-in-charge-list .ui.divider,
.Case-progress .status-details .status-date-list .ui.divider {
  margin-top: 7px !important;
}
.Case-progress .status-details .status-date-list {
  margin-top: 1.5rem;
}
.Case-progress .status-details .person-in-charge-list-item,
.Case-progress .status-details .status-date-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Case-progress .status-details .person-in-charge-list-item .person-in-charge,
.Case-progress .status-details .person-in-charge-list-item .status-date,
.Case-progress .status-details .status-date-list-item .person-in-charge,
.Case-progress .status-details .status-date-list-item .status-date {
  width: 85px;
  text-align: center;
  color: #7c8aa2;
  letter-spacing: 0.2px;
}
.Case-progress .status-details .person-in-charge-list-item .person-in-charge,
.Case-progress .status-details .person-in-charge-list-item .status-date,
.Case-progress .status-details .status-date-list-item .person-in-charge,
.Case-progress .status-details .status-date-list-item .status-date {
  overflow-wrap: break-word;
}
.Case-progress-status-history {
  margin-top: 1.5em;
}
.Case-progress-status-history > h4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  letter-spacing: 0.2px;
  color: #1fc0da;
  cursor: pointer;
  transition: color 0.2s;
}
.Case-progress-status-history > h4:hover {
  color: rgba(31, 192, 218, 0.8);
}
.Case-progress-status-history > table {
  margin-top: 0 !important;
}
.toolbar-button {
  margin-right: 5px;
}

.toolbar-container {
  margin-bottom: 10px;
}
.toolbar-container .Toolbar-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.toolbar-container__group-items {
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
}

.toolbar-date {
  width: 200px;
}

.link-add-action {
  color: green;
}

.link-view-action {
  color: red;
}

.link-view-action-imp a {
  color: red !important;
}

.client-cell {
  width: 200px;
}

.Modal-confirm-open-newui {
  text-align: center;
}
.Modal-confirm-open-newui .Button {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 16px;
  line-height: 14px;
  background-color: #1fc0da;
  color: white;
  border-radius: 0.28571429rem;
  font-weight: bold;
}
.Case-progress .status-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Case-progress .status-list .status-item {
  width: 85px;
  text-align: center;
}
.Case-progress .status-list .status-item .status-box {
  width: 100%;
  height: 80px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.Case-progress .status-list .status-item .status-box .status-box-outer,
.Case-progress .status-list .status-item .status-box .status-box-inner {
  position: absolute;
  background-color: #4a4a4a;
  border-radius: 100%;
}
.Case-progress .status-list .status-item .status-box .status-box-outer {
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.Case-progress .status-list .status-item .status-box .status-box-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.04;
}
.Case-progress .status-list .status-item .status-box .status-box-inner i.icon {
  margin-top: 3px;
  margin-right: 0;
  color: #fff;
}
.Case-progress .status-list .status-item .status-box.checked .status-box-outer,
.Case-progress .status-list .status-item .status-box.checked .status-box-inner {
  background-color: rgb(44, 191, 44);
}
.Case-progress .status-list .status-item .status-box.checked .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.checked .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-outer,
.Case-progress .status-list .status-item .status-box.unchecked .status-box-inner {
  background-color: rgb(221, 221, 221);
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-inner {
  opacity: 1;
  box-shadow: unset;
}
.Case-progress .status-list .status-item .status-box.unchecked .status-box-outer {
  opacity: 0.4;
  background-color: rgb(200, 224, 232);
}
.Case-progress .status-list .status-item .status-label {
  display: block;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #4a4a4a;
}
.Case-progress .status-details {
  margin-top: 2.2rem;
}
.Case-progress .status-details .person-in-charge-list .ui.divider,
.Case-progress .status-details .status-date-list .ui.divider {
  margin-top: 7px !important;
}
.Case-progress .status-details .status-date-list {
  margin-top: 1.5rem;
}
.Case-progress .status-details .person-in-charge-list-item,
.Case-progress .status-details .status-date-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Case-progress .status-details .person-in-charge-list-item .person-in-charge,
.Case-progress .status-details .person-in-charge-list-item .status-date,
.Case-progress .status-details .status-date-list-item .person-in-charge,
.Case-progress .status-details .status-date-list-item .status-date {
  width: 85px;
  text-align: center;
  color: #7c8aa2;
  letter-spacing: 0.2px;
}
.Case-progress .status-details .person-in-charge-list-item .person-in-charge,
.Case-progress .status-details .person-in-charge-list-item .status-date,
.Case-progress .status-details .status-date-list-item .person-in-charge,
.Case-progress .status-details .status-date-list-item .status-date {
  overflow-wrap: break-word;
}
.Case-progress-status-history {
  margin-top: 1.5em;
}
.Case-progress-status-history > h4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  letter-spacing: 0.2px;
  color: #1fc0da;
  cursor: pointer;
  transition: color 0.2s;
}
.Case-progress-status-history > h4:hover {
  color: rgba(31, 192, 218, 0.8);
}
.Case-progress-status-history > table {
  margin-top: 0 !important;
}
.add-edit-client .business-text {
  margin-top: 1em;
}
.add-edit-client .subscribe-box {
  margin-bottom: 1.5em;
}
.add-edit-client .same-as-address {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: #1890ff;
}

.table-booking-history {
  overflow-x: auto;
  overflow-y: auto;
  height: 30em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.Anonymous-Content {
  position: relative;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-left: 22rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100vh;
  overflow: auto;
  -ms-flex-align: center;
      align-items: center;
  padding-top: 5rem;
}
@media (max-height: 549px) {
  .Anonymous-Content {
    min-height: 550px;
  }
}
@media (max-width: 920px) {
  .Anonymous-Content {
    padding-left: 0;
    padding-top: 0;
  }
}
.Anonymous-Children {
  -ms-flex: 1 1;
      flex: 1 1;
  width: 100%;
  padding: 0rem 1rem 2rem;
}
.Anonymous-Children .register-title {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  padding-bottom: 1rem;
}
@media (min-width: 920px) {
  .Anonymous-Children {
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 50rem;
  }
}
@media (max-width: 919px) {
  .Anonymous-Children .register-title {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    padding-bottom: 1rem;
    padding-top: 5rem;
  }
}
.Anonymous-Sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22rem;
  padding: 8rem 2rem 2rem 1.5rem;
  background-image: url(/Content/build/static/media/anonymous-sidebar.f004c00c.png);
  background-size: cover;
}
.Anonymous-Sidebar:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 920px) {
  .Anonymous-Sidebar {
    display: none;
  }
}
.Anonymous-Footer {
  display: -ms-flexbox;
  display: flex;
  background-color: black;
  color: white;
  bottom: 0;
  width: 100%;
}
.Anonymous-FooterItem {
  -ms-flex: 0.4 1;
      flex: 0.4 1;
  padding: 1rem 2rem;
  padding-right: 0;
}
.Anonymous-FooterItem:nth-child(3) {
  -ms-flex: none;
      flex: none;
  width: 11rem;
  float: right;
}
.Anonymous-Footer .text-secondary {
  color: #cfcfcf !important;
}
.Anonymous-Cirrus {
  position: relative;
  color: white;
}
.Anonymous-CirrusLogo {
  width: 100%;
  padding-bottom: 34%;
  background-image: url(/Content/build/static/media/mag-logo5.eef16438.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
.Anonymous-CirrusSologan {
  position: relative;
  margin-top: calc(50vh - 15rem);
  font-size: 2rem;
}
@media (max-height: 499px) {
  .Anonymous-CirrusSologan {
    margin-top: 4rem;
  }
}
.Anonymous-CirrusSologan:before {
  content: "The right doctor";
  display: block;
}
.Anonymous-CirrusSologan:after {
  content: "at the right time";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 1rem;
}
.file-review-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.file-review-toolbar .Toolbar-input {
  width: 30em;
}
.file-review-toolbar .Toolbar-range-picker {
  width: 20rem;
}
.file-review-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.file-review-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.file-review-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.file-review-toolbar .Toolbar-action-group div.text,
.file-review-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.med-meg-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.med-meg-toolbar .Toolbar-input {
  width: 30em;
}
.med-meg-toolbar .Toolbar-range-picker {
  width: 20rem;
}
.med-meg-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.med-meg-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.med-meg-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.med-meg-toolbar .Toolbar-action-group div.text,
.med-meg-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.conversation-form .ant-radio-group + input {
  margin-top: 10px;
}
.toolbar-button {
  margin-right: 5px;
}

.toolbar {
  margin-bottom: 10px;
}

.toolbar-date {
  width: 200px;
}

.link-add-action {
  color: green;
}

.link-view-action {
  color: red;
}

.client-cell {
  width: 200px;
}

.Modal-confirm-open-newui {
  text-align: center;
}
.Modal-confirm-open-newui .Button {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 16px;
  line-height: 14px;
  background-color: #1fc0da;
  color: white;
  border-radius: 0.28571429rem;
  font-weight: bold;
}

.search-title {
  margin: 0 auto;
  width: 1000px;
  text-align: center;
}
.search-title h1 {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.conversation-form .fee-difference {
  border-color: red !important;
}
.conversation-form .fee-difference input {
  color: red !important;
  border-color: red !important;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.ant-tooltip-arrow {
  border-right-color: white !important;
}

.ant-tooltip-inner {
  color: black;
  background-color: white;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}

.table .row-lightgray {
  background-color: lightgray !important;
}
.field-date {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.field-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.table-action-required-completed {
  color: red;
}

.label-title {
  font-weight: 700;
  font-size: 0.92857143em;
  color: rgba(0, 0, 0, 0.87);
}

.checkBox label {
  font-weight: 700;
  font-size: 0.92857143em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.Admin-dashboard-page .dashboard-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem 0.2rem 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  border-bottom: 1px solid #d4d4d5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02);
}
.Admin-dashboard-page .dashboard-header .statistics-group-title {
  color: #4a4a4a;
  margin-top: 0;
  margin-bottom: 7px;
  letter-spacing: 0.2px;
}
.Admin-dashboard-page .dashboard-header .statistics-group {
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}
.Admin-dashboard-page .dashboard-header .statistics-group .statistics {
  margin-bottom: 5px;
}
.Admin-dashboard-page .dashboard-header .statistics-group .statistics:not(:last-child) {
  min-width: 140px;
}
.Admin-dashboard-page .dashboard-header .statistics-group:last-child .statistics {
  margin-bottom: 0;
}
.Admin-dashboard-page .dashboard-header .statistics {
  font-size: 0.96em;
  letter-spacing: 0.2px;
}
.Admin-dashboard-page .dashboard-header .statistics-title {
  color: #7c8aa2;
}
.Admin-dashboard-page .dashboard-header .statistics-count {
  margin-left: 4px;
  font-weight: bold;
  color: #1fc0da;
}
.Admin-dashboard-page .dashboard-header .statistics:not(:last-child) {
  margin-bottom: 5px;
}
.Admin-dashboard-page .dashboard-header .statistics.statistics-inline .statistics-title {
  font-weight: bold;
}
.Admin-dashboard-page .dashboard-header .dashboard-header-left .statistics-title {
  color: #4a4a4a;
  font-size: 1.05em !important;
}
.Admin-dashboard-page .dashboard-body {
  margin-top: 9rem;
  position: relative;
}
.Admin-dashboard-page .dashboard-body .Chart-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.Admin-dashboard-page .dashboard-body .Chart-container .Toolbar {
  height: 18px;
  margin-top: 10px;
}
.Admin-dashboard-page .dashboard-body .Chart-container .Toolbar .Icon-close {
  color: red !important;
  cursor: pointer;
  font-size: 18px !important;
  float: right !important;
}
.Admin-dashboard-page .dashboard-body .Chart-container .Body {
  margin-top: 10px;
}
.Admin-dashboard-page .dashboard-body .Chart-container .Pagination {
  margin-top: 10px;
  margin-left: 40px;
}
.Admin-dashboard-page .dashboard-search-form form {
  padding: 0 34px;
}
.Admin-dashboard-page .dashboard-search-form form .ui[class*="left icon"].input > i.icon {
  left: 5px;
}
.Admin-dashboard-page .greeting {
  margin-left: 36px;
  margin-bottom: 1em;
}
.Admin-dashboard-page .greeting h1, .Admin-dashboard-page .greeting h2, .Admin-dashboard-page .greeting p {
  color: #1fc0da;
  letter-spacing: 0.3px;
  font-weight: bold;
}
.Admin-dashboard-page .greeting .convers-text {
  color: black;
}
.Admin-dashboard-page .doctor-service-group {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 16px;
  padding: 0 34px;
}
.Admin-dashboard-page .doctor-service-group .image, .Admin-dashboard-page .doctor-service-group svg {
  max-width: 26%;
  height: auto;
}
.Admin-dashboard-page .doctor-service-group .col {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
  padding-right: 5px;
}
.Admin-dashboard-page .doctor-service-group .col .button {
  background-color: #2b2b2b;
  color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  padding: 1em;
  width: 100%;
  height: 8em;
  text-align: center;
  border-radius: 12px;
  letter-spacing: 0.3px;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 0.833vw;
  text-transform: uppercase;
  line-height: 1.3;
}
.Admin-dashboard-page .doctor-service-group .col .active {
  background-color: #1fc0da !important;
}
.Admin-dashboard-page .this-month-sessions-statistics {
  background-color: #fafafa;
  padding: 2rem 1.3rem;
  text-align: center;
  border-radius: 2px;
  color: #4a4a4a;
  letter-spacing: 0.3px;
}
.Admin-dashboard-page .this-month-sessions-statistics .session-title {
  margin-bottom: 5px;
  font-size: 1.5em;
}
.Admin-dashboard-page .this-month-sessions-statistics .session-details-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 auto;
}
.Admin-dashboard-page .this-month-sessions-statistics .session-details-group .session-details {
  width: 20%;
  background-color: #fff;
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  height: 5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.Admin-dashboard-page .this-month-sessions-statistics .session-details-group .session-details-title {
  font-size: 1.2em;
  letter-spacing: 0.2px;
  cursor: pointer;
  color: #7c8aa2;
  font-weight: bold;
}
.Admin-dashboard-page .this-month-sessions-statistics .session-details-group .session-details-count {
  font-weight: bold;
  color: #7c8aa2;
  color: #1fc0da;
  cursor: pointer;
}
.Admin-dashboard-page .this-month-sessions-statistics .session-details-group .session-details:not(:last-child) {
  margin-right: 2rem;
}
.Admin-dashboard-page section {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.01);
  border: solid 1px rgba(124, 138, 162, 0.2);
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 2px;
}
.Admin-dashboard-page section .section-title {
  font-size: 1.2em;
  color: #4a4a4a;
  letter-spacing: 0.2px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
}

section.navigate-section {
  height: 100%;
}
section.navigate-section .ui.list .item {
  letter-spacing: 0.2px;
  transition: 0.2s;
}
section.navigate-section .ui.list .item a {
  padding: 0.84rem 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #7c8aa2;
}
section.navigate-section .ui.list .item a span {
  width: 90%;
}
section.navigate-section .ui.list .item img {
  width: 20px;
  margin-left: auto;
}
section.navigate-section .ui.list .item:hover {
  background: #1fc0da;
}
section.navigate-section .ui.list .item:hover a {
  color: #fff;
}

section.next-task-section,
section.action-required-section {
  background-color: #2b2b2b;
  height: 550px;
}
section.next-task-section .section-title,
section.action-required-section .section-title {
  color: #fff;
}
section.next-task-section .ant-spin,
section.action-required-section .ant-spin {
  width: 100%;
  height: 90%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
section.next-task-section .accordion.ui,
section.action-required-section .accordion.ui {
  height: 470px;
  overflow: hidden auto;
}
section.next-task-section .accordion.ui::-webkit-scrollbar,
section.action-required-section .accordion.ui::-webkit-scrollbar {
  width: 6px;
}
section.next-task-section .accordion.ui .title,
section.action-required-section .accordion.ui .title {
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  font-size: 1.05em;
  letter-spacing: 0.2px;
  padding: 1rem 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
section.next-task-section .accordion.ui .title.active,
section.action-required-section .accordion.ui .title.active {
  padding-bottom: 0.5em;
}
section.next-task-section .accordion.ui .title > a,
section.action-required-section .accordion.ui .title > a {
  font-size: 0.9em;
  font-weight: normal;
  color: #1fc0da;
  margin-top: 1px;
  padding-right: 10px;
}
section.next-task-section .accordion.ui .title > a:hover,
section.action-required-section .accordion.ui .title > a:hover {
  color: rgba(31, 192, 218, 0.7);
}
section.next-task-section .accordion.ui .content,
section.action-required-section .accordion.ui .content {
  color: rgba(255, 255, 255, 0.6);
  padding-left: 22px !important;
  padding-top: 0 !important;
  letter-spacing: 0.2px;
}
section.next-task-section .accordion.ui .content.active,
section.action-required-section .accordion.ui .content.active {
  padding-bottom: 0.5em !important;
}
section.next-task-section .accordion.ui .content .content-item,
section.action-required-section .accordion.ui .content .content-item {
  padding: 5px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
section.next-task-section .accordion.ui .content .content-item span:first-child,
section.action-required-section .accordion.ui .content .content-item span:first-child {
  width: 70%;
}
section.next-task-section .accordion.ui .content .content-item span:last-child,
section.action-required-section .accordion.ui .content .content-item span:last-child {
  font-weight: bold;
  padding-right: 10px;
}
section.next-task-section .accordion.ui .content .perfomance-item,
section.action-required-section .accordion.ui .content .perfomance-item {
  padding: 10px 0;
  display: -ms-flexbox;
  display: flex;
}
section.next-task-section .accordion.ui .content .perfomance-item span,
section.action-required-section .accordion.ui .content .perfomance-item span {
  width: 70%;
}
section.next-task-section .accordion.ui .content .perfomance-item .rank,
section.action-required-section .accordion.ui .content .perfomance-item .rank {
  width: 10%;
  text-align: center;
  font-weight: bold;
}
section.next-task-section .accordion.ui .content .perfomance-item .count,
section.action-required-section .accordion.ui .content .perfomance-item .count {
  width: 20%;
  text-align: center;
  font-weight: bold;
}

.section-empty-message {
  height: 90%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.perfomance-section {
  background-color: #2b2b2b;
  min-height: 550px;
}
.perfomance-section .top-rank-section {
  background-image: linear-gradient(315deg, #3fa4bd 0%, #25828b 74%);
}
.perfomance-section .top-rank-section .top-rank {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 10px;
}
.perfomance-section .top-rank-section .top-rank .ui.card,
.perfomance-section .top-rank-section .top-rank .ui.card > .card {
  border: none;
  background: none;
  border-radius: 0;
  box-shadow: none;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: 200px;
}
.perfomance-section .top-rank-section .top-rank .ui.card .description,
.perfomance-section .top-rank-section .top-rank .ui.card .header,
.perfomance-section .top-rank-section .top-rank .ui.card > .card .description,
.perfomance-section .top-rank-section .top-rank .ui.card > .card .header {
  letter-spacing: 0.2px;
  font-size: 16px !important;
  width: 100px;
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.perfomance-section .top-rank-section .top-rank .ui.card .header,
.perfomance-section .top-rank-section .top-rank .ui.card > .card .header {
  margin-top: 20px !important;
}
.perfomance-section .top-rank-section .top-rank .ui.card .rank-2,
.perfomance-section .top-rank-section .top-rank .ui.card > .card .rank-2 {
  background-color: red;
  bottom: 0;
}
.perfomance-section .top-rank-section .top-rank .ui.card .rank-1,
.perfomance-section .top-rank-section .top-rank .ui.card > .card .rank-1 {
  bottom: 0;
  background-color: red;
}
.perfomance-section .top-rank-section .top-rank .ui.card .rank-3,
.perfomance-section .top-rank-section .top-rank .ui.card > .card .rank-3 {
  background-color: red;
  bottom: 0;
}
.perfomance-section .top-rank-section .top-rank .ui.card > .image,
.perfomance-section .top-rank-section .top-rank .ui.cards > .card > .image {
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 50% !important;
}
.perfomance-section .section-title {
  color: #fff;
  min-height: 80px;
  padding: 25px 0 0 25px;
}
.perfomance-section .ui.pointing.secondary.menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-bottom: 2px solid white;
  text-align: center !important;
}
.perfomance-section .ui.pointing.secondary.menu .item {
  -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
  max-width: 33.333333%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
}
.perfomance-section .ui.pointing.secondary.menu .active {
  color: #1b1c1d !important;
}
.perfomance-section .ant-spin {
  width: 100%;
  height: 90%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.perfomance-section .accordion.ui {
  overflow: hidden auto;
}
.perfomance-section .accordion.ui::-webkit-scrollbar {
  width: 6px;
}
.perfomance-section .accordion.ui .title {
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  font-size: 1.05em;
  letter-spacing: 0.2px;
  padding: 1rem 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.perfomance-section .accordion.ui .title.active {
  padding-bottom: 0.5em;
}
.perfomance-section .accordion.ui .title > a {
  font-size: 0.9em;
  font-weight: normal;
  color: #1fc0da;
  margin-top: 1px;
  padding-right: 10px;
}
.perfomance-section .accordion.ui .title > a:hover {
  color: rgba(31, 192, 218, 0.7);
}
.perfomance-section .accordion.ui .content {
  color: rgba(255, 255, 255, 0.6);
  padding-top: 0 !important;
  letter-spacing: 0.2px;
}
.perfomance-section .accordion.ui .content .perfomance-item {
  padding: 10px 0;
  display: -ms-flexbox;
  display: flex;
  height: 75px;
  -ms-flex-align: center;
      align-items: center;
}
.perfomance-section .accordion.ui .content .perfomance-item span {
  width: 70%;
}
.perfomance-section .accordion.ui .content .perfomance-item .rank {
  width: 10%;
  text-align: center;
  font-weight: bold;
}
.perfomance-section .accordion.ui .content .perfomance-item .count {
  width: 20%;
  text-align: center;
  font-weight: bold;
}
.perfomance-section .accordion.ui .content .perfomance-item .atavar {
  height: 36px;
  width: 36px;
  margin: 0 10px 0 5px;
}
.Table-loading {
  position: relative;
}
.Table-loading > .ui.loader {
  display: block;
}
.table-case-correspondence {
  overflow: auto;
  width: 60vw;
  height: 35rem;
}
.table-case-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-case-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-case-correspondence .cell-correspondence {
  width: 20em;
}

.drag-drop-file {
  text-align: center;
}
.drag-drop-file .drag-drop-file-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #25828b;
}

.add-file-container {
  min-height: 130px;
  position: relative;
}
.add-file-container .add-file-container-text {
  text-align: center;
}
.add-file-container .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.add-file-container .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.add-file-container .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.add-file-container .Upload-File .Actions > i {
  cursor: pointer;
}
.add-file-container .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.add-file-container .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}

.special-checkbox:after {
  content: "" !important;
}

.add-files-urgent-report label {
  font-size: 14px !important;
}
.form-progress-fr {
  grid-column: 1/-1;
  margin-bottom: 3em;
  overflow: hidden;
  position: sticky;
  height: auto;
  top: 0px;
  background-color: #fff;
  z-index: 1;
}
.form-progress-fr .form-progress-step {
  grid-column: 1/-1;
  margin-bottom: 3em;
  overflow: hidden;
  position: relative;
  margin: 0 75px 0 75px;
}
.form-progress-fr .form-progress-step .ant-steps {
  transition: transform 0.2s;
}
.form-progress-fr .form-progress-step .ant-steps-item {
  -ms-flex-preferred-size: 300px;
      flex-basis: 300px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
}
.form-progress-fr .form-progress-step .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  white-space: break-spaces;
}
.form-progress-fr .form-progress-step .ant-steps-item-title {
  width: 203px !important;
}
.form-progress-fr .form-progress-step .ant-steps-item-description {
  max-width: 250px !important;
}
.form-progress-fr button {
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
}
.form-progress-fr button:first-child {
  left: 0;
  top: 50%;
}
.form-progress-fr button:last-child {
  right: 0;
  top: 50%;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.conversation-form .ant-radio-group + input {
  margin-top: 10px;
}
.fileDrop {
  background: #f5f5f5;
  border: 1px dashed #c2c2c2;
  border-radius: 3px;
  text-align: center;
  padding: 36px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.fileDrop:hover {
  background: rgb(194, 243, 194);
  border: 1px dashed #333;
}

.list-file {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 700;
}

.list-file-ul {
  padding-left: 20px;
  list-style: decimal;
  margin: 10px 0 0;
}

.upload-icon {
  margin-bottom: 20px !important;
  font-size: 2em !important;
  color: #1db4cd;
}

.custom-modal .ant-modal-body {
  max-height: 60em !important;
  overflow: auto;
}
.Ime-table .Table-toolbar .toolbar-left .ui.icon.input {
  width: 18rem !important;
  margin-right: 5px;
}
.table-case-correspondence {
  overflow: auto;
  width: 60vw;
  height: 35rem;
}
.table-case-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-case-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-case-correspondence .cell-correspondence {
  width: 20em;
}

.drag-drop-file {
  text-align: center;
}
.drag-drop-file .drag-drop-file-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #25828b;
}

.add-file-container {
  min-height: 130px;
  position: relative;
}
.add-file-container .add-file-container-text {
  text-align: center;
}
.add-file-container .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.add-file-container .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.add-file-container .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.add-file-container .Upload-File .Actions > i {
  cursor: pointer;
}
.add-file-container .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.add-file-container .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}

.add-files-urgent-report label {
  font-size: 14px !important;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.conversation-form .ant-radio-group + input {
  margin-top: 10px;
}
.form-progress-supp {
  grid-column: 1/-1;
  margin-bottom: 3em;
  overflow: hidden;
  position: sticky;
  height: auto;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.form-progress-supp .form-progress-step {
  grid-column: 1/-1;
  margin-bottom: 3em;
  overflow: hidden;
  position: relative;
  margin: 0 75px 0 75px;
}
.form-progress-supp .form-progress-step .ant-steps {
  transition: transform 0.2s;
}
.form-progress-supp .form-progress-step .ant-steps-item {
  -ms-flex-preferred-size: 300px;
      flex-basis: 300px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
}
.form-progress-supp .form-progress-step .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  white-space: break-spaces;
}
.form-progress-supp .form-progress-step .ant-steps-item-title {
  width: 203px !important;
}
.form-progress-supp .form-progress-step .ant-steps-item-description {
  max-width: 250px !important;
}
.form-progress-supp button {
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
}
.form-progress-supp button:first-child {
  left: 0;
  top: 50%;
}
.form-progress-supp button:last-child {
  right: 0;
  top: 50%;
}
.file-review-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.file-review-toolbar .Toolbar-input {
  width: 30em;
}
.file-review-toolbar .Toolbar-filter > div {
  max-width: 14em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-review-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.file-review-toolbar .Toolbar-action-group div.text,
.file-review-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.ui.button.button--overridden-style.blue.Toolbar-submit.clear {
  background-color: #db2828 !important;
  width: 80px;
  margin-left: 5px;
}
.doctor-report-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.doctor-report-toolbar-tab-name {
  width: calc(20% - 5px) !important;
}
.doctor-report-toolbar .Toolbar-input {
  width: 30em;
}
.doctor-report-toolbar .Toolbar-range-picker input,
.doctor-report-toolbar .Toolbar-range-picker {
  width: 25em;
}
.doctor-report-toolbar .label-onboarding-date,
.doctor-report-toolbar .label-assigned-date {
  min-width: calc(25rem + 5px);
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.doctor-report-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.doctor-report-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.doctor-report-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.doctor-report-toolbar .Toolbar-action-group div.text,
.doctor-report-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}

.export-specialist .field-checkbox .checkbox {
  min-width: 115px;
}
.doctor-remittance-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.doctor-remittance-toolbar .Field-Label-warning-date-range-picker {
  top: -15px;
  left: calc(48rem + 15px);
}
.doctor-remittance-toolbar .Toolbar-range-picker-error input {
  border: solid 1px red !important;
}
.doctor-remittance-toolbar .Toolbar-input {
  width: 30em;
}
.doctor-remittance-toolbar .Toolbar-range-picker {
  width: 20rem;
}
.doctor-remittance-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.doctor-remittance-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.doctor-remittance-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.doctor-remittance-toolbar .Toolbar-action-group div.text,
.doctor-remittance-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.doctor-remittance-toolbar .form-totalRemittanceAmout {
  margin-left: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
}
.doctor-remittance-toolbar .form-totalRemittanceAmout .disabled {
  opacity: 1;
}
.doctor-remittance-toolbar .totalRemittanceAmout {
  width: 200px;
  margin-left: 10px;
}
.doctor-remittance-toolbar .totalRemittanceAmout .search {
  display: none;
}
.doctor-name-text {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-review-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.file-review-toolbar .Toolbar-input {
  width: 30em;
}
.file-review-toolbar .Toolbar-range-picker {
  width: 20rem;
}
.file-review-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.file-review-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.file-review-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.file-review-toolbar .Toolbar-action-group div.text,
.file-review-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.table-case-correspondence {
  overflow: auto;
  width: 60vw;
  height: 35rem;
}
.table-case-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-case-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-case-correspondence .cell-correspondence {
  width: 20em;
}

.drag-drop-file {
  text-align: center;
}
.drag-drop-file .drag-drop-file-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #25828b;
}

.add-file-container {
  min-height: 130px;
  position: relative;
}
.add-file-container .add-file-container-text {
  text-align: center;
}
.add-file-container .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.add-file-container .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.add-file-container .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.add-file-container .Upload-File .Actions > i {
  cursor: pointer;
}
.add-file-container .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.add-file-container .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}

.clinical-note-container .clinical-note-request-date {
  width: 350px !important;
}

.ant-form .ant-form-item.flex-children .ant-form-item-control .ant-form-item-children {
  display: -ms-flexbox;
  display: flex;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.conversation-form .ant-radio-group + input {
  margin-top: 10px;
}
.fileDrop {
  background: #f5f5f5;
  border: 1px dashed #c2c2c2;
  border-radius: 3px;
  text-align: center;
  padding: 36px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.fileDrop:hover {
  background: rgb(194, 243, 194);
  border: 1px dashed #333;
}

.list-file {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 700;
}

.list-file-ul {
  padding-left: 20px;
  list-style: decimal;
  margin: 10px 0 0;
}

.upload-icon {
  margin-bottom: 20px !important;
  font-size: 2em !important;
  color: #1db4cd;
}

.custom-modal .ant-modal-body {
  max-height: 60em !important;
  overflow: auto;
}
.form-progress-cr {
  grid-column: 1/-1;
  margin-bottom: 3em;
  overflow: hidden;
  position: sticky;
  height: 75px;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.form-progress-cr .form-progress-step {
  grid-column: 1/-1;
  margin-bottom: 3em;
  overflow: hidden;
  position: relative;
  margin: 0 75px 0 75px;
}
.form-progress-cr .form-progress-step .ant-steps {
  transition: transform 0.2s;
}
.form-progress-cr .form-progress-step .ant-steps-item {
  -ms-flex-preferred-size: 300px;
      flex-basis: 300px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
}
.form-progress-cr .form-progress-step .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  white-space: break-spaces;
}
.form-progress-cr .form-progress-step .ant-steps-item-title {
  width: 203px !important;
}
.form-progress-cr .form-progress-step .ant-steps-item-description {
  max-width: 250px !important;
}
.form-progress-cr button {
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
}
.form-progress-cr button:first-child {
  left: 0;
  top: 60%;
}
.form-progress-cr button:last-child {
  right: 0;
  top: 60%;
}
.Universal-search-page .search-title {
  margin: 0 auto;
  width: 500px;
}
.Universal-search-page .search-title h1 {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
}
.Universal-search-page .inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.Universal-search-page .button-clear {
  margin-left: 10px;
  width: 120px;
}
.Universal-search-page .search-input input[type=text] {
  width: 500px !important;
}
.Universal-search-page .search-form {
  margin: 0 auto;
  width: 55rem;
}
.Universal-search-page .form-checkboxes {
  margin-top: 1.5rem;
}
.Universal-search-page .form-checkboxes .ui.checkbox:not(:first-child) {
  margin-left: 2rem;
}
.Universal-search-page .form-checkboxes .ui.checkbox > label {
  padding-left: 1.6rem;
}
.Universal-search-page .form-checkboxes .ui.checkbox input:checked ~ label:before {
  background-color: #1fc0da;
  border-color: #1fc0da;
}
.Universal-search-page .form-checkboxes .ui.checkbox input:checked ~ label:after {
  color: #fafafa;
}
.Universal-search-page .recent-search {
  margin-top: 1.5rem;
}
.Universal-search-page .recent-search h4 {
  color: #4a4a4a;
  margin-bottom: 0.7rem;
}
.Universal-search-page .recent-search .recent-search-emtpy-msg {
  font-size: 1.2rem;
  text-align: center;
  color: rgba(124, 138, 162, 0.5);
}
.Universal-search-page .recent-search .recent-search-list .ui.label {
  font-size: 1rem;
  padding: 0.7rem 1rem;
  border-radius: 16px;
  border: solid 1px rgba(37, 130, 139, 0.2);
  background-color: rgba(37, 130, 139, 0.05);
  color: #7c8aa2;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}
.Universal-search-page .accordion {
  margin-top: 2rem;
}
.Universal-search-page .accordion .title {
  font-size: 1.1rem !important;
}
.Universal-search-page.loading .accordion,
.Universal-search-page.loading button {
  pointer-events: none;
  opacity: 0.6;
}
.Doctor-card-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Doctor-card-list .Doctor-card-item {
  width: 18%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  text-align: center;
  padding: 3rem 2rem 2rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  transition: box-shadow 0.2s;
}
.Doctor-card-list .Doctor-card-item:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
}
.Doctor-card-list .Doctor-card-item img {
  width: 60%;
  border-radius: 100%;
}
.Doctor-card-list .Doctor-card-item .Doctor-info .doctor-name {
  margin-top: 1rem;
  margin-bottom: 10px;
  color: #1fc0da;
}
.Doctor-card-list .Doctor-card-item .Doctor-info .doctor-specialty {
  width: 90%;
  display: block;
  margin: auto;
  color: #a3a3a3;
  margin-bottom: 2.5rem;
}
.Doctor-card-list .Doctor-card-item .Doctor-info-contact-list {
  width: 60%;
  margin-block-start: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.Doctor-card-list .Doctor-card-item .Doctor-info-contact-list i.icon {
  margin: 0;
  cursor: pointer;
  font-size: 1.2rem;
  color: #1fc0da;
}
.Doctor-card-list .Doctor-card-item .Doctor-info-contact-list i.icon.disabled {
  pointer-events: none;
  opacity: 0.2 !important;
}
.Doctor-card-list .empty-message {
  width: 100%;
  padding: 2rem 0 !important;
  text-align: center !important;
  color: #ccc;
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}
.Documents-search-page {
  height: 100%;
}
.Documents-search-page div {
  padding: 0px;
  margin: 0px;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: white;
}
.Documents-search-page .search-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 10%;
  left: 50%;
}
.Documents-search-page .search-wrapper .input-holder {
  overflow: hidden;
  height: 70px;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  position: relative;
  width: 70px;
  transition: all 0.3s ease-in-out;
}
.Documents-search-page .search-wrapper.active .input-holder {
  border-radius: 50px;
  width: 450px;
  background: rgba(34, 215, 228, 0.5);
  transition: all 0.5s cubic-bezier(0, 0.105, 0.035, 1.57);
}
.Documents-search-page .search-wrapper .input-holder .search-input {
  width: 100%;
  height: 50px;
  padding: 0px 70px 0 20px;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: "Open Sans", Arial, Verdana;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #1fc0da;
  transform: translate(0, 60px);
  transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
  transition-delay: 0.3s;
}
.Documents-search-page .search-wrapper.active .input-holder .search-input {
  opacity: 1;
  transform: translate(0, 10px);
}
.Documents-search-page .search-wrapper .input-holder .search-icon {
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 6px;
  background: #1fc0da;
  padding: 0px;
  outline: none;
  position: relative;
  z-index: 2;
  float: right;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.Documents-search-page .search-wrapper.active .input-holder .search-icon {
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 30px;
}
.Documents-search-page .search-wrapper .input-holder .search-icon span {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transform: rotate(45deg);
  transition: all 0.4s cubic-bezier(0.65, -0.6, 0.24, 1.65);
}
.Documents-search-page .search-wrapper.active .input-holder .search-icon span {
  transform: rotate(-45deg);
}
.Documents-search-page .search-wrapper .input-holder .search-icon span::before,
.Documents-search-page .search-wrapper .input-holder .search-icon span::after {
  position: absolute;
  content: "";
}
.Documents-search-page .search-wrapper .input-holder .search-icon span::before {
  width: 4px;
  height: 11px;
  left: 9px;
  top: 25px;
  border-radius: 2px;
  background: white;
}
.Documents-search-page .search-wrapper .input-holder .search-icon span::after {
  width: 30px;
  height: 30px;
  left: -4px;
  top: -5px;
  border-radius: 16px;
  border: 4px solid white;
}
.Documents-search-page .search-wrapper .plus {
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transform: rotate(-180deg);
  transition: all 0.3s cubic-bezier(0.285, -0.45, 0.935, 0.11);
  transition-delay: 0.2s;
}
.Documents-search-page .search-wrapper.active .plus {
  right: -50px;
  transform: rotate(0deg);
  transition: all 0.6s cubic-bezier(0, 0.105, 0.035, 1.57);
  transition-delay: 0.5s;
}
.Documents-search-page .search-wrapper .plus::before,
.Documents-search-page .search-wrapper .plus::after {
  position: absolute;
  content: "";
  background: #1fc0da;
  border-radius: 2px;
}
.Documents-search-page .search-wrapper .plus::before {
  width: 5px;
  height: 25px;
  left: 10px;
  top: 0px;
}
.Documents-search-page .search-wrapper .plus::after {
  width: 25px;
  height: 5px;
  left: 0px;
  top: 10px;
}
.Documents-search-page .search-wrapper .plus.active::before {
  transition-delay: 0s;
  opacity: 0;
}
.Documents-search-page .search-wrapper .result-container {
  width: 200%;
  position: absolute;
  top: 70px;
  left: -50%;
  text-align: center;
  font-family: "Open Sans", Arial, Verdana;
  font-size: 14px;
  color: #B7B7B7;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}
.Documents-search-page .search-wrapper.active .result-container.advanced {
  opacity: 1;
  transition-delay: 0s;
}
@media screen and (max-width: 560px) {
  .Documents-search-page .search-wrapper.active .input-holder {
    width: 200px;
  }
}
.Documents-search-page .search-wrapper .btn-container {
  width: 200%;
  position: absolute;
  top: 90px;
  left: -50%;
  text-align: center;
  font-family: "Open Sans", Arial, Verdana;
  font-size: 14px;
  opacity: 1;
}
.Documents-search-page .search-wrapper.active .btn-container.advanced {
  top: 180px;
}
.Documents-search-page .search-title {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
}
.Documents-search-page .inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.Documents-search-page .button-clear {
  margin-left: 10px;
  width: 120px;
}
.Documents-search-page .search-input input[type=text] {
  width: 750px !important;
}
.Documents-search-page .search-form {
  margin: 0 auto;
  width: 55rem;
}
.Documents-search-page .form-checkboxes {
  margin-top: 1.5rem;
}
.Documents-search-page .form-checkboxes .ui.checkbox:not(:first-child) {
  margin-left: 2rem;
}
.Documents-search-page .form-checkboxes .ui.checkbox > label {
  padding-left: 1.6rem;
}
.Documents-search-page .form-checkboxes .ui.checkbox input:checked ~ label:before {
  background-color: #1fc0da;
  border-color: #1fc0da;
}
.Documents-search-page .form-checkboxes .ui.checkbox input:checked ~ label:after {
  color: #fafafa;
}
.Documents-search-page .recent-search {
  margin-top: 1.5rem;
}
.Documents-search-page .recent-search h4 {
  color: #4a4a4a;
  margin-bottom: 0.7rem;
}
.Documents-search-page .recent-search .recent-search-emtpy-msg {
  font-size: 1.2rem;
  text-align: center;
  color: rgba(124, 138, 162, 0.5);
}
.Documents-search-page .recent-search .recent-search-list .ui.label {
  font-size: 1rem;
  padding: 0.7rem 1rem;
  border-radius: 16px;
  border: solid 1px rgba(37, 130, 139, 0.2);
  background-color: rgba(37, 130, 139, 0.05);
  color: #7c8aa2;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}
.Documents-search-page .accordion {
  margin-top: 2rem;
}
.Documents-search-page .accordion .title {
  font-size: 1.1rem !important;
}
.Documents-search-page.loading .accordion,
.Documents-search-page.loading button {
  pointer-events: none;
  opacity: 0.6;
}
.Documents-search-page {
  height: 100%;
}
.Documents-search-page.loading .accordion,
.Documents-search-page.loading button {
  pointer-events: none;
  opacity: 0.6;
}
.Documents-search-page .chat-box {
  width: 1100px;
  height: 510px;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.Documents-search-page .chat-header {
  background-color: lightgray;
  padding: 10px;
  text-align: center;
}
.Documents-search-page .chat-header h3 {
  margin: 0;
  color: white;
  font-size: 20px;
}
.Documents-search-page .chat-messages {
  height: 400px;
  overflow: scroll;
  padding: 10px;
}
.Documents-search-page .chat-message-container {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.Documents-search-page .chat-message-content {
  background-color: lightgray;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.Documents-search-page .chat-message-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 5px;
  font-size: 12px;
  color: gray;
}
.Documents-search-page .user-message .chat-message-content {
  background-color: lightblue;
}
.Documents-search-page .chat-input-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 10px;
}
.Documents-search-page .chat-input-container input[type=text] {
  width: 70%;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}
.Documents-search-page .chat-input-container button[type=submit] {
  width: 20%;
  height: 40px;
  background-color: lightgray;
  border: 0;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}
.AC-search-page .search-title {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
}
.AC-search-page .inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.AC-search-page .button-clear {
  margin-left: 10px;
  width: 120px;
}
.AC-search-page .search-input {
  width: 800px !important;
}
.AC-search-page .search-form {
  margin: 0 auto;
  width: 65rem;
}
.AC-search-page .form-checkboxes {
  margin-top: 1.5rem;
  margin-left: -2%;
}
.AC-search-page .form-checkboxes .ui.checkbox {
  width: 195px;
}
.AC-search-page .form-checkboxes .ui.checkbox > label {
  padding-left: 1.6rem;
}
.AC-search-page .form-checkboxes .ui.checkbox input:checked ~ label:before {
  background-color: #1fc0da;
  border-color: #1fc0da;
}
.AC-search-page .form-checkboxes .ui.checkbox input:checked ~ label:after {
  color: #fafafa;
}
.AC-search-page .checkboxes-pending .ui.checkbox {
  width: 250px;
}
.AC-search-page .recent-search {
  margin-top: 1.5rem;
}
.AC-search-page .recent-search h4 {
  color: #4a4a4a;
  margin-bottom: 0.7rem;
}
.AC-search-page .recent-search .recent-search-emtpy-msg {
  font-size: 1.2rem;
  text-align: center;
  color: rgba(124, 138, 162, 0.5);
}
.AC-search-page .recent-search .recent-search-list .ui.label {
  font-size: 1rem;
  padding: 0.7rem 1rem;
  border-radius: 16px;
  border: solid 1px rgba(37, 130, 139, 0.2);
  background-color: rgba(37, 130, 139, 0.05);
  color: #7c8aa2;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}
.AC-search-page .accordion {
  margin-top: 2rem;
}
.AC-search-page .accordion .title {
  font-size: 1.1rem !important;
}
.AC-search-page.loading .accordion,
.AC-search-page.loading button {
  pointer-events: none;
  opacity: 0.6;
}
.AC-search-page .mg-l-20 {
  margin-left: 20px;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-correspondence {
  overflow-x: auto;
}
.table-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-correspondence .cell-correspondence {
  width: 20em;
}
.table-correspondence table {
  width: 140% !important;
}

.checkbox-IsTypist::after,
.checkbox-IsTypist label::after {
  content: "" !important;
}

.checkbox-IsTypist {
  font-size: 14px !important;
}

.posstion-IsTypist-label .ant-form-item-label > label::after {
  content: "" !important;
}

.invalid-label-eror-doctor-details {
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
}
.invalid-label-eror-doctor-details-error {
  color: white;
}

.input-doctor-details-edit-error input {
  border: solid 1px red !important;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.campaignAttachments .mb-1 {
  float: left;
  margin-top: 10px;
  margin-right: 50px;
}

.campaignAttachments .mb-1 .file, .campaignAttachments .mb-1 .FileName, .campaignAttachments .mb-1 .Actions {
  float: left;
  line-height: 28px;
}

.campaignAttachments .mb-1 .Actions {
  margin-left: 10px;
}

.campaignAttachments .Upload-File {
  margin-left: -6px;
}
.table-correspondence {
  overflow-x: auto;
}
.table-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-correspondence .cell-correspondence {
  width: 20em;
}
.table-correspondence table {
  width: 140% !important;
}

.checkbox-IsTypist::after,
.checkbox-IsTypist label::after {
  content: "" !important;
}

.posstion-IsTypist-label .ant-form-item-label > label::after {
  content: "" !important;
}

.invalid-label-eror-doctor-details {
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
}
.invalid-label-eror-doctor-details-error {
  color: white;
}

.input-doctor-details-edit-error input {
  border: solid 1px red !important;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.Wrap-checkbox,
.Wrap-date,
.Wrap-dropdown,
.Wrap-text {
  margin-top: 20px;
}
.Wrap-checkbox .Text,
.Wrap-date .Text,
.Wrap-dropdown .Text,
.Wrap-text .Text {
  width: 850px;
  height: 120px;
}

.Checkbox,
.Date,
.Text,
.Dropdown {
  margin-top: 10px;
}

.Checkbox-clients > :not(:first-child) {
  margin-left: 15px;
}

.Title {
  font-weight: 700;
}

.Type-clients,
.Direct-clients,
.Unsub-clients {
  display: -ms-flexbox;
  display: flex;
}

.Type-clients,
.Direct-clients,
.Suburb-clients,
.Postcode-clients,
.Suburb-clients,
.State-clients,
.Unsub-clients {
  margin-top: 30px;
}

.Checkbox-clients {
  margin-left: 15px !important;
  margin-top: 3px !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.Checkbox-clients > :not(:first-child),
.Date-picker-cms {
  margin-left: 15px !important;
}

.Title-export {
  text-align: center;
}

.Data-cms {
  display: -ms-flexbox;
  display: flex;
}

.Date-picker-cms {
  margin-top: -5px;
}

.Button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100px;
}

.Client-name {
  min-width: 18.75em;
}

.Full-address {
  min-width: 18.75em;
}

.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.file-review-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.file-review-toolbar .Toolbar-input {
  width: 30em;
}
.file-review-toolbar .Toolbar-range-picker {
  width: 20rem;
}
.file-review-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.file-review-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.file-review-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.file-review-toolbar .Toolbar-action-group div.text,
.file-review-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.Radio {
  margin-bottom: 1em;
  margin-left: 1em;
}

.form-action-note-imaging-service {
  padding: 1em;
  border-radius: 3px;
}
.form-action-note-imaging-service:not(:last-child) {
  margin-bottom: 20px;
}
.form-action-note-imaging-service .form-group {
  font-size: 1.03em;
  margin-top: 5px;
}
.form-action-note-imaging-service .form-group.Checkbox {
  margin-bottom: 1em;
}
.form-action-note-imaging-service .datetime-picker {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.form-action-note-imaging-service .datetime-picker > span {
  width: 100% !important;
}
.form-action-note-imaging-service .datetime-picker > span input {
  height: 2.875em;
  border-color: rgba(124, 138, 162, 0.3);
  color: #7c8aa2 !important;
}
.form-action-note-imaging-service .datetime-picker > span input:hover {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.form-action-note-imaging-service .datetime-picker > span input:focus {
  border-color: #1fc0da !important;
  box-shadow: 0 1px 3px 0 rgba(37, 130, 139, 0.2) !important;
}
.form-action-note-imaging-service .datetime-picker.date-picker span {
  width: 100% !important;
}
.title-table {
  font-size: x-large;
}

.login-history-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.login-history-toolbar .Toolbar-input-login input {
  width: 30em !important;
}
.login-history-toolbar .Toolbar-range-picker input {
  width: 20em;
}
.login-history-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.login-history-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.login-history-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.login-history-toolbar .Toolbar-action-group div.text,
.login-history-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}
.radio-box {
  font-weight: bold;
  font-size: large;
}

.Form-group {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.Toolbar-input {
  width: 25rem;
}

.Toolbar-range-picker {
  width: 20em;
}

.Toolbar-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 1em;
}

.Cell-no-data-msg {
  padding: 4rem !important;
  text-align: center !important;
  color: #ccc;
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}

.Header {
  opacity: 0.5;
  pointer-events: none;
}

.Field-range-picker {
  margin-top: 40px;
}

.Field-Label-warning-btn-search {
  top: -15px;
  left: calc(50rem + 8em);
}
.Field-Label-warning-btn-search-from-to-Active {
  top: -15px;
  left: calc(50rem + 21em);
}
.Field-Label-warning-date-range-picker {
  top: -15px;
  left: calc(50rem + 10px);
}

.Toolbar-range-picker-client-report-error input {
  border: solid 1px red !important;
}

.Modal-UploadProfilePicture {
  text-align: center;
}
.Modal-UploadProfilePicture .Button {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
}
.Modal-UploadProfilePicture .Button .title-client {
  margin-left: -20px;
}
.Cell-Subject {
  min-width: 20em;
}

.Cell-Body {
  width: 20em;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Text-area-edit {
  width: 82em;
  height: 10em;
}

.View-edit {
  width: 82em;
  height: 10em;
}

.Input-Subject {
  width: 82em;
  margin-top: 5px;
}

.Cell-logic {
  min-width: 20em;
}

.Cell-date {
  min-width: 7.575em;
}

.Label-subject {
  margin-bottom: 100em;
  font-weight: bold;
}

.modal-body {
  height: 100%;
}

.Select-module {
  height: 2.875em;
  width: 15.925em;
  margin-right: 5px;
}
.Select-module.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.Select-module.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -ms-user-select: none;
      user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.Input-Url {
  width: 20em;
}
.admin-my-details .Form-Container {
  margin: auto;
  width: 100%;
  margin-top: 10vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.admin-my-details .Form-Loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}
.admin-my-details .Form-field {
  margin-bottom: 0.8125em;
}
.admin-my-details .Field-Input {
  width: 30rem;
  height: 2.125em;
}
.admin-my-details .Filed-State {
  width: 30rem;
  height: 2.125em;
  font-size: 0.895em;
}
.admin-my-details .Field-BirthDate input {
  width: 30rem;
  height: 2.125em;
}
.admin-my-details .Form-label {
  margin-right: 0.8125em;
  margin-bottom: 0.355em;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1em;
  color: #000;
  height: 1.125em;
  font-size: 1em;
  width: 10em;
  margin-top: 0.255em;
  text-align: left;
  float: left;
}
.admin-my-details .Radio-box {
  margin-right: 0.8125em;
}
.admin-my-details .Field-Password-Checker {
  width: 30rem;
  height: 2.125em;
}
.admin-my-details .Field-text-password-short {
  color: lightgrey;
}
.admin-my-details .Field-text-password-weak {
  color: #ff4757;
}
.admin-my-details .Field-text-password-medium {
  color: orange;
}
.admin-my-details .Field-text-password-good {
  color: #3399ff;
}
.admin-my-details .Field-text-password-strong {
  color: #23ad5c;
}
.admin-my-details .indicator {
  height: 10px;
  margin: 10px 0;
  width: 30rem;
  display: block;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}
.admin-my-details .percent {
  position: relative;
  width: 30rem;
  height: 10px;
  border-radius: 10px;
  background: lightgrey;
  box-shadow: inset;
}
.admin-my-details .progress {
  position: absolute;
  border-radius: 10px;
  background: #fff;
  box-shadow: inset 0 0 2px #000;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  animation: animate 1s ease-in-out;
}
@keyframes animate {
  from {
    width: 0;
  }
}
.admin-my-details .Form-field-checkbox {
  margin-right: 1.525em;
  margin-bottom: 0.355em;
  font-size: 1em;
  width: 8.5em;
  height: 7rem;
  margin-top: 0.255em;
  text-align: left;
  float: left;
}
.admin-my-details .List-password-strength {
  width: 45rem;
  height: 7rem;
}
.admin-my-details .List-password-strength label {
  position: relative;
  display: block;
  font-size: 1em;
  color: #ff4757;
}
.admin-my-details .List-password-strength .checked-icon {
  position: absolute;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #ff4757;
}
.admin-my-details .List-password-strength input[type=checkbox] {
  -webkit-appearance: none;
}
.admin-my-details .List-password-strength input[type=checkbox]:checked ~ .checked-icon {
  bottom: 10px;
  border-top: none;
  border-right: none;
  height: 8px;
  width: 17px;
  transform: rotate(-45deg);
  border-color: #23ad5c;
}
.admin-my-details .List-password-strength span {
  position: relative;
  left: 25px;
  transition: 0.5s;
}
.admin-my-details .List-password-strength span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  transform: translateY(-50%) scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}
.admin-my-details .List-password-strength input[type=checkbox]:checked ~ span:before {
  transform: translateY(-50%) scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}
.admin-my-details .List-password-strength input[type=checkbox]:checked ~ span {
  color: #23ad5c;
}
.table-correspondence {
  overflow-x: auto;
}
.table-correspondence .cell-correspondence-content {
  width: 20em;
}
.table-correspondence .cell-correspondence-content-body {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-correspondence .cell-correspondence {
  width: 20em;
}
.table-correspondence table {
  width: 140% !important;
}

.checkbox-IsTypist::after,
.checkbox-IsTypist label::after {
  content: "" !important;
}

.posstion-IsTypist-label .ant-form-item-label > label::after {
  content: "" !important;
}

.invalid-label-eror-doctor-details {
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
}
.invalid-label-eror-doctor-details-error {
  color: white;
}

.input-doctor-details-edit-error input {
  border: solid 1px red !important;
}

.page-container {
  padding: 1em;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 0 4em;
}

.loading-spin {
  width: 100%;
  height: 70vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.form-container .form-title {
  letter-spacing: 0.2px;
  margin-bottom: 1.5em;
  color: #4a4a4a;
}
.form-container .form-subtitle {
  margin-top: 0;
  margin-bottom: 1em;
  color: #525252;
}
.form-container .fluid-field {
  grid-column: 1/-1;
}
.form-container .ant-divider.fluid-field {
  margin: 1em 0 2em 0;
}
.form-container > form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 2em), 1fr));
  grid-gap: 0.3em 2em;
}
.form-container .ant-col.ant-form-item-label {
  line-height: 1.2;
  margin-bottom: 6px;
}
.form-container .ant-col.ant-form-item-label label {
  font-size: 1.1em;
}
.form-container .ant-form-item-control {
  line-height: 1;
}
.form-container .upload-box {
  height: 7em;
  position: relative;
}
.form-container .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.form-container .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.form-container .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.form-container .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.form-container .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.form-container .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.form-container .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
}
.form-container .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.form-container .upload-box .file-actions i.download {
  margin-right: 0.8em;
}

.form-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding-bottom: 1em;
  grid-column: 1/-1;
}
.form-footer .ant-divider {
  margin: 2.5em 0;
}
.form-footer .ui.primary.button {
  background: #1fc0da;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.form-footer .ui.primary.button:hover {
  background-color: #1db4cd !important;
}

.step-container .step {
  letter-spacing: 0.2px;
  padding: 1.2em 2em;
  text-align: left;
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  line-height: 1.3em;
}
.step-container .step:hover {
  color: #fff !important;
  border: solid 1px #1aaec5;
  background: #1aaec5;
}
.step-container .step:not(:last-child) {
  margin-bottom: 1em;
}
.step-container .active {
  background: #1fc0da !important;
  color: #fff !important;
}

.check-duplicate,
.field-actions {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0.9em;
  line-height: 1;
  color: #1890ff;
  margin-left: 5px;
  cursor: pointer;
  letter-spacing: 0.2px;
}
.check-duplicate:hover,
.field-actions:hover {
  color: #40a9ff;
}
.check-duplicate:disabled,
.field-actions:disabled {
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.clinic-calendar,
.btn-action {
  width: fit-content;
  padding: 0;
  background: transparent;
  border: none;
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}
.clinic-calendar:hover,
.btn-action:hover {
  color: #40a9ff;
}
.clinic-calendar:disabled,
.btn-action:disabled {
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.room-list > p {
  margin-bottom: 7px;
}
.room-list .ant-tag {
  font-size: 1em;
  padding: 0.5em 1em;
  height: fit-content;
}
.room-list .tag-input {
  width: 145px;
  height: 37px !important;
  margin: 5px 10px 5px 0;
}
.room-list .tag-item {
  margin: 5px 10px 5px 0;
}
.room-list .add-new-room {
  width: 145px;
  height: 37px;
  background: #fff;
  margin: 5px 10px 5px 0;
}

.registered-specialists-container.disabled table,
.registered-specialists-container.disabled .registered-specialists-searchbox {
  opacity: 0.6;
  pointer-events: none;
}
.registered-specialists-container .registered-specialists-searchbox {
  display: -ms-flexbox;
  display: flex;
}
.registered-specialists-container .registered-specialists-searchbox .ant-input-search {
  width: 35%;
  margin-right: 0.5em;
}
.registered-specialists-container .registered-specialists-searchbox .ant-select {
  width: 23%;
  margin-right: 0.5em;
}
.registered-specialists-container .registered-specialists-searchbox button.primary {
  background: #1fc0da;
}
.registered-specialists-container .registered-specialists-searchbox button.primary:hover {
  background-color: #1db4cd !important;
}
.registered-specialists-container .registered-specialists-searchbox button.primary:focus {
  background: #1fc0da;
}
.registered-specialists-container .registered-specialists-searchbox .label-tag {
  margin: 0 7px 7px 0 !important;
  border-radius: 4px !important;
  border: solid 1px rgba(37, 130, 139, 0.2) !important;
  background-color: rgba(37, 130, 139, 0.1) !important;
}
.registered-specialists-container table {
  margin-bottom: 0.5em !important;
}
.registered-specialists-container table thead tr th:first-child,
.registered-specialists-container table tbody tr td:first-child {
  width: 4em;
  text-align: center;
}
.registered-specialists-container .doctor-info {
  width: 170px;
}
.registered-specialists-container .doctor-info .avatar {
  width: 50px;
  height: 50px;
  background-size: cover !important;
  margin: auto auto 7px auto;
  border-radius: 4px;
}
.registered-specialists-container .doctor-info .doctor-name {
  text-align: center;
}

.checkbox-field {
  margin-bottom: 1.5em;
}
.checkbox-field .note {
  display: block;
  padding: 0;
  margin-top: 5px;
  color: rgb(145, 145, 145);
  font-size: 1em;
}

.action-group button {
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}
.Form-Container-DR {
  width: 100%;
  height: 100vh;
}
.Form-Container-DR-View {
  margin-bottom: 5px;
}
.Form-Container-DR-Profile-img {
  border-radius: 50%;
  width: 180px;
  height: 180px;
}
.Form-Container-DR-Consultants {
  width: 200px;
  height: 20px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #25828b;
}
.Form-Container-DR-Name {
  width: 262px;
  height: 38px;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 1px;
  color: #4a4a4a;
}
.Form-Container-DR-Checkbox-avaiable {
  width: 262px;
  height: 38px;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 1px;
  color: #4a4a4a;
}
.Form-Container-DR-Label {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #4a4a4a !important;
}
.Form-Container-DR-Text {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2 !important;
  letter-spacing: 0.34px !important;
  color: #7c8aa2 !important;
}
.Form-Container-DR-Label-Preferences {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #2b2b2b !important;
}
.Form-Container-DR .ui.pointing.secondary.menu {
  overflow-y: hidden;
  padding-bottom: 2px;
}

.Form-Loading-DR {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.Form-Group-label {
  font-family: Lato;
  font-size: 30px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #4a4a4a !important;
  margin-top: 1rem !important;
}

.Form-Field-label {
  opacity: 0.86;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #4a4a4a !important;
}

.Segment-View {
  margin-top: 10px;
}

.Field-option {
  border-radius: 4px;
  font-size: 14px !important;
  border: solid 1px rgba(37, 130, 139, 0.2);
  background-color: rgba(37, 130, 139, 0.1);
  margin-top: 5px !important;
}

.Label-item {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: black !important;
}

.Form-Field-Link {
  margin-left: 1em;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.34px;
}

.Form-Field-Text {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.34px;
  color: #7c8aa2 !important;
}
.Form-Field-Text-content {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Form-Field-Text-subject {
  width: 20em;
}

.Form-field-new-ui-checkbox {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  margin-right: 22px !important;
  margin-top: 5px;
}

.Form-field-radio {
  height: 19px;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  margin-right: 2rem !important;
  margin-top: 5px;
}

.Preferences-img {
  width: 50px;
  height: 49.6px;
  border-radius: 50%;
}

.Field-preferences {
  margin-top: 22px;
}

.Field-preferences-label {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #25828b !important;
}

.Field-preferences-text {
  font-family: Lato;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7c8aa2 !important;
}

.Field-preferences-Date {
  font-family: Lato;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(74, 74, 74, 0.6) !important;
}

.Field-Shown-to-CM-and-staf {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(74, 74, 74, 0.6);
}

.Field-Shown-to-CM-and-staf-icon {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #7a7a7a !important;
}

.Field-document-content {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #25828b;
}

.Field-document-content-no-file {
  opacity: 0.3;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #7a7a7a !important;
}

.Field-document-action {
  font-family: Material-Design-Iconic-Font;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.43px;
  color: #4a4a4a !important;
  font-size: 18px !important;
}
.Field-document-action-delete {
  color: #c4737d !important;
  font-size: 18px !important;
}

.Medical-centre-card {
  width: 262px !important;
  height: 350px !important;
}

.Medical-centre-Image {
  width: 100%;
  height: 240px;
  background: url(/Content/build/static/media/placeholder.bb03e57a.png);
  background-size: auto;
}

.Medical-centre-Name {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #25828b !important;
  margin-top: 20px !important;
}

.Medical-centre-Address {
  font-family: Lato;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a !important;
}

.Medical-centre-Image-icon {
  opacity: 0.5;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  float: right;
  padding-top: 10px;
}

.Medical-centre-box-icon {
  text-align: center;
  margin-top: 10px;
}

.Medical-centre-Icon {
  font-size: 22px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.64 !important;
  letter-spacing: normal !important;
  color: #25828b !important;
  margin: 10px !important;
  cursor: pointer;
}
.Medical-centre-Icon:nth-child(2) {
  font-size: 24px !important;
}
.Medical-centre-Icon:nth-child(3) {
  font-size: 24px !important;
}
.Medical-centre-Icon:nth-child(1) {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.Medical-centre-Add-New {
  width: 262px !important;
  height: 350px !important;
  border: solid 2px #25828b !important;
  background-color: rgba(37, 130, 139, 0.05) !important;
  border-style: dashed !important;
  text-align: center !important;
  cursor: pointer !important;
  -ms-user-select: none;
  user-select: none;
}

.Medical-centre-content {
  margin: auto;
}

.Medical-centre-Add-New-icon {
  width: 37px !important;
  height: 50px !important;
  font-size: 30px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.64px !important;
  color: #25828b !important;
  text-decoration: none !important;
}

.Medical-centre-Add-New-text {
  width: 91px !important;
  height: 17px !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  color: #25828b !important;
}

.Form-Field-table {
  margin-top: 2rem;
  overflow-x: auto;
  overflow-y: auto;
}

.btn-text-show {
  float: right !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
  color: rgba(122, 122, 122, 0.51) !important;
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
}

.popup-clinic {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.popup-clinic-input {
  width: 360px !important;
  height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(124, 138, 162, 0.5) !important;
  background-color: #ffffff !important;
  opacity: 0.7 !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.38 !important;
  letter-spacing: normal !important;
  color: #7c8aa2 !important;
}
.popup-clinic-dropdown {
  width: 254px !important;
  height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(124, 138, 162, 0.5) !important;
  background-color: #ffffff !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.88 !important;
  letter-spacing: normal !important;
  color: #4a4a4a !important;
}
.popup-clinic-header {
  margin-bottom: 29px;
}
.popup-clinic-header-text {
  font-family: Lato !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.9 !important;
  letter-spacing: 0.67px !important;
  color: #4a4a4a !important;
}
.popup-clinic-header-icon {
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.9 !important;
  letter-spacing: 1px !important;
  color: #4a4a4a !important;
  float: right;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.Modal-Form-Field-table {
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: auto;
}

.Tab-label {
  font-size: 18px;
}

.Form-table-DRDetail-cell-label {
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.88 !important;
  letter-spacing: normal !important;
  color: #4a4a4a !important;
}

.Form-table-DRDetail-cell-img {
  width: 30px !important;
  height: 30px !important;
}

.Form-table-DRDetail-cell-id {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  color: #25828b !important;
  cursor: pointer;
}

.Form-table-DRDetail-cell-name,
.Form-table-DRDetail-cell-address {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  color: #7c8aa2 !important;
}

.Form-table-DRDetail-cell-icon {
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  text-align: right !important;
  color: #c4737d !important;
  cursor: pointer;
}

.Form-table-DRDetail-cell-add {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  text-align: right !important;
  color: #25828b !important;
  cursor: pointer;
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
}

.DoctorDetail .Avatar {
  width: 180px;
  height: 180px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px dashed #25828b;
  border-radius: 50%;
}
.DoctorDetail .Avatar img {
  border-radius: 50%;
}
.DoctorDetail .Avatar-Actions {
  margin-top: 10px;
}
.DoctorDetail .Avatar.Upload {
  cursor: pointer;
  background-color: rgba(37, 130, 139, 0.05);
}
.DoctorDetail .Avatar.Upload div {
  margin: auto;
  text-align: center;
  color: #25828b;
}
.DoctorDetail .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.DoctorDetail .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.DoctorDetail .Upload-File .Actions > i {
  cursor: pointer;
}
.DoctorDetail .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.DoctorDetail .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}
.DoctorDetail .PageFooter {
  position: sticky;
  bottom: 0;
  background-color: white;
  margin-top: 1em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.Modal-Conversation .ant-col.ant-form-item-label {
  line-height: 2;
}
.Modal-Conversation .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.Modal-Conversation .ant-form-item-control {
  line-height: 1;
}

.invalid-label-eror-doctor-details {
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
}
.invalid-label-eror-doctor-details-error {
  color: white;
}

.input-doctor-details-edit-error input {
  border: solid 1px red !important;
}

.Modal-UploadProfilePicture {
  text-align: center;
}
.Modal-UploadProfilePicture .Button {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
}
.Modal-UploadProfilePicture .cropper-view-box, .Modal-UploadProfilePicture .cropper-crop-box {
  box-shadow: 0 0 0 1px #39f;
  border-radius: 50%;
  outline: 0;
}
.Modal-UploadProfilePicture canvas {
  display: none;
}

.search-rate .ant-select-search__field {
  min-width: 200px;
}
.btn-primary,
.btn-secondary,
.btn-dark {
  height: 46px;
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  padding: 10px 0 0 0;
  text-align: center;
  color: #ffffff !important;
}

.btn-primary {
  background-color: #1fc0da !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: #25828b !important;
  border: solid 1px rgba(37, 130, 139, 0.5) !important;
}

.btn-dark {
  background-color: #4a4a4a !important;
}
.Form-Container-DR {
  width: 100%;
  height: 100vh;
}

.Form-Loading-DR {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}
.Form-Container {
  margin: auto;
  margin-top: 10vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.Form-Loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.Footer {
  margin: 1em 0;
  text-align: center;
}

.ui.vertical.menu {
  width: 16rem;
}

.setting-tabs {
  width: 100%;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
.clinic-details-container {
  padding: 1em;
  padding-bottom: 2em;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  text-decoration: underline;
}

.clinic-info {
  display: -ms-flexbox;
  display: flex;
}
.clinic-info .clinic-avatar {
  width: 160px;
  height: 160px;
  background-size: cover !important;
  margin-right: 30px;
}
.clinic-info .clinic-info-details h1 {
  letter-spacing: 1px;
  color: #4a4a4a;
  margin-bottom: 10px;
}
.clinic-info .clinic-info-details .cta-buttons {
  margin-top: 28px;
}
.clinic-info .clinic-info-details .cta-buttons .ui.button {
  padding-left: 2.5em;
  padding-right: 2.5em;
  font-size: 1;
  line-height: 1.38;
  margin-right: 7px;
  background-color: #1fc0da !important;
  box-shadow: 0 0 0 1px #1fc0da inset !important;
}
.clinic-info .clinic-info-details .cta-buttons .ui.button.basic {
  background-color: transparent !important;
  color: #1fc0da !important;
}
.clinic-info .clinic-info-details .cta-buttons .ui.button.basic:hover {
  background-color: #1fc0da !important;
  color: #fff !important;
}

.basic-info {
  margin-top: 28px;
}
.basic-info div {
  margin-bottom: 1em;
}
.basic-info div span:first-child {
  display: inline-block;
  width: 120px !important;
  color: #4a4a4a;
  opacity: 0.86;
  font-weight: 600;
}
.basic-info div span:last-child {
  color: #7c8aa2;
  letter-spacing: 0.34px;
  font-weight: normal !important;
}

.info-wrapper {
  padding: 1.5em;
  border-radius: 4px;
  background-color: #f7f8fa;
  margin-top: 2em;
  min-height: 8em;
}
.info-wrapper .title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 2em;
}
.info-wrapper .title h3 {
  color: #2b2b2b;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.34px;
}

.registered-specialists {
  margin-top: 3em;
}
.registered-specialists span {
  color: #1fc0da;
  letter-spacing: 0.34px;
}
.registered-specialists .specialist-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.8em 1em;
}
.registered-specialists .specialist-list .specialist {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.registered-specialists .specialist-list .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-size: cover !important;
}
.registered-specialists .specialist-list .specialist-info {
  display: block;
  margin-left: 1em;
}
.registered-specialists .specialist-list .specialist-info h4 {
  color: #1fc0da;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.1em;
}
.registered-specialists .specialist-list .specialist-info span {
  color: #a3a3a3;
  letter-spacing: 0.34px;
}

.info-description .content {
  color: #a3a3a3;
}

.info-addresses {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
}
.info-addresses .basic-info div span:first-child {
  width: 100px !important;
}
.info-addresses .basic-info div:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}
.conversation-form .ant-calendar-picker,
.conversation-form .ant-input-number {
  width: 100% !important;
}
.CM-report-toolbar .toolbar-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0 1.7em 0;
}
.CM-report-toolbar .CM-Toolbar-input {
  width: 30em;
}
.CM-report-toolbar .Toolbar-range-picker,
.CM-report-toolbar .Toolbar-range-picker input {
  width: 25em !important;
}
.CM-report-toolbar .Toolbar-filter > div.ui.selection.active.dropdown {
  border-color: #1fc0da !important;
}
.CM-report-toolbar .Toolbar-filter > div.disabled {
  color: #7c8aa2 !important;
  border: solid 1px rgba(124, 138, 162, 0.3) !important;
  background-color: rgba(124, 138, 162, 0.16) !important;
}
.CM-report-toolbar .Toolbar-action-group {
  background: #038951 !important;
  color: #fff !important;
}
.CM-report-toolbar .Toolbar-action-group div.text,
.CM-report-toolbar .Toolbar-action-group div.text:not(.default) {
  color: #fff !important;
}

.Modal-UploadProfilePicture {
  text-align: center;
}
.Modal-UploadProfilePicture .Button {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
}
.Modal-UploadProfilePicture .Button .title {
  margin-left: -30px;
}
.Modal-UploadProfilePicture .Button .title-download {
  margin-left: -20px;
}

.uploadcm-loading {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.Form-Feedback-Container {
  margin: 0 auto;
  width: 800px;
}
.Form-Feedback-Container h4 {
  font-size: 20px;
  line-height: 1.5;
}
.Form-Feedback-Container .text-area {
  min-height: 300px;
}
.Form-Container-DR {
  width: 100%;
  height: 100vh;
}

.Form-Loading-DR {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}
.Form-Container-DR {
  width: 100%;
  height: 100vh;
}
.Form-Container-DR-View {
  margin-bottom: 5px;
}
.Form-Container-DR-Profile-img {
  border-radius: 50%;
  width: 180px;
  height: 180px;
}
.Form-Container-DR-Consultants {
  width: 200px;
  height: 20px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #25828b;
}
.Form-Container-DR-Name {
  width: 262px;
  height: 38px;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 1px;
  color: #4a4a4a;
}
.Form-Container-DR-Checkbox-avaiable {
  width: 262px;
  height: 38px;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 1px;
  color: #4a4a4a;
}
.Form-Container-DR-Label {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #4a4a4a !important;
}
.Form-Container-DR-Text {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2 !important;
  letter-spacing: 0.34px !important;
  color: #7c8aa2 !important;
}
.Form-Container-DR-Label-Preferences {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #2b2b2b !important;
}

.Form-Loading-DR {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.Form-Group-label {
  font-family: Lato;
  font-size: 30px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #4a4a4a !important;
  margin-top: 1rem !important;
}

.Form-Field-label {
  opacity: 0.86;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #4a4a4a !important;
}

.Segment-View {
  margin-top: 10px;
}

.Field-option {
  border-radius: 4px;
  font-size: 14px !important;
  border: solid 1px rgba(37, 130, 139, 0.2);
  background-color: rgba(37, 130, 139, 0.1);
  margin-top: 5px !important;
}

.Label-item {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: black !important;
}

.Form-Field-Link {
  margin-left: 1em;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.34px;
}

.Form-Field-Text {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.34px;
  color: #7c8aa2 !important;
}
.Form-Field-Text-content {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Form-Field-Text-subject {
  width: 20em;
}

.Form-field-new-ui-checkbox {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  margin-right: 22px !important;
  margin-top: 5px;
}

.Form-field-radio {
  height: 19px;
  font-family: Lato;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  margin-right: 2rem !important;
  margin-top: 5px;
}

.Preferences-img {
  width: 50px;
  height: 49.6px;
  border-radius: 50%;
}

.Field-preferences {
  margin-top: 22px;
}

.Field-preferences-label {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #25828b !important;
}

.Field-preferences-text {
  font-family: Lato;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7c8aa2 !important;
}

.Field-preferences-Date {
  font-family: Lato;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(74, 74, 74, 0.6) !important;
}

.Field-Shown-to-CM-and-staf {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(74, 74, 74, 0.6);
}

.Field-Shown-to-CM-and-staf-icon {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #7a7a7a !important;
}

.Field-document-content {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #25828b;
}

.Field-document-content-no-file {
  opacity: 0.3;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #7a7a7a !important;
}

.Field-document-action {
  font-family: Material-Design-Iconic-Font;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.43px;
  color: #4a4a4a !important;
  font-size: 18px !important;
}
.Field-document-action-delete {
  color: #c4737d !important;
  font-size: 18px !important;
}

.Medical-centre-card {
  width: 262px !important;
  height: 350px !important;
}

.Medical-centre-Image {
  width: 100%;
  height: 240px;
  background: url(/Content/build/static/media/placeholder.bb03e57a.png);
  background-size: auto;
}

.Medical-centre-Name {
  font-family: Lato;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #25828b !important;
  margin-top: 20px !important;
}

.Medical-centre-Address {
  font-family: Lato;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a !important;
}

.Medical-centre-Image-icon {
  opacity: 0.5;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7c8aa2 !important;
  float: right;
  padding-top: 10px;
}

.Medical-centre-box-icon {
  text-align: center;
  margin-top: 10px;
}

.Medical-centre-Icon {
  font-size: 22px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.64 !important;
  letter-spacing: normal !important;
  color: #25828b !important;
  margin: 10px !important;
  cursor: pointer;
}
.Medical-centre-Icon:nth-child(2) {
  font-size: 24px !important;
}
.Medical-centre-Icon:nth-child(3) {
  font-size: 24px !important;
}
.Medical-centre-Icon:nth-child(1) {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.Medical-centre-Add-New {
  width: 262px !important;
  height: 350px !important;
  border: solid 2px #25828b !important;
  background-color: rgba(37, 130, 139, 0.05) !important;
  border-style: dashed !important;
  text-align: center !important;
  cursor: pointer !important;
  -ms-user-select: none;
  user-select: none;
}

.Medical-centre-content {
  margin: auto;
}

.Medical-centre-Add-New-icon {
  width: 37px !important;
  height: 50px !important;
  font-size: 30px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.33 !important;
  letter-spacing: 0.64px !important;
  color: #25828b !important;
  text-decoration: none !important;
}

.Medical-centre-Add-New-text {
  width: 91px !important;
  height: 17px !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  color: #25828b !important;
}

.Form-Field-table {
  margin-top: 2rem;
  overflow-x: auto;
  overflow-y: auto;
}

.btn-text-show {
  float: right !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;
  color: rgba(122, 122, 122, 0.51) !important;
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
}

.popup-clinic {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.popup-clinic-input {
  width: 360px !important;
  height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(124, 138, 162, 0.5) !important;
  background-color: #ffffff !important;
  opacity: 0.7 !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.38 !important;
  letter-spacing: normal !important;
  color: #7c8aa2 !important;
}
.popup-clinic-dropdown {
  width: 254px !important;
  height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(124, 138, 162, 0.5) !important;
  background-color: #ffffff !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.88 !important;
  letter-spacing: normal !important;
  color: #4a4a4a !important;
}
.popup-clinic-header {
  margin-bottom: 29px;
}
.popup-clinic-header-text {
  font-family: Lato !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.9 !important;
  letter-spacing: 0.67px !important;
  color: #4a4a4a !important;
}
.popup-clinic-header-icon {
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.9 !important;
  letter-spacing: 1px !important;
  color: #4a4a4a !important;
  float: right;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.Modal-Form-Field-table {
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: auto;
}

.Tab-label {
  font-size: 18px;
}

.Form-table-DRDetail-cell-label {
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.88 !important;
  letter-spacing: normal !important;
  color: #4a4a4a !important;
}

.Form-table-DRDetail-cell-img {
  width: 30px !important;
  height: 30px !important;
}

.Form-table-DRDetail-cell-id {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  color: #25828b !important;
  cursor: pointer;
}

.Form-table-DRDetail-cell-name,
.Form-table-DRDetail-cell-address {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  color: #7c8aa2 !important;
}

.Form-table-DRDetail-cell-icon {
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  text-align: right !important;
  color: #c4737d !important;
  cursor: pointer;
}

.Form-table-DRDetail-cell-add {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: 0.3px !important;
  text-align: right !important;
  color: #25828b !important;
  cursor: pointer;
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
}

.DoctorDetail .Avatar {
  width: 180px;
  height: 180px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px dashed #25828b;
  border-radius: 50%;
}
.DoctorDetail .Avatar img {
  border-radius: 50%;
}
.DoctorDetail .Avatar-Actions {
  margin-top: 10px;
}
.DoctorDetail .Avatar.Upload {
  cursor: pointer;
  background-color: rgba(37, 130, 139, 0.05);
}
.DoctorDetail .Avatar.Upload div {
  margin: auto;
  text-align: center;
  color: #25828b;
}
.DoctorDetail .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.DoctorDetail .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.DoctorDetail .Upload-File .Actions > i {
  cursor: pointer;
}
.DoctorDetail .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.DoctorDetail .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}
.DoctorDetail .PageFooter {
  position: sticky;
  bottom: 0;
  background-color: white;
  margin-top: 1em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.Modal-Conversation .ant-col.ant-form-item-label,
.Modal-ChangePassword .ant-col.ant-form-item-label {
  line-height: 2;
}
.Modal-Conversation .ant-row.ant-form-item:last-child,
.Modal-ChangePassword .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.Modal-Conversation .ant-form-item-control,
.Modal-ChangePassword .ant-form-item-control {
  line-height: 1;
}

.Modal-ChangePassword .List-password-strength {
  width: 30rem;
  height: 7rem;
  margin-bottom: -40px;
  margin-top: -20px;
}
.Modal-ChangePassword .List-password-strength label {
  position: relative;
  display: block;
  font-size: 1em;
  color: #ff4757;
}
.Modal-ChangePassword .List-password-strength .checked-icon {
  position: absolute;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #ff4757;
}
.Modal-ChangePassword .List-password-strength input[type=checkbox] {
  -webkit-appearance: none;
}
.Modal-ChangePassword .List-password-strength input[type=checkbox]:checked ~ .checked-icon {
  bottom: 10px;
  border-top: none;
  border-right: none;
  height: 8px;
  width: 17px;
  transform: rotate(-45deg);
  border-color: #23ad5c;
}
.Modal-ChangePassword .List-password-strength span {
  position: relative;
  left: 25px;
  transition: 0.5s;
}
.Modal-ChangePassword .List-password-strength span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  transform: translateY(-50%) scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}
.Modal-ChangePassword .List-password-strength input[type=checkbox]:checked ~ span:before {
  transform: translateY(-50%) scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}
.Modal-ChangePassword .List-password-strength input[type=checkbox]:checked ~ span {
  color: #23ad5c;
}

.invalid-label-eror-doctor-details {
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -2px;
  clear: both;
}
.invalid-label-eror-doctor-details-error {
  color: white;
}

.input-doctor-details-edit-error input {
  border: solid 1px red !important;
}

.Modal-UploadProfilePicture {
  text-align: center;
}
.Modal-UploadProfilePicture .Button {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
}
.Modal-UploadProfilePicture .cropper-view-box,
.Modal-UploadProfilePicture .cropper-crop-box {
  box-shadow: 0 0 0 1px #39f;
  border-radius: 50%;
  outline: 0;
}
.Modal-UploadProfilePicture canvas {
  display: none;
}

.Field-Password-Checker {
  width: 30rem;
  height: 2.125em;
}

.Field-text-password-short {
  color: lightgrey;
}
.Field-text-password-weak {
  color: #ff4757;
}
.Field-text-password-medium {
  color: orange;
}
.Field-text-password-good {
  color: #3399ff;
}
.Field-text-password-strong {
  color: #23ad5c;
}

.indicator {
  height: 10px;
  margin: 10px 0;
  width: 100%;
  display: block;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

.percent {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: lightgrey;
  box-shadow: inset;
}

.progress {
  position: absolute;
  border-radius: 10px;
  background: #fff;
  box-shadow: inset 0 0 2px #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: animate 1s ease-in-out;
}

@keyframes animate {
  from {
    width: 0;
  }
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}

.attach-contact-list {
  margin-bottom: -15px !important;
}

.campaign-erorr-text {
  color: red !important;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.ui.form.mail-search {
  width: 100%;
  padding: 0 10px;
}
.ui.form.mail-search .ui.button {
  margin-left: 5px;
}
.ui.form.mail-search .field.button-container {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 25px;
}
.ui.form.mail-search .Toolbar-range-picker {
  width: 100%;
}
.ui.items {
  margin: 0 1.5rem 0 0.5rem;
}
.ui.items .ui.divider {
  margin-left: 20px;
}
.ui.items .child-list {
  margin-left: 70px;
  margin-right: 0px;
}
.ui.items .item.selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.ui.items .item {
  padding-bottom: 0;
}
.ui.items .item .header {
  font-size: 1.1em !important;
}
.ui.items .item .meta {
  line-height: 1.5em;
}
.ui.items .item .header.negative {
  color: #9f3a38;
}
.ui.items .item .item-selection {
  -ms-flex-align: center;
      align-items: center;
  margin-right: 7px;
  display: -ms-flexbox;
  display: flex;
}
.ui.items .item .item-selection i.icon {
  height: 18px;
  cursor: pointer;
  margin-left: 0.25rem;
}
.ui.items .item .actions {
  vertical-align: middle;
  display: -ms-flexbox;
  display: flex;
}
.ui.items .item .actions .ui.button.dropdown {
  padding: 5px;
  -ms-flex-item-align: center;
      align-self: center;
}
.ui.items .item .actions .ui.button.dropdown i.icon {
  margin: 0;
}
.ui.items .item .actions .ui.button.dropdown .menu .ui.item.dropdown {
  text-align: right;
  width: 120px;
}
.ui.items .item .actions .ui.button.dropdown .menu .ui.item.dropdown .dropdown.icon {
  margin-top: 2px;
}
.ui.items.child-list .item {
  padding: 10px !important;
}
.ui.items.child-list .item .header-container {
  padding-top: 10px;
}
.ui.items.child-list .sub-message-header {
  font-size: 1em !important;
  overflow: hidden;
  max-height: 60px;
  text-overflow: ellipsis;
}

.ui.items > .item > .content {
  cursor: pointer;
  overflow-x: hidden;
}

.ui.items > .item > .image:not(.ui) {
  width: 16px;
  padding-top: 6px;
  opacity: 0.5;
  cursor: pointer;
}
.ui.items > .item > .image:not(.ui):hover {
  opacity: 1;
}

.ui.items > .item > .image + .content {
  padding-left: 0.5rem;
}

.mail-list.infinite-scroll {
  max-height: 650px;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 300px;
  background-color: white;
}

.action-bar {
  padding: 0 30px 10px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.action-bar .item-count {
  margin-right: 30px;
}
.action-bar .select-all-emails {
  margin-left: 7px;
  margin-right: 1em;
}

.ui.grid .row.no-padding-top {
  padding-top: 0;
}

.ui.grid .row.no-padding {
  padding: 0;
}
.mail-detail.infinite-scroll {
  max-height: 730px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px;
  width: 100%;
}
.mail-detail.infinite-scroll .ui.divider {
  width: 100%;
}
.mail-detail .ui.grid {
  width: 100%;
}
.mail-detail .save-button {
  height: 40px;
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.mail-detail .action-note-form {
  width: 100%;
}
.mail-detail .case-no {
  margin-right: 10px;
  font-weight: bold;
  color: #1fc0da !important;
  transition: 0.2s opacity;
}
.file-upload-container .ant-upload-list-item-name {
  width: auto;
  margin-right: 10px;
}
.file-upload-container .ant-upload-list-item-info > span {
  display: -ms-flexbox;
  display: flex;
}
.react-tag-input__input {
  border: none !important;
  width: auto !important;
}
.add-file-container {
  min-height: 130px;
  position: relative;
}
.add-file-container .Upload-File {
  padding: 0.5em;
  background-color: rgba(122, 122, 122, 0.08);
  border-radius: 0.28571429rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 76px;
}
.add-file-container .Upload-File .FileName {
  -ms-flex-positive: 2;
      flex-grow: 2;
  max-width: 84%;
}
.add-file-container .Upload-File .Actions > i {
  cursor: pointer;
}
.add-file-container .Upload-File .Button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center;
  height: 46px;
  font-size: 14px;
  line-height: 14px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 0.28571429rem;
  margin-right: 1em;
}
.add-file-container .Upload-File p {
  color: #7a7a7a;
  opacity: 0.8;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}

.ant-select-selection__rendered {
  margin-left: 0;
  margin-right: 0;
}

.ant-select-selection__rendered .ant-select-selection-selected-value {
  padding-left: 11px;
  padding-right: 11px;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
.form-add-edit-container {
  width: 100%;
  overflow-x: revert;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.form-add-edit-loading {
  display: -ms-flexbox;
  display: flex;
  height: 75vh;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 3rem;
}

.btn-secondary-close,
.btn-primary-submit {
  color: #ffffff !important;
}

.btn-secondary-close {
  background-color: #1fc0da !important;
}

.btn-primary-submit {
  background-color: #1fc0da !important;
}

.text-area-description {
  resize: none !important;
}
.report-container {
  margin-top: -50px;
}
.report-container .report-style-class iframe {
  height: calc(100vh - 30px) !important;
  border: none;
}
.conversation-form .ant-col.ant-form-item-label {
  line-height: 2;
}
.conversation-form .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.conversation-form .ant-form-item-control {
  line-height: 1;
}

.Body-string {
  display: block;
  white-space: nowrap;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-file-container-marketing-campaign {
  min-height: 130px;
  position: relative;
}
.add-file-container-marketing-campaign .add-file-container-text {
  text-align: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.add-file-container-marketing-campaign .add-file-container-btn-area .button-add-file {
  position: absolute;
  bottom: -15px;
  width: 70%;
}
.add-file-container-marketing-campaign .upload-box {
  height: 7em;
  position: relative;
}
.add-file-container-marketing-campaign .upload-box .upload-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .upload-file .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 3090;
  background: rgba(0, 0, 0, 0.04);
}
.add-file-container-marketing-campaign .upload-box .upload-file input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.add-file-container-marketing-campaign .upload-box .file-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 90%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner i {
  font-size: 3em;
  height: 100%;
}
.add-file-container-marketing-campaign .upload-box .file-info-inner .file-name {
  color: #25828b;
  margin-left: 0.5em;
  display: block;
  white-space: nowrap;
  width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-file-container-marketing-campaign .upload-box .file-actions {
  cursor: pointer;
  font-size: 1.3em;
}
.add-file-container-marketing-campaign .upload-box .file-actions i.download {
  margin-right: 0.8em;
}
.DashboardHeaderIcon {
  position: absolute;
  top: 0;
  width: 3rem;
  height: 3rem;
  font-size: 1.3rem;
  line-height: 3rem;
  overflow: hidden;
  text-align: center;
  color: #e6e6e6;
  cursor: pointer;
}
.DashboardHeaderIcon:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}
.DashboardHeaderIcon-Tooltip {
  position: fixed !important;
  top: 3.1rem;
  margin-top: 0 !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  white-space: nowrap;
}
.DashboardHeaderIcon-Tooltip:before {
  top: 1px !important;
  left: auto !important;
  right: 1em !important;
}
.IframeContainer {
  position: fixed;
  top: 3.75rem;
  bottom: 0;
  left: 22rem;
  right: 0;
  background-color: white;
}
.IframeContainer.sidebarAutohide {
  left: 0;
}

#cirrus-iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#cirrus-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
#cirrus-iframe.visibilityHidden iframe {
  visibility: hidden;
}
.DashboardContent {
  position: relative;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  margin-left: 22rem;
  padding-top: 3.75rem;
}
.DashboardContent.sidebarAutohide {
  margin-left: 0;
  border-left: 0;
}
.ContentItem {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  padding: 1rem;
  visibility: hidden;
  overflow: hidden;
}
.ContentItem.active {
  position: relative;
  bottom: auto;
  left: auto;
  height: calc(100vh - 3.75rem);
  visibility: visible;
  overflow: unset;
}
.DashboardHeader {
  position: fixed;
  top: 0;
  left: 22rem;
  right: 0;
  height: 3.75rem;
  min-width: calc(1150px - 22rem);
  z-index: 1;
}
.DashboardHeader.sidebarAutohide {
  left: 0;
  min-width: 1150px;
}
.ShowSidebarIcon {
  left: 0;
}
.Tabs {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #1fc0da;
  white-space: nowrap;
}
.Tabs:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.75rem;
  background-color: #ffffff;
  border-bottom: 1px dashed #595959;
  z-index: 101;
}
.Tabs-Outer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.Tabs-Outer.sidebarAutohide {
  left: 3rem;
}
.Tabs-Inner {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  right: 8rem;
}
.Tabs-DraggingBackdrop {
  position: fixed;
  top: -1rem;
  bottom: -1rem;
  left: -1rem;
  right: -1rem;
  cursor: pointer;
  z-index: 9999;
}
a.Tab {
  color: inherit !important;
  text-decoration: none !important;
  transition: none !important;
}
a.Tab.animation {
  transition: left 200ms ease-out !important;
}

.Tab {
  display: inline-block;
  position: relative;
  left: 0;
  width: 15rem;
  height: 2.75rem;
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
}
.Tab-Background {
  position: absolute;
  top: 0;
  left: 1rem;
  width: 14rem;
  height: 100%;
  box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  border-bottom: 0;
  background-color: #d9d9d9;
  transform: perspective(10.5rem) rotateX(30deg);
  transform-origin: 50% 0;
  pointer-events: none;
}
.Tab-Content {
  position: relative;
  height: 100%;
  padding: 0.8rem 1.5rem 0 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Tab-Content img {
  position: absolute;
  top: 0.8rem;
  left: 1.5rem;
  width: 1.3rem;
  height: 1.3rem;
  pointer-events: none;
}
.Tab-Close {
  position: absolute;
  top: 0.4rem;
  right: 0.2rem;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 0.1rem;
}
.Tab-Close svg {
  width: 100%;
  stroke: #4d4d4d;
}
.Tab-Close:hover {
  background-color: #e60000;
}
.Tab-Close:hover svg {
  stroke: #ffffff;
}
.Tab:hover .Tab-Background {
  background-color: #ebebeb;
}
.Tab.active {
  z-index: 100 !important;
}
.Tab.active .Tab-Background {
  background-color: #ffffff;
}
.Tab.active .Tab-Content {
  font-weight: 700;
}
.TabMore {
  width: 4.5rem;
  top: 0;
}
.TabMore .Tab-Background {
  width: 3.5rem;
  transform: perspective(3.3rem) rotateX(30deg);
}
.TabMore .Tab-Content {
  padding: 0.8rem 0.3rem 0 0.3rem;
  text-align: center;
  font-weight: 700;
  transform: translateX(5px);
}
.TabMore-Count {
  background-color: red;
  color: white;
  font-size: 0.8rem;
  padding: 0 3px;
  border-radius: 3px;
}
.TabMore-Dropdown {
  display: none;
  position: absolute;
  top: -0.5rem;
  right: 0;
  width: 20rem;
  background-color: white;
  box-shadow: 0 0 2px gray;
  border-radius: 3px;
  overflow: hidden;
}
.TabMore-Dropdown.active {
  display: block;
}
.TabMore-Dropdown .MenuItem {
  padding-left: 2.4rem;
  padding-right: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
}
.TabMore-Dropdown .MenuItem img {
  top: 0.7rem;
  left: 0.5rem;
}
.TabMore-Dropdown .MenuItem:hover {
  background-color: #f2f2f2;
}
.TabMore-Dropdown .Tab-Close {
  position: absolute;
  top: 0.8rem;
  right: 0.3rem;
}
.DashboardSidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22rem;
  z-index: 999;
}
.DashboardSidebar.autohide {
  visibility: hidden;
  left: -22rem;
}
.DashboardSidebar.autohide.visible {
  visibility: visible;
  left: 0;
  transition: left 300ms ease-out;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}
.DashboardSidebar__site-logo {
  background-color: #2d2d2d;
  padding: 0 0.8rem 3.5rem 0.8rem;
  margin-top: 3.75rem;
}

.SidebarAutohideBackdrop {
  display: none;
  position: fixed;
  top: -1rem;
  bottom: -1rem;
  left: -1rem;
  right: -1rem;
  background-color: rgba(0, 0, 0, 0.7);
}
.SidebarAutohideBackdrop.autohide {
  display: block;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-out;
}
.SidebarAutohideBackdrop.autohide.visible {
  pointer-events: auto;
  opacity: 1;
  transition: none;
}

.SidebarAnimationBackdrop {
  display: none;
  position: fixed;
  top: -1rem;
  bottom: -1rem;
  left: -1rem;
  right: -1rem;
}
.SidebarAnimationBackdrop.autohide {
  display: block;
  transition-property: visibility;
  transition-duration: 0s;
  transition-delay: 0.3s;
  visibility: visible;
  pointer-events: none;
}
.SidebarAnimationBackdrop.autohide.visible {
  visibility: hidden;
  pointer-events: auto;
}
.SidebarHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 22rem;
  height: 3.75rem;
  background-color: #2b2b2b;
}
.SidebarHeader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background-color: #2b2b2b;
}
.SidebarHeader:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: -0.1rem;
  left: 100%;
  width: 0.1rem;
  background-color: rgba(0, 0, 0, 0.07);
}
.SidebarHeader.autohide:after {
  display: none;
}
.HideSidebarIcon {
  left: 0;
}
.SidebarMenus {
  position: absolute;
  top: 7.35rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 0.8rem 0.5rem;
  padding-bottom: 100px;
  background-color: #2d2d2d;
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
}
.SidebarMenus .MenuGroup,
.SidebarMenus .MenuItem {
  text-transform: uppercase;
}
.SidebarMenus .MenuGroup:before,
.SidebarMenus .MenuItem:before {
  border-color: #4d4d4d;
}
.SidebarMenus .MenuGroup:hover,
.SidebarMenus .MenuItem:hover {
  background-color: #3d3d3d;
}
.SidebarMenus .MenuGroup:hover:before,
.SidebarMenus .MenuItem:hover:before {
  border-color: transparent;
}
.SidebarMenus .MenuGroup-Children .MenuItem {
  text-transform: none;
}

.labelMenu {
  cursor: default !important;
}
a.MenuItem {
  display: block;
  color: inherit !important;
  text-decoration: none !important;
  transition: none !important;
}

.MenuItem {
  margin-bottom: 0.2rem;
  padding: 0.6rem 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-radius: 3px;
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
}
.MenuItem:hover {
  background-color: #595959;
}
.MenuItem:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 14px;
  right: 0;
  border-bottom: 1px dashed #595959;
}
.MenuItem.active:before {
  display: none;
}
.MenuItem img {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 1.5rem;
  pointer-events: none;
  -ms-flex-item-align: center;
      align-self: center;
}
.MenuItem.active {
  color: white !important;
  background-color: #1fc0da !important;
  font-weight: 700;
}
.MenuItem.disabled {
  opacity: 0.4;
  pointer-events: none;
}

a.MenuItemParent {
  display: block;
  color: inherit !important;
  text-decoration: none !important;
  transition: none !important;
}

.MenuItemParent {
  margin-bottom: 0.2rem;
  padding: 0.6rem 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-radius: 3px;
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
}
.MenuItemParent:hover {
  background-color: #595959;
}
.MenuItemParent.active:before {
  display: none;
}
.MenuItemParent img {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 1.5rem;
  pointer-events: none;
  -ms-flex-item-align: center;
      align-self: center;
}
.MenuItemParent.active {
  color: white !important;
  background-color: #1fc0da !important;
  font-weight: 700;
}
.MenuItemParent.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.MenuGroup {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 0.3rem;
  padding: 0.6rem;
  font-weight: 700;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-radius: 3px;
  cursor: pointer;
  -ms-user-select: none;
      user-select: none;
}
.MenuGroup:hover {
  background-color: #595959;
}
.MenuGroup.active:before {
  display: none;
}
.MenuGroup img {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 1.5rem;
  pointer-events: none;
  -ms-flex-item-align: center;
      align-self: center;
}
.MenuGroup .icon.caret {
  font-size: 1.2rem;
  position: relative;
  top: 0.1rem;
  left: 0.5rem;
}
.MenuGroup-Children {
  margin-left: 1.7rem;
  padding-left: 1rem;
}
.MenuGroup-Children.hidden {
  display: none;
}
.MenuGroup-Children .MenuGroup {
  margin-top: 0;
}
.iframe-layout .login-wrapper {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 150px;
}
.iframe-layout .logout-button {
  position: fixed;
  right: 10px;
  top: 0px;
  z-index: 10000;
}
.container {
  background-color: #2d2d2d;
}

.header {
  padding-top: 50px;
}

.notification {
  color: white;
  margin-top: 50px;
  font-size: 20px;
}
