@import '../../../styles/variables.scss';

.ContentItem {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  padding: 1rem;
  visibility: hidden;
  overflow: hidden;
  &.active {
    position: relative;
    bottom: auto;
    left: auto;
    height: calc(100vh - #{$HEADER_HEIGHT});
    visibility: visible;
    overflow: unset;
  }
}
