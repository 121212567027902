.react-tabs__tab-list div {
  overflow: hidden;
}
.react-tabs__tab {
  display: block;
  width: 50%;
  float: left;
  text-align: center;
  text-transform: uppercase;
  height: 50px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
}
.react-tabs__tab--selected {
  border: 0px;
  position: relative;
  color: #1fc0da;
}
.react-tabs__tab--selected::after {
  content: '';
  border-bottom: 2px solid #1fc0da;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.react-tabs__tab--selected:hover {
  color: #1fc0da;
}
.react-tabs__tab-list {
  border-bottom: 0;
}

//Fix layout radio
.Radio-box {
  margin-right: 1.525em;
  margin-bottom: 1.025em;
}
.Container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
