.form-progress-action {
  &-loading {
    display: flex;
    height: 75vh;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  &-container {
    .fluid-field {
      grid-column: 1 / -1;
    }
    .action-notes-icon {
      margin-left: 5px !important;
    }
    .action-notes {
      background-color: #d9edf7;
      margin-top: 10px;
      padding: 10px 0 0 0;
      label {
        padding: 0 0 0 1em;
        font-size: 16px;
        font-weight: 500;
      }
      ul {
        list-style: none;
        padding: 0 0 10px 0;
      }
      li {
        padding-left: 1.3em;
      }
      li:before {
        content: '- ';
      }
    }
    .popup-content {
      width: 20em !important;
      background-color: red;
    }
  }
}
