/* Spacing helper like bootstrap utils */

@for $i from 0 through 5 {
  .pt-#{$i} {
    padding-top: #{$i}rem !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}rem !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}rem !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}rem !important;
  }
  .py-#{$i} {
    padding-top: #{$i}rem !important;
    padding-bottom: #{$i}rem !important;
  }
  .px-#{$i} {
    padding-left: #{$i}rem !important;
    padding-right: #{$i}rem !important;
  }
  .pa-#{$i} {
    padding: #{$i}rem !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}rem !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}rem !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}rem !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}rem !important;
  }
  .my-#{$i} {
    margin-top: #{$i}rem !important;
    margin-bottom: #{$i}rem !important;
  }
  .mx-#{$i} {
    margin-left: #{$i}rem !important;
    margin-right: #{$i}rem !important;
  }
  .ma-#{$i} {
    margin: #{$i}rem !important;
  }
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
