@import '../../../styles/variables.scss';

.Tabs {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $CIRRUS;
  white-space: nowrap;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.75rem;
    background-color: #ffffff;
    border-bottom: 1px dashed $BORDER;
    // Need to be synced with ./Tab.scss
    z-index: 101;
  }

  &-Outer {
    // To hide the overflow when drag/drop tabs to reorder
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    &.sidebarAutohide {
      // To display the ShowSidebarIcon
      left: 3rem;
    }
  }
  &-Inner {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    right: 8rem;
  }

  &-DraggingBackdrop {
    position: fixed;
    top: -1rem;
    bottom: -1rem;
    left: -1rem;
    right: -1rem;
    cursor: pointer;
    z-index: 9999;
  }
}
