@import '../../../styles/variables.scss';

.DatePicker {
  position: relative;

  & .Picker {
    position: absolute;
    z-index: 999;
    width: $SIDEBAR_WIDTH;
    box-shadow: 0 2px 8px 0 rgba(37, 130, 139, 0.2);

    &-Arrow {
      &:before,
      &:after {
        position: absolute;
        content: '';
        height: 15px;
        background: #f9fafb;
      }

      &:before {
        width: 15px;
        top: -6px;
        left: 13px;
        transform: rotate(45deg);
        border: 1px solid $CIRRUS;
        box-shadow: 0 2px 8px 0 rgba(37, 130, 139, 0.2);
      }

      &:after {
        width: 30px;
        top: 1px;
        left: 5px;
      }
    }

    & table {
      border-color: $CIRRUS;
      margin: 0;
    }
  }
}
