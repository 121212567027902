@import '../../styles/variables.scss';

.Anonymous {
  &-Content {
    position: relative;
    justify-content: space-between;
    padding-left: $SIDEBAR_WIDTH;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    align-items: center;
    padding-top: 5rem;
    @media (max-height: $BODY_MIN_HEIGHT_MAX) {
      min-height: $BODY_MIN_HEIGHT;
    }
    @media (max-width: 920px) {
      padding-left: 0;
      padding-top: 0;
    }
  }

  &-Children {
    flex: 1;
    width: 100%;
    padding: 0rem 1rem 2rem;
    .register-title {
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: white;
      padding-bottom: 1rem;
    }

    @media (min-width: 920px) {
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 50rem;
    }
    @media (max-width: 919px) {
      .register-title {
        position: sticky;
        top: 0;
        z-index: 100;
        background-color: white;
        padding-bottom: 1rem;
        padding-top: 5rem;
      }
    }
  }

  &-Sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: $SIDEBAR_WIDTH;
    padding: 8rem 2rem 2rem 1.5rem;
    background-image: url('../../assets/images/anonymous-sidebar.png');
    background-size: cover;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 920px) {
      display: none;
    }
  }

  &-Footer {
    display: flex;
    background-color: black;
    color: white;
    bottom: 0;
    width: 100%;
    &Item {
      flex: 0.4;
      padding: 1rem 2rem;
      padding-right: 0;
      &:nth-child(3) {
        flex: none;
        width: 11rem;
        float: right;
      }
    }
    .text-secondary {
      color: #cfcfcf !important;
    }
  }

  &-Cirrus {
    position: relative;
    color: white;
    &Logo {
      width: 100%;
      padding-bottom: 34%;
      // background-image: url('../../assets/images/logo3.png');
      background-image: url('../../assets/images/mag-logo5.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 1rem;
    }
    // &Title {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   color: $CIRRUS;
    //   font-size: 4rem;
    //   line-height: 1;
    //   &:before {
    //     content: 'CIRRUS';
    //   }
    // }
    // &Powered {
    //   position: relative;
    //   &:before {
    //     content: 'Powered by:';
    //     display: block;
    //   }
    //   &:after {
    //     content: 'Medicolegal Assessments Group';
    //     display: block;
    //     position: absolute;
    //     top: 100%;
    //     left: 0;
    //     font-size: 1.2rem;
    //     font-weight: $BOLD;
    //     white-space: nowrap;
    //   }
    // }
    &Sologan {
      position: relative;
      margin-top: calc(50vh - 15rem);
      @media (max-height: 499px) {
        // body min height 500px
        margin-top: 4rem;
      }
      font-size: 2rem;
      &:before {
        content: 'The right doctor';
        display: block;
      }
      &:after {
        content: 'at the right time';
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 1rem;
      }
    }
  }
}
