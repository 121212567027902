@import '../../../styles/variables.scss';

.DoctorTable__monthlySession {
  background-color: #7c8aa2;
  margin: -0.8em -0.85em;
  height: 38px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & > span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    color: #fff;
    line-height: 38px;
  }

  .DoctorTable__monthlySession__month {
    min-width: 120px;
  }

  .DoctorTable__monthlySession__doctor {
    min-width: 100px;
  }

  &.available {
    background-color: #0bb66c;
  }
  &.immediateAvailable {
    background-color: $CIRRUS_ACCENT;
    + i {
      display: none !important;
    }
  }
}

.SessionCount {
  position: relative;
  text-align: left;
  margin: 0.25rem 0;
  white-space: nowrap;
}

.SessionCountDate {
  display: inline-block;
  width: 50px;
  vertical-align: middle;
}

.SessionCountLabel {
  cursor: pointer;
}

.RightMenu {
  right: 0 !important;
  left: auto !important;
}

.columnBtns {
  width: 180px;
  margin: 0 auto;
}
.requestMoreDoctorInfoBtn,
.requestAnotherAppointmentBtn {
  padding: 5px 0 7px 0 !important;
  font-size: 0.9em !important;
  border-radius: 3px !important;
}
.requestAnotherAppointmentBtn {
  margin-top: 3px !important;
}

.mag-popover-container {
  position: fixed;
  background: #fff;
  padding: 1em;
  max-width: 40rem;
  z-index: 2080;
  border: 1px solid #d4d4d5;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
  transition: all 0.3s;

  &.top {
    margin-bottom: 8px;
  }

  &.bottom {
    margin-top: 8px;
  }

  &:before {
    content: '';
    position: absolute;
    top: auto;
    right: auto;
    bottom: -0.30714286em;
    left: 1em;
    width: 0.71428571em;
    height: 0.71428571em;
    background: #fff;
    margin-left: 0;
    z-index: 2;
    transform: rotate(45deg);
    box-shadow: 1px 1px 0 0 #bababc;
  }

  &.top.right {
    &:before {
      right: 1em;
      left: auto;
    }
  }

  &.bottom.right,
  &.bottom.left {
    &:before {
      right: 1em;
      top: -0.30714286em;
      bottom: auto;
      left: auto;
      transform: rotate(227deg);
    }
  }

  &.bottom.left {
    &:before {
      left: 1em;
      right: auto;
    }
  }

  .mag-popover-loading {
    width: 25rem;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mag-popover-title {
    color: #4a4a4a;
    border-bottom: 1px solid #d4d4d5;
    padding-bottom: 7px;
    margin-top: 0;
    margin-bottom: 7px;
  }

  .mag-popover-subtitle {
    margin-top: 0;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 1px;
    letter-spacing: 0.2px;

    &.red {
      color: #ff4d4f;
    }
  }

  .mag-popover-content {
    color: #7c8aa2;
    margin-bottom: 6px;

    &.red {
      color: #ff7875;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .mag-popover-message {
    background-color: #e3f8fb !important;
    color: #1fc0da !important;
    padding: 7px 0;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    margin-top: 6px;
  }
}

.profile-picture {
  width: 180px;
}

.profile-picture--small {
  width: 3.5rem;
}

.dr-name-distance {
  div {
    display: inline-block;
  }

  .distance {
    font-size: 0.9rem;
    font-style: italic;
    color: #727272;
  }
}

.label-tag {
  margin: 0 7px 7px 0 !important;
  border-radius: 4px !important;
  border: solid 1px rgba(37, 130, 139, 0.2) !important;
  background-color: rgba(37, 130, 139, 0.1) !important;
}

.table-scroll {
  display: block;
  empty-cells: show;
  thead{
    position:relative;
    display: block;
    width:100%;
    overflow-y: scroll;
  }

  tbody{
    /* Position */
    display: block; 
    position:relative;
    width:100%; 
    overflow-y:scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 220px);
  }

  tr{
    width: 100%;
    display:flex;
  }

  td, th {
    flex-basis:100%;
    flex-grow:2;
    display: block;
  }
}
