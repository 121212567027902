.Tooltip {
  position: fixed;
  background: #000;
  color: #fff;
  padding: 0.6em 0.9em;
  font-size: 0.9em;
  line-height: 1.2em;
  border-radius: 3px;
  min-width: 10em;
  max-width: 50%;
  z-index: 999;

  &-arrow {
    content: '';
    position: absolute;
    top: -3px;
    left: 0.9em;
    width: 0.8em;
    height: 0.625em;
    background: #000;
    transform: rotate(45deg);
  }
}

.Tooltip.swap-right > .Tooltip-arrow {
  left: auto;
  right: 0.9em !important;
}

.Tooltip.swap-top > .Tooltip-arrow {
  top: auto;
  bottom: -3px;
}
