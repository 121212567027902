.file-upload-container {
  .ant-upload-list-item-name {
    width: auto;
    margin-right: 10px;
  }

  .ant-upload-list-item-info {
    &>span {
      display: flex;
    }
  }
}
