.Session-affix .ant-affix {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 6px -6px;
}

.Session-note-container {
  background-color: #fff;
  margin-top: 1rem;

  .Session-note-group {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.note {
      margin-top: 10px;
      display: flex;
      align-items: center;

      >span {
        line-height: 1;
      }
    }

    .Session-note-title {
      margin: 0;
      line-height: 1;
      margin-right: 10px;
      font-size: 1.1em;
      letter-spacing: 0.1px;
      color: black;
      min-width: 14rem;
    }
  }

  .Session-note-item {
    display: inline-flex;
    align-items: flex-end;
    margin-right: 1.2rem;

    &:last-child {
      margin-right: 0;
    }

    .Session-note-label {
      line-height: 1;
      font-size: 1em;
      letter-spacing: 0.2px;
      margin-right: 7px;
      color: #000;
    }

    .Session-note-background {
      width: 1.7rem;
      height: 1.5rem;
      overflow: hidden;
      border-radius: 3px;
      padding: 1px;
    }

    &.booked {
      .Session-note-label {
        font-weight: bold;
        color: black;
      }

      .Session-note-background {
        background: #a62639;
      }
    }

    &.available {
      &.inperson .Session-note-background {
        background: #009b72;
      }

      &.video .Session-note-background {
        background: #514163;
      }

      &.both .Session-note-background {
        background: #febf40;
      }

      &.ndis .Session-note-background {
        background: #006eff;
      }
    }

    &.on-demand {
      &.inperson .Session-note-background {
        background: #99d7c6;
      }

      &.video .Session-note-background {
        background: #afa8b8;
      }

      &.both .Session-note-background {
        background: #fedc96;
      }

      &.ndis .Session-note-background {
        background: #559eff;
      }
    }
  }
}