@import '../../../styles//variables.scss';

.Case-progress {
  .status-list {
    display: flex;
    justify-content: space-between;

    .status-item {
      width: 85px;
      text-align: center;

      .status-box {
        width: 100%;
        height: 80px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .status-box-outer,
        .status-box-inner {
          position: absolute;
          background-color: #4a4a4a;
          border-radius: 100%;
        }

        .status-box-outer {
          width: 100%;
          height: 100%;
          opacity: 0.1;
        }

        .status-box-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          opacity: 0.04;

          i.icon {
            margin-top: 3px;
            margin-right: 0;
            color: #fff;
          }
        }

        &.checked {
          .status-box-outer,
          .status-box-inner {
            background-color: rgb(44, 191, 44);
          }

          .status-box-inner {
            opacity: 1;
            box-shadow: unset;
          }

          .status-box-outer {
            opacity: 0.4;
            background-color: rgb(200, 224, 232);
          }
        }
        &.unchecked {
          .status-box-outer,
          .status-box-inner {
            background-color: rgb(221, 221, 221);
          }

          .status-box-inner {
            opacity: 1;
            box-shadow: unset;
          }

          .status-box-outer {
            opacity: 0.4;
            background-color: rgb(200, 224, 232);
          }
        }
      }

      .status-label {
        display: block;
        margin-top: 10px;
        font-size: 0.9rem;
        font-weight: 500;
        color: #4a4a4a;
      }
    }
  }

  .status-details {
    margin-top: 2.2rem;

    .person-in-charge-list .ui.divider,
    .status-date-list .ui.divider {
      margin-top: 7px !important;
    }

    .status-date-list {
      margin-top: 1.5rem;
    }

    .person-in-charge-list-item,
    .status-date-list-item {
      display: flex;
      justify-content: space-between;

      .person-in-charge,
      .status-date {
        width: 85px;
        text-align: center;
        color: #7c8aa2;
        letter-spacing: 0.2px;
      }

      .person-in-charge,
      .status-date {
        overflow-wrap: break-word;
      }
    }
  }

  &-status-history {
    margin-top: 1.5em;

    > h4 {
      display: inline-flex;
      letter-spacing: 0.2px;
      color: $CIRRUS;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: transparentize($CIRRUS, 0.2);
      }
    }

    > table {
      margin-top: 0 !important;
    }
  }
}
