@import '../../../styles/variables.scss';

.DashboardContent {
  position: relative;
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  margin-left: $SIDEBAR_WIDTH;
  padding-top: $HEADER_HEIGHT;
  &.sidebarAutohide {
    margin-left: 0;
    border-left: 0;
  }
}
