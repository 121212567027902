@import '../../../styles/variables.scss';

.IframeContainer {
  position: fixed;
  top: $HEADER_HEIGHT;
  bottom: 0;
  left: $SIDEBAR_WIDTH;
  right: 0;
  background-color: white;
  &.sidebarAutohide {
    left: 0;
  }
}
#cirrus-iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  &.visibilityHidden iframe {
    visibility: hidden;
  }
}
