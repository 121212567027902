@import '../../../styles/variables.scss';

.Table__AccordionTile {
  position: relative;

  .Table__AccordionIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
    color: #fff;
    cursor: pointer;
    color: #fff;
  }
}

.td-Sessions {
  padding-top: 3px !important;
  padding-bottom: 15px !important;
}
.Session-container {
  display: flex;

  &:not(:first-child) {
    margin-top: 0.6em;
  }

  .Session-label {
    flex: 0 1 7em;
  }

  .Appointments {
    flex: 1;
    height: 100%;

    &-detail {
      cursor: pointer;
      width: calc(100% / 10 - 0.6em);
      color: #fff;
      border-radius: 3px;
      display: inline-flex;
      font-size: 0.95em;
      height: 100%;
      position: relative;

      &-content {
        border-radius: 3px;
        // z-index: 3;
        font-weight: bold;
        width: 100%;
        display: flex;
        justify-content: space-between;
        letter-spacing: 0.2px;

        & span:first-child {
          padding-left: 0.4em;
        }

        .video.icon {
          margin: 0;
          font-size: 0.9em;
          padding-top: 0.03em;
        }

        & span:last-child {
          padding-right: 0.4em;
        }

        @media (max-width: 1599px) {
          font-size: 0.8em;

          .video.icon {
            font-size: 0.8em;
            padding-top: 0;
          }
        }
      }

      &:not(:first-child) {
        margin-left: 0.63em;
      }
    }

    &-detail.new-line {
      margin-top: 0.3em;
    }
  }
}

.Appointments-detail {
  &.booked {
    background: #a62639;
  }
  &.reserved {
    background: #1FC0DA;
  }
  &.available {
    &.inperson {
      background: #009b72;
    }
    &.video {
      background: #514163;
    }
    &.both {
      background: #febf40;
      color: #000;
    }
  }
  &.onDemand {
    &.inperson {
      background: #99d7c6;
    }
    &.video {
      background: #afa8b8;
    }
    &.both {
      background: #fedc96;
      color: #000;
    }
  }
}
