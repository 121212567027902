@import '../../../styles/variables.scss';

.Search {
  position: relative;

  i {
    opacity: 0.2 !important;
    transition: unset !important;
  }

  & input:focus ~ i {
    opacity: 0.8 !important;
  }
}

.Search-results {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid $CIRRUS;
  box-shadow: 0 10px 8px 0 rgba(37, 130, 139, 0.2) !important;
  max-height: 18.75em;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  & > .item {
    padding: 0.7em 0.8em 0.7em 1em;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    }

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  & > .no-results {
    padding: 0.7em 0.8em 0.7em 1em;
    letter-spacing: 0.025em;
    color: rgba(0, 0, 0, 0.4);
  }
}
